import ajaxCall from '../utilities/ajax.js';

document.addEventListener('turbo:load', function () {
  const country_div = document.getElementById('payment_country');
  if(country_div) {
    country_div.addEventListener("change", function(){
      let getStateUrl = country_div.dataset.getStateUrl;
      getStateUrl = getStateUrl + '?country=' + country_div.value;
      $.ajax({
        url: getStateUrl,
        type: "GET"
      })
    });
  }
});
