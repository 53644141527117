class MenuBar {
  start() {
    document.addEventListener("turbo:load", function() {
      var menubar = document.querySelector('.m_menubar');
      var toggleBtn = document.querySelector('.m_menubar-btn');
      if( menubar && toggleBtn ) {
        toggleBtn.addEventListener('click', function(e) {
          menubar.classList.toggle('-isClosed');
          menubar.classList.toggle('-isOpened');
        });
      }
    }.bind(this))
  }
}

export default MenuBar
