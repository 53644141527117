import ajaxCall from '../utilities/ajax.js';
import {updateLineItemAmount, updateSubtotal, updatePriceAndDescriptionFromInvoiceItem, changeServiceType} from "../invoices/invoices";

class NestedFormsLineItem {
  start() {
    document.addEventListener("turbo:load", function () {
      if (document.getElementById('line-item-list')) {
        let addBtn = document.getElementById('js-nested-form-line-item-add');
        if (addBtn === null) {
          return;
        }

        this._createDOMEventListeners();
        this.nextID = parseInt(addBtn.dataset.LineItemCount, 10);
        this.newItemUrl = addBtn.dataset.newUrl;
        this.destroyItemUrl = addBtn.dataset.destroyUrl;
      }
    }.bind(this))
  }

  _addLineItem(html) {
    this.nextID = this.nextID + 1
    document.getElementById('line-item-list').insertAdjacentHTML('beforeend', html)
    let allRemoveButtonElements = document.getElementsByClassName('js-nested-form-line-item-remove')
    this._createRemoveEventListener(allRemoveButtonElements[allRemoveButtonElements.length - 1]);
    this._createUpdateEventListener(allRemoveButtonElements[allRemoveButtonElements.length - 1].parentElement.parentElement);
  }

  _destroyLineItem(elementToRemove) {
    if (typeof elementToRemove.dataset.destroyItemUrl !== "undefined" &&
      elementToRemove.dataset.destroyItemUrl !== "") {
      ajaxCall(elementToRemove.dataset.destroyItemUrl, 'DELETE', () => {
      }, (xhr) => {
        alert('Unable to delete line item');
      });
    }
    elementToRemove.remove()
  };

  // This is to create the events listening to some DOM events
  _createDOMEventListeners() {
    this._createAddEventListener()

    var removeLineItemButtons = document.getElementsByClassName('js-nested-form-line-item-remove')
    Array.from(removeLineItemButtons).forEach(function (element) {
      this._createRemoveEventListener(element)
    }.bind(this));
  }

  _loadNewLineItem() {
    let client = document.getElementById("invoice_client_id");
    let expense = document.getElementById("invoice_is_income");
    let query_args = "";

    if (client.value > 0) {
      // Find out if url as query arguments already
      if (this.newItemUrl.match(/\?/) === null) {
        query_args = "?";
      } else {
        query_args = "&";
      }
      query_args += `client_id=${client.value}`
    }

    if (expense && expense.value === 'false') {
      // Find out if url as query arguments already
      if (this.newItemUrl.match(/\?/) === null) {
        query_args = "?";
      } else {
        query_args = "&";
      }
      query_args += `expenses=${true}`
    }

    ajaxCall(this.newItemUrl + query_args, 'GET', (json_data) => {
      this._addLineItem(json_data.content);
    }, (xhr) => {
      //console.log('Unable to create new line item: ' + xhr.status);
    });
  }

  _createUpdateEventListener(element) {
    // Use invoices.js updateLineItemAmount when qty or price changes to update
    // the invoice total
    element.querySelectorAll('input').forEach(child => {
      if (child.classList.contains('line_item_qty') || child.classList.contains('line_item_price') || child.classList.contains('line_item_is_taxable')) {
        child.addEventListener('change', updateLineItemAmount.bind(this, child))
      }
    });
    element.querySelectorAll('select').forEach(child => {
      if (child.classList.contains('line_item_invoice_item_id')) {
        child.addEventListener('change', updatePriceAndDescriptionFromInvoiceItem.bind(this, child))
      }
    });
    element.querySelectorAll('select').forEach(child => {
      if (child.classList.contains('line_item_service_type')) {
        child.addEventListener('change', changeServiceType.bind(this, child))
      }
    });
  }

  _createAddEventListener() {
    var addLineItemButton = document.getElementById('js-nested-form-line-item-add')
    if (addLineItemButton) {
      addLineItemButton.addEventListener('click', function (event) {
        event.preventDefault()
        this._loadNewLineItem()
      }.bind(this));
    }
  }

  _createRemoveEventListener(element) {
    element.addEventListener('click', function (event) {
      event.preventDefault()
      var elementToRemove = event.target.parentElement.parentElement;

      if (elementToRemove.className.indexOf('line-item') != -1) {
        this._destroyLineItem(elementToRemove);
        updateSubtotal();
      } else {
        throw new Error('Noooooo.... :(');
      }
    }.bind(this));
  }
}

export default NestedFormsLineItem
