import data from 'emoji-mart/data/apple.json'
import { NimblePicker } from 'emoji-mart'
import React from 'react'
import ReactDOM from 'react-dom'

class TextareaEmojis {
  start() {
    document.addEventListener("turbo:load", function() {
      const popupToggles = document.querySelectorAll('.js-emojis-toggle');

      popupToggles.forEach((toggle) => {
        const popupElement = toggle.closest('form').querySelector('.js-emojis-popup'); // Scope to the current form
        const textareaTarget = toggle.getAttribute('data-target');
        const textareaElement = document.querySelector(textareaTarget); // Specific textarea for this form
        const formElement = toggle.closest('form'); // Closest form for the current toggle

        if (popupElement && textareaElement && formElement) {
          const Picker = React.createElement(NimblePicker, {
            set: 'apple',
            data,
            title: 'Emojis',
            onSelect: (emoji) => this._handleTextareaInsert(textareaElement, emoji), // Make sure to pass the correct textarea
          });

          let isMounted = false;

          toggle.addEventListener('click', function() {
            this._handleEmojisClick(popupElement, Picker, isMounted);
            isMounted = !isMounted; // Toggle mounted state
          }.bind(this));

          textareaElement.addEventListener('keydown', function(event) {
            this._handleEnterSubmit(event, formElement);
          }.bind(this));

          // comment initial focus on text-area, don't do fucus until a user click on it.
          // textareaElement.focus();
        }
      });
    }.bind(this))
  }

  _handleEnterSubmit(ev, formElement) {
    // comment these lines for stop message sending in conversations.
    // if(ev.keyCode == 13 && !ev.shiftKey){
    //     this.formElement.submit();
    //     ev.preventDefault();
    // }
  }

  _handleEmojisClick(popupElement, Picker, isMounted) {
    if (isMounted) {
      ReactDOM.unmountComponentAtNode(popupElement);
      popupElement.classList.remove('is-opened');
    } else {
      ReactDOM.render(Picker, popupElement);
      popupElement.classList.add('is-opened');
    }
  }

  _handleTextareaInsert(textareaElement, emoji) {
    this._insertAtCursor(textareaElement, emoji.native);
  }

  _insertAtCursor(myField, myValue) {
    // IE support
    if (document.selection) {
      myField.focus();
      const sel = document.selection.createRange();
      sel.text = myValue;
    }
    // MOZILLA and others
    else if (myField.selectionStart || myField.selectionStart == '0') {
      const startPos = myField.selectionStart;
      const endPos = myField.selectionEnd;
      myField.value = myField.value.substring(0, startPos)
        + myValue
        + myField.value.substring(endPos, myField.value.length);
    } else {
      myField.value += myValue;
    }
  }
}

export default TextareaEmojis;
