document.addEventListener("turbo:load", function() {
  $("tbody.js-sortable-rows").sortable({
    handle: ".js-sortable-row-handle",
    update: function (event, ui) {
        var data = $(this).sortable('serialize');
        // POST to server using $.post or $.ajax
        $.ajax({
            data: data,
            type: 'POST',
            url: $(this).data('reorder-url')
        });
    }
  });

  $("tbody.js-sortable-answer-options-rows").sortable();
});
