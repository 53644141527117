// import tagger from '../src/footer/vendors/tagger.js';
import tagger from '../vendors/tagger'
document.addEventListener("turbo:load", function() {
  var inputs = document.querySelectorAll('input.tag-list');
  
  if (inputs) {
    inputs.forEach(input => {
      var completionList = input.dataset.list;
      tagger(input, {
          allow_duplicates: false,
          allow_spaces: true,
          link: function(name) {
            return false;
          },
          completion: {
              list: completionList.split(','), 
              delay: 0, 
              min_length: 0
          }
      });
    });
  }
});