class TableSortingStyle {
  start() {
    document.addEventListener("turbo:load", function() {
      var sortableTable = document.querySelector('.js_sortableTable');
      if( sortableTable ) {
        var orderQuery = this.getQueryVariable("order_by");
        if(orderQuery !== false) {
          sortableTable.querySelector('th a[data-column="'+ orderQuery +'"]').classList.add('-active');
          var direction = this.getQueryVariable("order_direction");
          if (direction === "DESC") {
            sortableTable.querySelector('th a[data-column="'+ orderQuery +'"]').classList.add('-reverse');
          }
        }
      }

      var tableExtraOverlay = document.querySelectorAll('.js_tableExtraOverlay');
      var tableExtraOverlayBtn = document.querySelectorAll('.js_tableExtraOverlayBtn');

      if(tableExtraOverlay && tableExtraOverlayBtn) {
        for (var i = 0, len = tableExtraOverlayBtn.length; i < len; i++) {
          tableExtraOverlayBtn[i].addEventListener('click', function(e) {
            var overlay = e.currentTarget.parentElement.querySelector('.js_tableExtraOverlay');
            if (window.getComputedStyle(overlay).display === 'none'){
              return overlay.style.display = 'block';
            } else {
              return overlay.style.display = 'none';
            }
          });
        }
        for (var i = 0, len = tableExtraOverlay.length; i < len; i++) {
          tableExtraOverlay[i].addEventListener('mouseleave', function(e) {
            e.currentTarget.style.display = 'none';
          });
        }
      }

    }.bind(this))
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
    }
    return(false);
  }

}

export default TableSortingStyle
  