$(document).on('turbo:load', function () {
  $("#promotion_codes a.add_fields").
      data("association-insertion-method", 'append').
      data("association-insertion-traversal", 'closest').
      data("association-insertion-node", '#promotion_codes');

  var adminCouponForm = document.querySelector('form#admins-coupon')
  if(adminCouponForm) {
    var discountTypeDropdown = document.getElementById('coupon_discount_type');
    if(discountTypeDropdown) {
      discountTypeDropdown.addEventListener('change', function(event){
        var discountType = event.target.value;
        var couponPercentageOffDiv = document.getElementById('coupon_percentage_off_div');
        var couponAmountOffDiv = document.getElementById('coupon_amount_off_div');
        var amountOff = document.getElementById('coupon_amount_off');
        var percentageOff = document.getElementById('coupon_percentage_off');
        if (discountType == 'amount') {
          couponAmountOffDiv.classList.remove('hidden');
          couponPercentageOffDiv.classList.add('hidden');
          percentageOff.value = null;
        } else {
          couponPercentageOffDiv.classList.remove('hidden');
          couponAmountOffDiv.classList.add('hidden');
          amountOff.value = null;
        }
      });
    }

    var couponDurationDropdown = document.getElementById('coupon_duration');
    if(couponDurationDropdown) {
      couponDurationDropdown.addEventListener('change', function(event){
        var couponDuration = event.target.value;
        var couponDurationMonthDiv = document.getElementById('coupon_duration_month_div');
        var couponDurationMonth = document.getElementById('coupon_duration_month');
        if (couponDuration == 'repeating') {
          couponDurationMonthDiv.classList.remove('hidden');
        } else {
          couponDurationMonthDiv.classList.add('hidden');
          couponDurationMonth.value = null;
        }
      });
    }

    var couponApplyToProducts = document.getElementById('coupon_apply_to_specific_products');
    if(couponApplyToProducts) {
      couponApplyToProducts.addEventListener('change', function(event){
        var applyToProducts = event.target.checked;
        var couponPlanIdDiv = document.getElementById('coupon_plan_id_div');
        var couponPlanIds = document.getElementById('coupon_plan_ids');
        if (applyToProducts) {
          couponPlanIdDiv.classList.remove('hidden');
        } else {
          couponPlanIdDiv.classList.add('hidden');
        }
      });
    }

    var couponLimitDateRange = document.getElementById('coupon_limit_the_date_range');
    if(couponLimitDateRange) {
      couponLimitDateRange.addEventListener('change', function(event){
        var couponLimitRange = event.target.checked;
        var couponRedeemByDiv = document.getElementById('coupon_redeem_by_div');
        var couponRedeemBy = document.getElementById('coupon_redeem_by');
        if (couponLimitRange) {
          couponRedeemByDiv.classList.remove('hidden');
        } else {
          couponRedeemByDiv.classList.add('hidden');
          couponRedeemBy.value = null;
        }
      });
    }

    var couponLimitTotalNumberOfRedeemed = document.getElementById('coupon_limit_total_number_of_redeemed');
    if(couponLimitTotalNumberOfRedeemed) {
      couponLimitTotalNumberOfRedeemed.addEventListener('change', function(event){
        var couponLimitOfRedeemed = event.target.checked;
        var couponMaxRedemptionsDiv = document.getElementById('coupon_max_redemptions_div');
        var couponMaxRedemptions = document.getElementById('coupon_max_redemptions');
        if (couponLimitOfRedeemed) {
          couponMaxRedemptionsDiv.classList.remove('hidden');
        } else {
          couponMaxRedemptionsDiv.classList.add('hidden');
          couponMaxRedemptions.value = null;
        }
      });
    }
  }
});
