function showHideQuestionAnswerType(event) {
  let question_answer_type_select = document.getElementById('assessment_question_answer_type');
  let question_multirating_answer_type = document.getElementById('assessment_question_multirating_answer_type');
  let question_answer_type_for_choices = document.getElementById('js-for-answer-type-choices');
  let child_assessment_questions = document.getElementById('js-for-child-assessment-questions');
  let star_column = document.getElementById('star-column');
  let empty_legend_column = document.getElementById('empty-legend-column');
  let question_answer_type_for_choices_single = document.querySelectorAll('.js-for-answer-type-choices-single');
  let question_answer_type_for_choices_multiple = document.querySelectorAll('.js-for-answer-type-choices-multiple');
  let question_answer_type_for_choices_rating_question = document.querySelectorAll('.js-for-answer-type-choices-rating-question');
  let question_answer_type_for_choices_legend = document.querySelectorAll('.js-for-answer-type-choices-legend');
  let other_type_question_options = document.getElementById('js-for-other-type-question-options');
  let yes_no_type_question_options = document.getElementById('js-for-yes-no-question-options');
  let true_false_type_question_options = document.getElementById('js-for-true-false-question-options');
  let linear_scale_multi_rating_type_question_options = document.getElementById('js-for-linear-scale-multi-rating-type-question-options');
  let likert_multi_rating_type_question_options = document.getElementById('js-for-likert-multi-rating-type-question-options');
  let frequency_scale_multi_rating_type_question_options = document.getElementById('js-for-frequency-scale-multi-rating-type-question-options');
  let choose_multirating_answer_type = document.getElementById('js-for-choose-multirating-answer-type');

  if (question_answer_type_select && question_answer_type_for_choices) {
    if (question_answer_type_select.value == 'single_choice' || question_answer_type_select.value == 'true_false' || question_answer_type_select.value == 'yes_no' ||question_answer_type_select.value == 'rating_question'  || question_answer_type_select.value == 'multiple_rating_scale') {
      question_answer_type_for_choices.classList.remove("hidden");
      child_assessment_questions.classList.remove("hidden");
      other_type_question_options.classList.remove("hidden");
      yes_no_type_question_options.classList.add("hidden");
      true_false_type_question_options.classList.add("hidden");
      linear_scale_multi_rating_type_question_options.classList.add("hidden");
      likert_multi_rating_type_question_options.classList.add("hidden");
      frequency_scale_multi_rating_type_question_options.classList.add("hidden");
      choose_multirating_answer_type.classList.add("hidden");
      question_answer_type_for_choices_legend.forEach((element) => {
        element.classList.remove("hidden");
      })
      question_answer_type_for_choices_rating_question.forEach((element) => {
        element.classList.add("hidden");
      });
      question_answer_type_for_choices_single.forEach((element) => {
        element.classList.add("hidden");
      });
      question_answer_type_for_choices_multiple.forEach((element) => {
        element.classList.add("hidden");
      });
      if (question_answer_type_select.value == 'single_choice') {
        child_assessment_questions.classList.add("hidden");
        star_column.classList.add("hidden");
        empty_legend_column.classList.remove("hidden");
      } else if (question_answer_type_select.value == 'rating_question') {
        child_assessment_questions.classList.add("hidden");
        star_column.classList.remove("hidden")
        empty_legend_column.classList.add("hidden")
        question_answer_type_for_choices_legend.forEach((element) => {
          element.classList.add("hidden");
        });
        question_answer_type_for_choices_rating_question.forEach((element) => {
          element.classList.remove("hidden");
        });
      }  else if (question_answer_type_select.value == 'yes_no') {
        yes_no_type_question_options.classList.remove("hidden");
        child_assessment_questions.classList.add("hidden");
        other_type_question_options.classList.add("hidden");
        star_column.classList.add("hidden");
        empty_legend_column.classList.remove("hidden");
      } else if (question_answer_type_select.value == 'true_false') {
        true_false_type_question_options.classList.remove("hidden");
        child_assessment_questions.classList.add("hidden");
        other_type_question_options.classList.add("hidden");
        star_column.classList.add("hidden");
        empty_legend_column.classList.remove("hidden");
      } else if (question_answer_type_select.value == 'multiple_rating_scale') {
        child_assessment_questions.classList.remove("hidden");
        other_type_question_options.classList.add("hidden");
        choose_multirating_answer_type.classList.remove("hidden");
        if (question_multirating_answer_type.value == 'linear_numeric_scale') {
          linear_scale_multi_rating_type_question_options.classList.remove("hidden");
        } else if (question_multirating_answer_type.value == 'likert') {
          likert_multi_rating_type_question_options.classList.remove("hidden");
        } else if (question_multirating_answer_type.value == 'frequency_scale') {
          frequency_scale_multi_rating_type_question_options.classList.remove("hidden");
        }
      } else if (question_answer_type_select.value == 'multiple_choices') {
        child_assessment_questions.classList.add("hidden");
        question_answer_type_for_choices_multiple.forEach((element) => {
          element.classList.remove("hidden");
        });
      }
    } else {
      question_answer_type_for_choices.classList.add("hidden");
    }
  }
}

document.addEventListener('turbo:load', function () {
  let question_answer_type_select = document.getElementById('assessment_question_answer_type');
  if (question_answer_type_select) {
    question_answer_type_select.addEventListener('change', showHideQuestionAnswerType.bind(question_answer_type_select));
  }
  let question_multirating_answer_type = document.getElementById('assessment_question_multirating_answer_type');
  if (question_multirating_answer_type) {
    question_multirating_answer_type.addEventListener('change', showHideQuestionAnswerType.bind(question_multirating_answer_type));
  }
  showHideQuestionAnswerType();
  $('#assessment_question_options')
      .on('cocoon:after-insert', function(e, added_task) {
        showHideQuestionAnswerType();
        let question_answer_type_select = document.getElementById('assessment_question_answer_type');

        ul = document.getElementsByClassName("stars");
        child_length = $('#assessment_question_options .nested-fields').children().length;
        if (question_answer_type_select && question_answer_type_select.value == "rating_question" && child_length >= 7) {
          $('#assessment_question_options .links')[0].classList.add('hidden')
        }

        for (i = 1; i < child_length; i++) {
          li = document.createElement("li");
          li.setAttribute("class", "selected-star");
          icon = document.createElement("i");
          icon.setAttribute("class", "fa fa-star fa-fw");
          li.appendChild(icon);
          ul[ul.length-1].appendChild(li);
        }
      })
});
