import 'lodash'

class ExercicesAddingFromProgressScreen {
  start() {
    document.addEventListener("turbo:load", function() {
      var wrapper = document.getElementById('js-exercises-and-tools-wrapper')
      if(wrapper) {
        this.initialize()
      }
    }.bind(this))
  }

  initialize() {
    this.removeCount = 0;
    this.categoryID = undefined
    this.typeID = undefined
    this.tag = undefined
    
    this._createDOMEventListeners()
  }

  _createDOMEventListeners () {
    document.getElementById('tool_tool_category_id').addEventListener('change', function(event){
      this.categoryID = event.target.value
      this.refreshToolList()
    }.bind(this))

    document.getElementById('tool_tool_type_id').addEventListener('change', function(event){
      this.typeID = event.target.value
      this.refreshToolList()
    }.bind(this))
    
    document.getElementById('tool_tool_tag').addEventListener('change', function(event){
      this.tag = event.target.value
      this.refreshToolList()
    }.bind(this))

    if (document.getElementById('js-tool-list')){
      var domTools = document.querySelectorAll('.js-tool input[type="checkbox"]');

      Array.from(domTools).forEach(function(domTool){
        domTool.addEventListener('change', function(event){
          if (event.target.checked) {
            this.addTool(event.target);
          } else {
            this.removeTool(event.target);
          }
        }.bind(this))
      }.bind(this));
    }
  }

  refreshToolList(){
    var hide = false;
    var domTools = document.getElementsByClassName('js-tool');
    Array.from(domTools).forEach(function(domTool){
      hide = false;
      if (this.categoryID) {
        if (domTool.dataset.category != this.categoryID && domTool.dataset.subcategory != this.categoryID) {
          hide = true;
        }
      }
      if (this.typeID) {
        if (domTool.dataset.type != this.typeID) {
          hide = true;
        }
      }
      if (this.tag) {
        if (!domTool.dataset.tags.split(", ").includes(this.tag)) {
          hide = true;
        }
      }
      if (hide) {
        domTool.classList.add('hidden');
      } else {
        domTool.classList.remove('hidden');
      }
    }.bind(this));
  }

  addTool(element) {
    // the fact that the checkbox get's checked is enough to save the tool when submitting the form
    // but here we make sure we don't have an entry in the "exercises_remove" hidden fields
    var domToolRemove = document.getElementById(`scheduled_session_exercises_remove_${element.value}`);
    if (domToolRemove) {
      domToolRemove.parentNode.removeChild(domToolRemove);
    }
    // give 2 seconds to save, then reload to show the tool added
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }
  removeTool(element) {
    var toolID = parseInt(element.value)

    var html = `<input type="hidden" id="scheduled_session_exercises_remove_${element.value}" name="scheduled_session[exercises_remove][${this.removeCount}][id]" value="${element.value}" >`
    var div = document.createElement('div');
    div.innerHTML = html

    document.getElementById('js-exercises-to-remove').appendChild(div)

    this.removeCount++
  }
}

window.initializeToolFilters = function() {
  var exericesAdd = new ExercicesAddingFromProgressScreen();
  exericesAdd.initialize();
}
    
export default ExercicesAddingFromProgressScreen
