import { Controller } from "@hotwired/stimulus"
import bulmaCalendar from '../src/application/vendors/bulma-calendar'
import * as moment from 'moment';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';
import { I18n } from "i18n-js";
import fr from "../src/translations/fr.json";
import en from "../src/translations/en.json";

// Connects to data-controller="full-calendar"
export default class extends Controller {
  static targets = [ "clientCalendar", "coachCalendar", "coachAvailabilitiesCalendar" ]
  static values = { minTime: String, maxTime: String, hiddenDays: String, minDelayToBook: String, futureDays: String, eventsPath: String, locale: String, newEventPath: String, newEventConfirmationPath: String, defaultDate: String, pastAvailable: String, employeeId: String, readonly: Boolean, openEventId: String, relationshipStatus: String }

  connect() {
    if (this.hasClientCalendarTarget) {
      const i18n = new I18n({
        fr,
        en,
      });
      i18n.locale = $('body').data('locale');
      // Client's calendar
      var calendar = this.clientCalendarTarget;
      var defaultConfigs = load_calendar_configs(this);
      defaultConfigs.headerToolbar.end = 'dayGrid,timeGridWeek,timeGridDay';
      defaultConfigs.buttonText = { dayGrid: i18n.t("month") };
      defaultConfigs.visibleRange = defaultConfigs.validRange;

      if (this.eventsPathValue) {
        var eventsPath = this.eventsPathValue;
      } else {
        var eventsPath = "/" + defaultConfigs.paramsLocale + "/clients/scheduled_sessions/availabilities.json?employee_id="+defaultConfigs.employeeId;
      }
      if (this.newEventPathValue) {
        var newEventPath = this.newEventPathValue;
      } else {
        var newEventPath = '/' + defaultConfigs.paramsLocale + '/clients/scheduled_sessions/new';
      }
      var calendarConfigs = {
        selectable: false,
        events: eventsPath,
        eventDidMount: event_render_callback,
        eventAfterAllRender: event_after_all_render_callback(calendar),
      };
      if (defaultConfigs.editable) {
        calendarConfigs.eventClick = function(info) {
          // Did I click a scheduled session, or a possible booking slot?
          if (info.event.id && info.event.extendedProps.edit_url) {
            // I clicked on a scheduled session, let's edit it
            $.getScript(info.event.extendedProps.edit_url, function() {
              var params = {
                'form_id': 'client-calendar-form',
                'calendar_id': 'client-calendar',
                'current_user_type': 'client',
                'current_action': 'edit'
              };
              calendar_popup_callback(params, calendarEl);
            });
          } else {
            // I clicked on a booking time slot
            var startDate = moment(info.event.start).format("YYYY-MM-DD");
            $.getScript(newEventPath+'?start_date='+startDate, function() {
              var params = {
                'form_id': 'client-calendar-form',
                'calendar_id': 'client-calendar',
                'datetime_field': 'scheduled_session_scheduled_for',
                'duration_field': 'scheduled_session_scheduled_for_duration',
                'location_field': 'scheduled_session_location_id',
                'current_user_type': 'client',
                'current_action': 'new',
                'start': info.event.start,
                'end': info.event.end,
                'location_id': info.event.extendedProps.location_id,
                'location_overview': info.event.extendedProps.location_overview
              };
              calendar_popup_callback(params, calendarEl);
            });
          }
        };
      }
      var allConfigs = Object.assign(defaultConfigs, calendarConfigs);
      var calendarEl = new Calendar(this.clientCalendarTarget, allConfigs);

      calendarEl.render();
    } else if (this.hasCoachCalendarTarget) {
      // Coach's calendar
      var calendar = this.coachCalendarTarget;
      var defaultConfigs = load_calendar_configs(this);
      defaultConfigs.headerToolbar.end = 'dayGridMonth,timeGridWeek,timeGridDay,listWeek';

      var calendarConfigs = {
        events: '/' + defaultConfigs.paramsLocale + '/users/calendar_events.json?employee_id='+defaultConfigs.employeeId+'&relationship_status='+defaultConfigs.relationshipStatus,
        eventDidMount: event_render_callback,
        eventAfterAllRender: event_after_all_render_callback(calendar),
      };
      if (defaultConfigs.selectable) {
        calendarConfigs.select = function(info) {
          var startDate = moment(info.start).format("YYYY-MM-DD");
          $.getScript('/' + defaultConfigs.paramsLocale + '/users/calendar_events/new?start_date='+startDate, function() {
            var params = {
              'form_id': 'coach-calendar-form',
              'calendar_id': 'coach-calendar',
              'datetime_field': 'scheduled_session_scheduled_for',
              'duration_field': 'scheduled_session_scheduled_for_duration',
              'current_user_type': 'coach',
              'current_action': 'new',
              'start': info.start,
              'end': info.end
            };
            calendar_popup_callback(params, calendarEl);
          });
        };
      }
      if (defaultConfigs.editable) {
        calendarConfigs.eventClick = function(info) {
          // coach users can sometimes see events from their own client calendar
          // when this happens, we want to direct them to their client account to edit the session
          if (info.event.extendedProps.event_type == 'availability' ) {
            $.getScript(info.event.extendedProps.edit_url, function() {
              var params = {
                'form_id': 'coach-calendar-form',
                'calendar_id': 'coach-availabilities-calendar',
                'datetime_field': 'availability_starts_at',
                'duration_field': 'availability_duration',
                'current_user_type': 'coach',
                'current_action': 'edit'
              };
              calendar_popup_callback(params, calendarEl);
            });
          }
          else if (info.event.extendedProps.edit_url_mode != 'popup') {
            window.location.assign(info.event.extendedProps.edit_url);
          } else {
            $.getScript(info.event.extendedProps.edit_url, function() {
              var params = {
                'form_id': 'coach-calendar-form',
                'calendar_id': 'coach-calendar',
                'datetime_field': 'scheduled_session_scheduled_for',
                'duration_field': 'scheduled_session_scheduled_for_duration',
                'current_user_type': 'coach',
                'current_action': 'edit'
              };
              calendar_popup_callback(params, calendarEl);
            });
          }
        };
        calendarConfigs.eventDrop = function(info) {
          if(info.event.extendedProps.event_type == 'availability') {
            var event_data = { 
              availability: {
                id: info.event.id,
                starts_at: moment(info.event.start).format("YYYY-MM-DD HH:mm")
              }
            };
          } else {
            var event_data = { 
              scheduled_session: {
                id: info.event.id,
                scheduled_for_date: moment(info.event.start).format("YYYY-MM-DD"),
                scheduled_for_time: moment(info.event.start).format("HH:mm")
              }
            };
          }
          $.ajax({
              url: info.event.extendedProps.update_url,
              data: event_data,
              type: 'PATCH'
          });
        };
      }

      var allConfigs = Object.assign(defaultConfigs, calendarConfigs);
      var calendarEl = new Calendar(this.coachCalendarTarget, allConfigs);

      calendarEl.render();
    } else if (this.hasCoachAvailabilitiesCalendarTarget) {
      // Coach's availabilities management calendar
      var calendar = this.coachAvailabilitiesCalendarTarget;
      var defaultConfigs = load_calendar_configs(this);

      var calendarConfigs = {
        events: '/' + defaultConfigs.paramsLocale + '/users/availabilities.json',
        eventDidMount: event_render_callback,
        eventAfterAllRender: event_after_all_render_callback(calendar),
        select: function(info) {
          // create a new event
          var startsAt = moment(info.start).format("YYYY-MM-DD HH:mm");
          $.getScript('/' + defaultConfigs.paramsLocale + '/users/availabilities/new?starts_at='+startsAt, function() {
            var params = {
              'form_id': 'coach-calendar-form',
              'calendar_id': 'coach-availabilities-calendar',
              'datetime_field': 'availability_starts_at',
              'duration_field': 'availability_duration',
              'current_user_type': 'coach',
              'current_action': 'new',
              'start': info.start,
              'end': info.end
            };
            calendar_popup_callback(params, calendarEl);
          });
          // calendar.fullCalendar('unselect');
        },
        eventDrop: function(info) {
          var event_data = { 
            availability: {
              id: info.event.id,
              starts_at: moment(info.event.start).format("YYYY-MM-DD HH:mm")
            }
          };
          $.ajax({
              url: info.event.extendedProps.update_url,
              data: event_data,
              type: 'PATCH'
          });
        },

        eventClick: function(info) {
          $.getScript(info.event.extendedProps.edit_url, function() {
            var params = {
              'form_id': 'coach-calendar-form',
              'calendar_id': 'coach-availabilities-calendar',
              'datetime_field': 'availability_starts_at',
              'duration_field': 'availability_duration',
              'current_user_type': 'coach',
              'current_action': 'edit'
            };
            calendar_popup_callback(params, calendarEl);
          });
        }
      };
      var allConfigs = Object.assign(defaultConfigs, calendarConfigs);
      var calendarEl = new Calendar(this.coachAvailabilitiesCalendarTarget, allConfigs);

      calendarEl.render();
    }
  }
}

var event_after_all_render_callback;
event_after_all_render_callback = function(calendar){
  var eventId = calendar.dataset.fullCalendarOpenEventIdValue;
  if (eventId) {
    var timeoutID = window.setTimeout(function() {
      $("#session-" + eventId)[0].click();
    }, 1000);
  }
};

var event_render_callback;
event_render_callback = function(info){
  const i18n = new I18n({
    fr,
    en,
  });
  i18n.locale = $('body').data('locale');

  // 1. add an id to the element so I can trigger clicks from js removeEvents
  $(info.el).attr('id', info.event.id);


  // 2. Append info to events in the list view only
  if (info.event.extendedProps.status == 'pending_client_invitation_acceptation' || info.event.extendedProps.status == 'refused') {
    var titleEl = document.querySelector(`.fc-list-table .fc-event#${info.event.id} .fc-list-event-title a`);
    if (titleEl) {
      let tag = document.createElement("span");
      tag.style.marginLeft = '10px';
      if (info.event.extendedProps.status == 'pending_client_invitation_acceptation') {
        tag.append(i18n.t("pending_client_invitation_acceptation"));
        tag.classList = 'u_card-badge -themeYellow';
      } else if (info.event.extendedProps.status == 'refused') {
        tag.append(i18n.t("refused"));
        tag.classList = 'u_card-badge -themeRed';
      }
      titleEl.append(tag);
    }
  }
  
  // 3. Custom implementation for validity date ranges in recurring events
  if (info.event.ranges == null) {
    // if no ranges specified in event's array, return true and show the event rightaway
    return true;
  } else {
    return (info.event.ranges.filter(function(range){ // test event against all the ranges

        return (event.start.isBefore(range.end) &&
                event.end.isAfter(range.start));

    }).length)>0; //if it isn't in one of the ranges, don't render it (by returning false)
  }
};

var load_calendar_configs;
load_calendar_configs = function (calendar) {
  var minTime = calendar.minTimeValue;
  var maxTime = calendar.maxTimeValue;
  var defaultDate = calendar.defaultDateValue;
  if (!defaultDate) {
    defaultDate = moment().format("YYYY-MM-DD");
  }
  var pastAvailable = calendar.pastAvailableValue;
  
  var hiddenDays = calendar.hiddenDaysValue;
  if (hiddenDays !== '') {
    if ($.type(hiddenDays) == 'string') {
      // got a list of numbers
      hiddenDays = hiddenDays.split(",").map(Number);
    } else {
      // got a single number
      hiddenDays = [hiddenDays];
    }
  } else {
    hiddenDays = [];
  }
  
  var defaultLocale = calendar.localeValue;
  if (defaultLocale === 'en') {
    defaultLocale = 'en';
    var paramsLocale = 'en';
  }
  else {
    defaultLocale = 'fr-ca';
    var paramsLocale = 'fr';
  }

  var employeeId = calendar.employeeIdValue;
  if (employeeId == null) employeeId = '';
  
  var relationshipStatus = calendar.relationshipStatusValue;
  if (relationshipStatus == '') relationshipStatus = '';
  
  var readonly = calendar.readonlyValue;
  if (readonly == null) readonly = false;

  var futureDays = calendar.futureDaysValue;
  if (futureDays == "") futureDays = 365;
  var minDelayToBook = calendar.minDelayToBookValue;
  if (minDelayToBook == "") minDelayToBook = 0;
  var validRange = {
    end: moment().add(futureDays, 'days').format()
  }
  if (!pastAvailable) {
    validRange.start = moment().startOf('day').format("YYYY-MM-DD");
  }
  var selectConstraint = {
    startTime: moment().add(minDelayToBook, 'hours').format("YYYY-MM-DD"),
    endTime: moment().add(futureDays, 'days').format("YYYY-MM-DD")
  }
  return {
    plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
    initialView: 'timeGridWeek',
    headerToolbar: {
      start: 'prev,next today',
      center: 'title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    initialDate: defaultDate,
    locales: allLocales,
    locale: defaultLocale,
    employeeId: employeeId,
    relationshipStatus: relationshipStatus,
    paramsLocale: paramsLocale,
    slotMinTime: minTime,
    slotMaxTime: maxTime,
    hiddenDays: hiddenDays,
    validRange: validRange,
    selectConstraint: selectConstraint,
    selectMirror: true,
    selectable: !readonly,
    editable: !readonly,
    eventDurationEditable: false,
    dayMaxEventRows: true,
    eventColor: '#3273DC',
    navLinks: true, // can click day/week names to navigate views
    dayMaxEvents: true, // allow "more" link when too many events
  };
};

var calendar_popup_callback;
calendar_popup_callback = function(params, calendarEl) {
  const i18n = new I18n({
    fr,
    en,
  });
  i18n.locale = $('body').data('locale');
  var $form = $('#'+params['form_id']);
  var $calendar = $('#'+params['calendar_id']);
  // Set behavior and content of the form opened in popup
  $form.find('.calendar-form-error').hide();

  // attach a listener to the form to handle the submit
  var form = document.getElementById(params['form_id']);
  if (form) {
    form.addEventListener('ajax:success', function(event, a, b, c, d){
      var calendarEvent = calendarEl.getEventById(event.detail[0].id);
      if (calendarEvent) {
        calendarEvent.remove()
      }
      calendarEl.refetchEvents()

      // if I just confirmed or cancelled a pending session, remove it from the pending list
      // var pendingEvent = $('#approval-row-'+event.detail[0].id);
      var eventStatus = event.detail[0].status;
      if (eventStatus != 'pending' && eventStatus != 'pending_client_invitation_acceptation') {
        $('#approval-row-'+event.detail[0].id).hide();
      }
      if ($calendar.data('full-calendar-new-event-confirmation-path-value')) {
        window.location.assign($calendar.data('full-calendar-new-event-confirmation-path-value'));
      } else {
        var modal = document.getElementById('calendar-modal-wrapper');
        if(modal) {
          if (modal.getElementsByClassName('modal')[0]) {
            modal.getElementsByClassName('modal')[0].classList.remove('is-active');
          } else {
            $(".modal").modal('hide');
          }
        }
        var fullModal = document.getElementById('js-fullmodal-wrapper');
        if (fullModal) {
          fullModal.getElementsByClassName('js-fullmodal')[0].classList.remove('is-active');
        }
        // if I had a session id in url that opened the modal on page load, I want to remove it in case the user hits "reload" button
        const url = new URL(location);
        if (url.searchParams.get('session')) {
          url.search = '';
          history.pushState({}, "", url);
        }
      }
    });
    form.addEventListener('ajax:error', function(event, a, b, c, d){
      $('.js-fullmodal').scrollTop(0);
      if (event.detail[0]['recurring_until']) {
        $('#'+params['form_id']+' .calendar-form-error').html(i18n.t("end_date_must_be_greater"));
      } else if (event.detail[0]['email']) {
        $('#'+params['form_id']+' .calendar-form-error').html(i18n.t("client_already_associated_with_customer"));
      } else if (event.detail[0]['max_guests']) {
        $('#'+params['form_id']+' .calendar-form-error').html(i18n.t("cant_go_over_max_guests"));
      } else if (event.detail[0]['video_meeting_external_url']) {
        $('#'+params['form_id']+' .calendar-form-error').html(i18n.t("clms_video_meetings_max_10_users"));
      } else {
        $('#'+params['form_id']+' .calendar-form-error').html(i18n.t("please_complete_all_field_and_choose_upcoming_date"));
      }
      $('#'+params['form_id']+' .calendar-form-error').show();
    });
  }
  
  // Set startTime to the clicked hour unless it's 00:00 (00:00 == month view)
  if (params['current_action'] == 'new') {
    var start = params['start'];
    var end = params['end'];
    var startTime = moment(start).format("HH:mm");

    if (startTime != '00:00') {
      if (params['current_user_type'] == 'client') {
        $form.find('#'+params['datetime_field']+'_time').val(moment(start).format("HH:mm"));
      } else {
        $form.find('#'+params['datetime_field']+'_time_4i').val(moment(start).format("HH"));
        $form.find('#'+params['datetime_field']+'_time_5i').val(moment(start).format("mm"));
      }
      var duration = moment.duration(moment(end).diff(moment(start))).asMinutes();
      if (duration > 600) {
        // too long, is invalid, max 10 hours for now
        duration = 600;
      }
      $form.find('#'+params['duration_field']).val(duration);
      if (params['location_field']) {
        $form.find('#'+params['location_field']).val(params['location_id']);
      }
      if (params['location_overview']) {
        $form.find('.location-overview p').html(params['location_overview']);
      } else {
        $form.find('.location-overview').hide();
      }
    }
  } else {
    // attach a listener to the delete link to handle the deletion
    var deleteLink = document.getElementById('coach-calendar-delete-link');
    if (deleteLink) {
      deleteLink.addEventListener('ajax:success', function(event, a, b, c, d){
        if (params['current_user_type'] == 'client') {
          // In client mode, we want to full reload since the deleted session might allow an availability slot to show
          var calendarEvent = calendarEl.getEventById(event.detail[0].id);
          if (calendarEvent) {
            calendarEvent.remove()
          }
          calendarEl.refetchEvents()
        } else {
          // In coach mode, ok to simply delete this single event
          var calendarEvent = calendarEl.getEventById(event.detail[0].id);
          if (calendarEvent) {
            calendarEvent.remove()
          }
        }
        var modal = document.getElementById('calendar-modal-wrapper');
        if(modal) {
          if (modal.getElementsByClassName('modal')[0]) {
            modal.getElementsByClassName('modal')[0].classList.remove('is-active');
          } else {
            $(".modal").modal('hide');
          }
        }
        var fullModal = document.getElementById('js-fullmodal-wrapper');
        if (fullModal) {
          fullModal.getElementsByClassName('js-fullmodal')[0].classList.remove('is-active');
        }
      });
    }
  }

  // Apply bulma datepicker to the date field in opened popup
  var datePickers = bulmaCalendar.attach('[type="date"]', {
    displayMode: 'dialog',
    dateFormat: 'dd/MM/yyyy',
    lang: i18n.locale,
    showFooter: false
  });

  // Apply select2 plugin to the guests list
  $('.select2').select2({});

  // Show / hide the recurring options zone
  var showRecurringOptionsLink = document.getElementById('show-recurring-options');
  if (showRecurringOptionsLink) {
    showRecurringOptionsLink.addEventListener('click', function(event){
      event.preventDefault();
      var recurringOptions = document.getElementById('recurring-options');
      recurringOptions.classList.remove('hidden');
      this.classList.add('hidden');
    });
  }
  
  // Show / hide the Assignments "Other" type free text input
  // Attention, ce bout de code est dupliqué dans assignments/form.js 
  // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
  
  var showHideAssignmentTypeOther = function() {
    let assignment_type_select = document.getElementById('assignment_assignment_type_id');
    let assignment_type_input = document.getElementById('assignment_assignment_type_other');
    if (assignment_type_select && assignment_type_input) {
      if (assignment_type_select.value) {
        assignment_type_input.classList.add('hidden');
      } else {
        assignment_type_input.classList.remove('hidden');
      }
    }
  };
  let assignment_type_select = document.getElementById('assignment_assignment_type_id');
  if (assignment_type_select) {
    assignment_type_select.addEventListener('change', showHideAssignmentTypeOther.bind(assignment_type_select));
    showHideAssignmentTypeOther();
  }

  var deleteGuest = function(event) {
    if(event.target.classList.contains("delete-trigger") || event.target.parentNode.classList.contains("delete-trigger")){
      event.preventDefault();
      let parent = event.target.closest(".m_meeting-user");
      parent.remove();
      changeMeetingTypeAndCustomerList();
      warnForMissingFields();
    }
  }
  let guests_wrapper = document.getElementById('guests-wrapper');
  if (guests_wrapper) {
    guests_wrapper.addEventListener( "click", deleteGuest );
  }
  
  // Show / hide the Meetings "Other" type free text input
  // Attention, ce bout de code est dupliqué dans scheduled_sessions/form.js 
  // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
  var showHideMeetingTypeOther = function() {
    let meeting_type_select = document.getElementById('scheduled_session_meeting_type_id');
    let meeting_type_input = document.getElementById('scheduled_session_meeting_type_other');
    if (meeting_type_select && meeting_type_input) {
      if (meeting_type_select.value) {
        meeting_type_input.classList.add('hidden');
      } else {
        meeting_type_input.classList.remove('hidden');
      }
    }
  };
  var showHideGuestsType = function() {
    // Attention, ce bout de code est dupliqué dans scheduled_sessions/form.js
    // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
    let meeting_type_select = document.getElementById('scheduled_session_meeting_type_id');
    let customer_or_prospect_select = document.getElementById('scheduled_session_customer_or_prospect');
    const meeting_type_coaching_id_holder = document.getElementById('meeting_type_id_holder');
    if (meeting_type_select && customer_or_prospect_select && meeting_type_coaching_id_holder) {
      if (meeting_type_select.value == meeting_type_coaching_id_holder.value) {
        customer_or_prospect_select.value = 'client';
        customer_or_prospect_select.disabled = true;
      } else {
        customer_or_prospect_select.disabled = false;
      }
    }
  }
  let meeting_type_select = document.getElementById('scheduled_session_meeting_type_id');
  if (meeting_type_select) {
    meeting_type_select.addEventListener('change', showHideMeetingTypeOther.bind(meeting_type_select));
    showHideMeetingTypeOther();
    meeting_type_select.addEventListener('change', showHideGuestsType.bind(meeting_type_select));
    showHideGuestsType();
  }

  var changeMeetingTypeAndCustomerList = function() {
    let customer_or_prospect = document.getElementById('scheduled_session_customer_or_prospect');
    let meeting_type_select = document.getElementById('scheduled_session_meeting_type_id');
    if (customer_or_prospect && meeting_type_select) {
      // retrieve the currently selected guests IDs
      let client_ids = []
      let client_ids_inputs = document.getElementsByName("scheduled_session[client_ids][]");
      if (client_ids_inputs) {
        for(let i = 0;i < client_ids_inputs.length; i++) {
          if (!client_ids_inputs[i].disabled && client_ids_inputs[i].value) {
            client_ids.push(client_ids_inputs[i].value);
          }
        }
      }
      let user_ids = []
      let user_ids_inputs = document.getElementsByName("scheduled_session[user_ids][]");
      if (user_ids_inputs) {
        for(let i = 0;i < user_ids_inputs.length; i++) {
          if (!user_ids_inputs[i].disabled && user_ids_inputs[i].value) {
            user_ids.push(user_ids_inputs[i].value);
          }
        }
      }
      $.ajax({
        url: window.location.pathname + "/get_client_and_meeting_types?customer_or_prospect="+customer_or_prospect.value+"&client_ids="+client_ids+"&user_ids="+user_ids+"&meeting_type="+meeting_type_select.value,
        type: 'GET',
        dataType: "script",
        processData: false,
        contentType: false,
      });
    }
  }

  var addGuest = function(event) {
    // Attention, ce bout de code est dupliqué dans initialize-calendar.js dans le callback lorsqu'on ouvre la popup
    // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
    event.preventDefault();
    const btn_add_guest = document.getElementById('scheduled_session_btn_add_guest');
    const select_guest_id = document.getElementById('scheduled_session_guest_id');
    const guests_wrapper = document.getElementById('guests-wrapper');
    const guests_template = document.querySelector('#guests-wrapper .m_meeting-user.template');
    if (btn_add_guest && select_guest_id) {
      const guest_id = select_guest_id.value;
      if (guest_id) {
        const selected_guest_type = select_guest_id.options[select_guest_id.selectedIndex].dataset.guestType;
        // check if I already have this guest in the list
        const guest_node_id = 'guest-'+selected_guest_type+'-'+guest_id;
        if (!document.getElementById(guest_node_id)) {
          let guest = guests_template.cloneNode(true);
          let guest_name = guest.querySelector('span.name');
          let guest_email = guest.querySelector('span.email');
          let guest_avatar = guest.querySelector('span.avatar');
          let guest_input = guest.querySelector('input');
          const selected_name = select_guest_id.options[select_guest_id.selectedIndex].text;
          const selected_email = select_guest_id.options[select_guest_id.selectedIndex].dataset.email;
          let avatarURI;
          if (selected_guest_type == 'user') {
            avatarURI = '/users/firm/employees/'+guest_id+"/avatar";
            guest_input.name = 'scheduled_session[user_ids][]';
          } else {
            avatarURI = '/users/clients/'+guest_id+'/avatar';
            guest_input.name = 'scheduled_session[client_ids][]';
          }
          guest_name.innerHTML = selected_name;
          guest_email.innerHTML = selected_email;
          guest_input.value = guest_id;
          guest_input.disabled = false;
          guest.classList.remove("hidden", "template");
          guest.id = guest_node_id;
          guests_wrapper.appendChild(guest);
          
          $.get( "/"+$('html').attr('lang')+avatarURI, function( data ) {
            guest_avatar.innerHTML = data;
          });
          
          changeMeetingTypeAndCustomerList();
        } else {
          // guest already added, ignore.
        }
        warnForMissingFields();
      }
    }
  }

  var warnForMissingFields = function() {
    // Attention, ce bout de code est dupliqué dans initialize-calendar.js dans le callback lorsqu'on ouvre la popup
    // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
    const i18n = new I18n({fr, en});
    i18n.locale = $('body').data('locale');
    const guests_wrapper = document.getElementById('guests-wrapper');
    let warning_line = document.getElementById('warning-line');
    if (guests_wrapper && warning_line) {
      // check if I have at least one guest to the meeting
      let message = null;
      const guests_count = guests_wrapper.querySelectorAll('.m_meeting-user:not(.template)').length;
      if (guests_count > 0) {
        // Guest(s) present, check for location
        let location_present = false;
        const meeting_type = document.querySelector('input[name="meeting_type"]:checked').value
        if (meeting_type == 'phone') {
          // check if a phone is selected
          location_present = document.querySelector('input[name="scheduled_session[phone_number]"]:checked') !== null
        } else if (meeting_type == 'virtual') {
          // Virtual mode is always ok, if empty we generate teh url ourselves
          location_present = true;
        } else {
          // Physical, check if a location is checked
          location_present = document.querySelector('input[name="scheduled_session[location_id]"]:checked') !== null 
        }
        if (!location_present) {
          // Show warning about missing location
          message =  i18n.t("warning_no_location");
        }
      } else {
        // Show warning for 0 guests
        message =  i18n.t("warning_no_guest");
      }

      if (message == null) {
        // hide warning_line
        warning_line.classList.add("hidden");
      } else {
        // show warning_line
        warning_line.querySelector('.warning-line-text').innerHTML = message;
        warning_line.classList.remove('hidden');
      }
    }
  }

  let customer_or_prospect = document.getElementById('scheduled_session_customer_or_prospect');
  if (customer_or_prospect) {
    customer_or_prospect.addEventListener('change', changeMeetingTypeAndCustomerList.bind(customer_or_prospect));
    changeMeetingTypeAndCustomerList();
  }
  
  let btn_add_guest = document.getElementById('scheduled_session_btn_add_guest');
  if (btn_add_guest) {
    btn_add_guest.addEventListener('click', addGuest.bind(btn_add_guest));
  }

  let meeting_location_type_tabs = document.querySelector('.m_fullmodal-meetingSection .u_switchBtn');
  if (meeting_location_type_tabs) {
    meeting_location_type_tabs.addEventListener('click', () => warnForMissingFields());
  }
  document.querySelectorAll('.m_fullmodal-meetingSection input[type=radio]').forEach(radio => 
    radio.addEventListener('click', () => warnForMissingFields())
  );

  warnForMissingFields();

};
