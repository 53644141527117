class Tabs {
  start() {
    document.addEventListener("turbo:load", () => {
      if($('.js-tab').length){
        this.tabsToggles = $('.js-tab-toggle');
        this._activateTab(`#${$('.js-tab').first().attr('id')}`);
        this._bindEvents();

        let debouncedCssToTarget = this._debounce(this._adjustCssToTargetHandler.bind(this));
        $(window).on('load scroll resize orientationchange', debouncedCssToTarget);
      }
    });
  }

  _bindEvents() {
    this.tabsToggles.on('click', (ev) => {
      let toDeactivate = `#${$('.js-tab.u_tab-active').attr('id')}`;
      let toActivate = $(ev.currentTarget).attr('href');
      
      let next = this.tabsToggles.index($(ev.currentTarget));
      let ex = this.tabsToggles.index($('.js-tab-toggle.active'));
      let posNeg = next > ex ? 1 : -1;

      if(toDeactivate != toActivate) {
        this._deactivateTab(toDeactivate, posNeg).then( () => {
          this._activateTab(toActivate, posNeg);
        });
        this.tabsToggles.removeClass('active');
        this.tabsToggles.filter('a[href="' + $(ev.currentTarget).attr('href') + '"]').addClass('active');
      }
      ev.preventDefault();
    });
  }

  _activateTab(sel, posNeg) {
    const el = $(sel);
    const promise = new Promise(function(resolve, reject) {
      if(el.length && !el.hasClass('u_tab-active')) {
        const heightEl = el.find('.js-tab-height');
        const height = heightEl.outerHeight();
        el.addClass('u_tab-active');
        resolve();

        // anime({
        //     targets: '.js-tabs-wrapper',
        //     height,
        //     easing: bezier,
        //     // complete(){
        //         // resolve();
        //     // }
        // });
        $('.js-tabs-wrapper').animate({
          height
        });

        // anime({
        //     targets: sel,
        //     translateX: [dir, '0'],
        //     translateZ: 0,
        //     opacity: [0, 1],
        //     duration: 1500,
        //     easing: bezier,
        //     // complete(){
        //     //  resolve();
        //     // }
        // });
        
        if(posNeg === 1) {
          $(sel).addClass('u_tab-move-in-right');
        } else {
          $(sel).addClass('u_tab-move-in-left');
        }
        $(sel).on('animationend', () => {
          $(sel).removeClass('u_tab-move-in-left u_tab-move-in-right');
        });

        // $(sel).css({
        //     'transform': 'translate(' + dir + ', 0%, 0%)',
        //     'opacity': 0
        // });
        // $(sel).animate({
        //     'transform': 'translate(0%, 0%, 0%)',
        //     'opacity': 1
        // }, 500);
      }else{
        reject('Element not found or already activated');
      }
    });

    return promise;
  }

  _deactivateTab(sel, posNeg) {
    const el = $(sel);
    const promise = new Promise(function(resolve, reject) {
      if(el.length) {
        // const heightEl = el.find('.js-tab-height');
        // const height = heightEl.outerHeight();
        const dir = -100 * posNeg + '%';
        el.removeClass('u_tab-active');
        resolve();

        
        // anime({
        //     targets: sel,
        //     translateX: ['0%', dir],
        //     translateZ: 0,
        //     opacity: [1, 0],
        //     duration: 1500,
        //     easing: bezier,
        //     // complete(){
        //     //  resolve();
        //     // }
        // });
        
        // $(sel).css({
        //     'transform': 'translate(0%, 0%, 0%)',
        //     'opacity': 1
        // });
        // $(sel).animate({
        //     'transform': 'translate(' + dir + ', 0%, 0%)',
        //     'opacity': 0
        // }, 500);
        
        if(posNeg === 1) {
            $(sel).addClass('u_tab-move-out-left');
        } else {
            $(sel).addClass('u_tab-move-out-right');
        }
        $(sel).on('animationend', () => {
          $(sel).removeClass('u_tab-move-out-left u_tab-move-out-right');
        });
      }else{
        reject('Element not found');
      }
    });

    return promise;
  }

  /**
   * Adjust Height to target element's height
   * Used to fix layout problems with absolute elements
   *
   * @param  element          el          Dom element to adjust the height
   * @param  string           target      Query Selector to target one element to get height from
   * @param  string           attr        Attribute on the source to modify
   * @param  string           attrTarget  Attribute on the target to get value from
   * @param  int              multiplier  Multiply to allow reversing the value or other manipulations
   * @return void
   */
  _adjustCssToTarget(el, target, attr, attrTarget, mode, multiplier) {
    const _el = $(el);
    const target_element = $(target);

    if (target_element.length > 0) {
      const cssValue = target_element.first().css(attrTarget);
      const rectValue = target_element.first()[0].getBoundingClientRect()[attrTarget];
      const elValue = target_element.first()[0][attrTarget];
      let value = parseFloat(cssValue);

      if (mode == 'rect') {
        value = parseFloat(rectValue);
      } else if (mode == 'el') {
          value = parseFloat(elValue);
      }

      if (typeof multiplier === 'number') {
        value *= multiplier;
      }

      _el.css(attr, value);
      _el.addClass('css-target-adjusted');
    }
  }
  _adjustCssToTargetHandler(ev) {
    $('[data-css-target]').each((index, el) => {
      this._adjustCssToTarget(
        el,
        $(el).data('css-target'),
        $(el).data('css-attr'),
        $(el).data('css-target-attr'),
        $(el).data('css-target-mode'),
        $(el).data('css-multiplier')
      );
    });
  }

  _debounce(func) {
    var wait = arguments.length <= 1 || arguments[1] === undefined ? 500 : arguments[1];

    var timeout = void 0;
    return function () {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      clearTimeout(timeout);
      timeout = setTimeout(function () {
        func.apply(_this, args);
      }, wait);
    };
  }
  
}

export default Tabs
