import Rails from '@rails/ujs';

var autosaveBuffer = false;
function triggerAutosave(event) {
  if (!event.target.classList.contains('not-an-autosave-trigger')) {
    clearTimeout(autosaveBuffer);
    autosaveBuffer = setTimeout(autosaveNow, 1000);
    var autosaveInfo = document.getElementsByClassName('autosave-info')[0];
    var saveButtons = document.getElementsByClassName('autosave-manual');
    if (autosaveInfo) {
      autosaveInfo.classList.remove('success');
      autosaveInfo.classList.remove('error');
      autosaveInfo.classList.add('unsaved');
      autosaveInfo.innerHTML = "Dernières modifications non-sauvegardées...";
    }
    if (saveButtons) {
      Array.from(saveButtons).forEach(function(saveButton){
        saveButton.classList.add('active');
      });
    }
  }
}
function autosaveNow(event) {
  var autosaveInfo = document.getElementsByClassName('autosave-info')[0];
  var form = document.querySelectorAll('form.autosave')[0];
  if (autosaveInfo) {
    autosaveInfo.classList.remove('unsaved');
    autosaveInfo.innerHTML = 'Sauvegarde en cours...';
  }
  if (form) {
    // need this for the data-remote of rails to work and submit in ajax
    Rails.fire(form, 'submit');
  }
}

document.addEventListener("turbo:load", function() {
  var autosaveForm = document.querySelectorAll('form.autosave')[0];
  if (autosaveForm) {
    autosaveForm.addEventListener('keyup', triggerAutosave, false);
    autosaveForm.addEventListener('change', triggerAutosave, false);
    autosaveForm.addEventListener('click', triggerAutosave, false);

    // attach logic to the form ajax save success
    autosaveForm.addEventListener('ajax:success', function(event, a, b, c, d){
      if (event['detail'][0].progress == 100) {
        location.reload();
      }
      var autosaveInfo = document.getElementsByClassName('autosave-info')[0];
      if (autosaveInfo) {
        autosaveInfo.classList.add('success');
        autosaveInfo.innerHTML = 'Modifications enregistrées.';
      }
      var saveButtons = document.getElementsByClassName('autosave-manual');
      if (saveButtons) {
        Array.from(saveButtons).forEach(function(saveButton){
          saveButton.classList.remove('active');
        });
      }
      // reset the files upload fields if any
      var fileInputs = document.querySelectorAll('form.autosave input[type=file]');
      Array.from(fileInputs).forEach(function(fileInput){
        fileInput.value = '';
        fileInput.removeAttribute("disabled");
        // remove the hidden inputs associated to this file uploader
        var hiddenInputs = document.querySelectorAll('form.autosave input[type=hidden]');
        Array.from(hiddenInputs).forEach(function(hiddenInput){
          if (hiddenInput.name == fileInput.name) {
            hiddenInput.parentNode.removeChild(hiddenInput);
          }
        });
        // uploaded a file... refresh the links list
        if (event.detail[0].files.length > 0) {
          var linkList = fileInput.parentNode.parentNode.querySelectorAll('.js-files-link-list')[0];
          if (linkList) {
            linkList.innerHTML = '';
            Array.from(event.detail[0].files).forEach(function(file){
              var link = document.createElement("a"); // Create a <a> node
              link.setAttribute("href", file.url);
              link.setAttribute("target", "_blank");
              var label = document.createTextNode(file.filename); // Create a text node
              link.appendChild(label); // Append the text to <a>
              linkList.appendChild(link); // Append <a> to the link list div
            });
          }
        }
      });
    });
  }
});

document.addEventListener("turbo:before-visit", function(event) {
  // skip if in the grapesJsEditor screen
  if (typeof grapesJsEditor !== 'undefined') {
    var autosaveInfo = document.getElementsByClassName('autosave-info')[0];
    if (autosaveInfo && autosaveInfo.classList.contains('unsaved')) {
      // if (!confirm('Attention, vos modifications ne sont pas sauvegardées. Souhaitez-vous quand même quitter cette page?')) {
      //   event.preventDefault();
      // }
      // on ne donne pas le choix d'attendre...
      event.preventDefault();
      alert("La sauvegarde automatique est en train d'opérer sa magie.  Veuillez fermer cette fenêtre, attendre 3 secondes et sortir de nouveau.");
    }
  }
});