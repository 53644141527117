import { Controller } from "@hotwired/stimulus"
import lottie from 'lottie-web'

// Connects to data-controller="lottie"
export default class extends Controller {
  static targets = ["animation"]

  connect() {
    this.animationInstance = lottie.loadAnimation({
      container: this.animationTarget, // the dom element that will contain the animation
      renderer: 'svg', // 'canvas' or 'html'
      loop: false, // if true, the animation will loop
      autoplay: false, // if true, the animation will start automatically
      path: '/animations/congrats-animation.json' // the path to the animation json
    });
  }

  playAnimation() {
    const button = event.currentTarget;

    const closetActionContent = button.closest('.action-content');
    const formationRightPanel = button.closest('.formation-right-panel');

    if (closetActionContent) {
      const nearestActionCompleted = closetActionContent.querySelector('.action-completed')
      const nearestActionRequired = closetActionContent.querySelector('.action-required')
      
      if (nearestActionCompleted) {
        nearestActionCompleted.classList.remove('hidden');
        nearestActionRequired.classList.add('hidden');
      }
    }

    if (formationRightPanel) {
      const ratingCardModal = formationRightPanel.querySelector('.rating-card-modal')
      
      if (ratingCardModal) {
        ratingCardModal.classList.remove('hidden');
      }
    }
    
    this.animationInstance.goToAndPlay(0, true) // plays animation from the beginning
  }
}
