import ajaxCall from '../utilities/ajax.js';
import { I18n } from "i18n-js";
import fr from "../../translations/fr.json";
import en from "../../translations/en.json";

function updateSessionTime(event) {
  event.preventDefault();
  const i18n = new I18n({
    fr,
    en,
  });
  i18n.locale = $('body').data('locale')
  
  let input_field_id = '';
  let session_start = document.getElementsByClassName('sessionStartBtn');
  if(session_start && session_start[0].value == 'true') {
    session_start[0].value = false
  }
  if (this.classList.contains('scheduled-session-start-btn')) {
    input_field_id = 'scheduled_session_progress_start_time';
  } else if (this.classList.contains('scheduled-session-end-btn')) {
    input_field_id = 'scheduled_session_progress_end_time';
  }
  if (input_field_id == 'scheduled_session_progress_end_time' || confirm(i18n.t("confirm_start_meeting"))) {
    ajaxCall(
      this.dataset.url,
      'PATCH',
      (json_data) => {
        // Update start time with returned value
        if (json_data.time_val) {
          updateTimeInput(input_field_id, json_data.time_val);
        }

        // Change action btn
        if (json_data.action_btn) {
          changeProgressActionBtn(json_data.action_btn)
        }
      },
      (xhr) => {
        // console.log('Unable update session progress time. Returned status of ' + xhr.status);
      }
    );
  }
}

function updateTimeInput(id, new_val) {
  let time_input = document.getElementById(id);

  if (time_input) {
    time_input.value = new_val;
  }
}

function changeProgressActionBtn(action_btn_html) {
  let progress_action_div = document.getElementById('progress-action');

  let temp = document.createElement('template');
  temp.innerHTML = action_btn_html.trim();

  progress_action_div.innerHTML = '';
  progress_action_div.appendChild(temp.content.firstChild);

  let new_btn = progress_action_div.firstElementChild;

  if (new_btn.classList.contains('scheduled-session-start-btn') || new_btn.classList.contains('scheduled-session-end-btn')) {
    new_btn.onclick = updateSessionTime.bind(new_btn);
  }
}

document.addEventListener('turbo:load', function () {
  let start_btn = document.getElementsByClassName('scheduled-session-start-btn');
  Array.prototype.forEach.call(start_btn, (el) => {
    el.addEventListener('click', updateSessionTime.bind(el));
  });

  let end_btn = document.getElementsByClassName('scheduled-session-end-btn');
  Array.prototype.forEach.call(end_btn, (el) => {
    el.addEventListener('click', updateSessionTime.bind(el));
  });
});
