document.addEventListener('turbo:load', function () {
  let client_company_role_manager_radios = document.getElementsByName('client[company_role]');
  if (client_company_role_manager_radios) {
    let client_managed_departments = document.getElementById('client-managed-departments');
    for (var i = 0; i < client_company_role_manager_radios.length; i++) {
      client_company_role_manager_radios[i].addEventListener('change', function() {
        if (this.value == 'manager') {
          client_managed_departments.classList.remove("hidden");
        } else {
          client_managed_departments.classList.add("hidden");
        }
      });
    }

    let client_department_id = document.getElementById('client_department_id');
    if (client_department_id) {
      client_department_id.addEventListener('change', function() {
        let company_information = document.getElementsByClassName('company-information');
        if (this.value) {
          for (var i = 0; i < company_information.length; i++) {
            company_information[i].classList.add("hidden");
          }
        } else {
          for (var i = 0; i < company_information.length; i++) {
            company_information[i].classList.remove("hidden");
          }
        }
      });
    }
    let client_status = document.getElementById('client_status');
    let provider_address_div = document.getElementById('provider-address-div');
    let client_service_type_div = document.getElementById('client-service-type-div');
    let change_end_relationship_and_delete_provider_button = document.getElementById('change-end-relationship-and-delete-provider-button');
    let end_relationship_div = document.getElementById('end-relationship-div');
    let delete_provider_div = document.getElementById('delete-provider-div');
    let active_client_div = document.getElementsByClassName('active_client_div');
    let client_form_title = document.getElementsByClassName('client_form_title')[0];
    let prospect_form_title = document.getElementsByClassName('prospect_form_title')[0];
    let provider_form_title = document.getElementsByClassName('provider_form_title')[0];
    let partner_form_title = document.getElementsByClassName('partner_form_title')[0];
    let contact_form_title = document.getElementsByClassName('contact_form_title')[0];
    let provider_information_div = document.getElementById('provider-information-div');
    let client_and_prospect_business_name = document.getElementById('client-and-prospect-business-name');
    let client_and_prospect_tag_list = document.getElementById('client-and-prospect-tag-list');
    let provider_tag_list = document.getElementsByClassName('provider-tag-list')[0];
    let client_tag_list = document.getElementsByClassName('client-tag-list')[0];
    let provider_business_name = document.getElementsByClassName('provider-business-name')[0];
    let client_business_name = document.getElementsByClassName('client-business-name')[0];
    if (client_status) {
      client_status.addEventListener('change', function() {
        provider_address_div.classList.add("hidden");
        client_service_type_div.classList.add("hidden");
        provider_information_div.classList.add("hidden");
        client_and_prospect_business_name.classList.remove("hidden");
        client_and_prospect_tag_list.classList.remove("hidden");
        client_tag_list.removeAttribute('disabled');
        provider_tag_list.setAttribute('disabled', 'true');
        client_business_name.removeAttribute('disabled');
        provider_business_name.setAttribute('disabled', 'true');
        if (change_end_relationship_and_delete_provider_button) {
          end_relationship_div.classList.remove("hidden");
          delete_provider_div.classList.add("hidden");
        }
        if (client_form_title || prospect_form_title || provider_form_title || partner_form_title || contact_form_title) {
          client_form_title.classList.add("hidden");
          prospect_form_title.classList.add("hidden");
          provider_form_title.classList.add("hidden");
          partner_form_title.classList.add("hidden");
          contact_form_title.classList.add("hidden");
        }
        if (this.value == 'active' || this.value == 'pending') {
          provider_address_div.classList.remove("hidden");
          for (var i = 0; i < active_client_div.length; i++) {
            active_client_div[i].classList.remove("hidden");
          }
          if (this.value == 'active' && client_form_title) {
            client_form_title.classList.remove("hidden");
          }
          if (this.value == 'pending' && prospect_form_title) {
            prospect_form_title.classList.remove("hidden");
          }
        } else {
          for (var i = 0; i < active_client_div.length; i++) {
            active_client_div[i].classList.add("hidden");
          }
          console.log('provider_form_title', provider_form_title)
          if (provider_form_title || partner_form_title || contact_form_title) {
            if (this.value == 'provider') {
              client_and_prospect_business_name.classList.add("hidden");
              client_and_prospect_tag_list.classList.add("hidden");
              provider_form_title.classList.remove("hidden");
              provider_information_div.classList.remove("hidden");
              provider_address_div.classList.remove("hidden");
              client_service_type_div.classList.remove("hidden");
              client_tag_list.setAttribute('disabled', 'true');
              provider_tag_list.removeAttribute('disabled');
              client_business_name.setAttribute('disabled', 'true');
              provider_business_name.removeAttribute('disabled');
              if (change_end_relationship_and_delete_provider_button) {
                end_relationship_div.classList.add("hidden");
                delete_provider_div.classList.remove("hidden");
              }
            } else if (this.value == 'partner') {
              partner_form_title.classList.remove("hidden");
            } else {
              contact_form_title.classList.remove("hidden");
            }
          }
        }
      });
    }

    let client_service_type = document.getElementById('client_service_type');
    let client_other_service_type = document.getElementById('client_other_service_type');
    if (client_service_type) {
      client_service_type.addEventListener('change', function() {
        if (this.value == "Other") {
          client_other_service_type.classList.remove('hidden');
        } else {
          client_other_service_type.classList.add('hidden');
        }
      });
    }
  }
});
