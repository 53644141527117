document.addEventListener("turbo:load", function() {
  var links = document.querySelectorAll('.js-show-something')
  if (links) {
    Array.from(links).forEach(function(element){
      element.addEventListener('click', function(event){
        event.target.classList.add('hidden')
        document.getElementById(event.target.dataset.showId).classList.remove('hidden');
        event.preventDefault();
      });
    })
  }
});
