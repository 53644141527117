function csrfToken() {
  return document.head.querySelector("[name~=csrf-token][content]").content;
}

function ajaxCall(url, method, success_cb, error_cb) {
  let xhr = new XMLHttpRequest();
  xhr.open(method, url);
  xhr.setRequestHeader('X-CSRF-Token', csrfToken());
  xhr.onload = function () {
    if (xhr.status === 200) {
      success_cb(JSON.parse(xhr.responseText));
    } else {
      error_cb(xhr);
    }
  };

  xhr.send();
}

export default ajaxCall;