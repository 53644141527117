document.addEventListener('turbo:load', function () {
  var professionDropdown = document.querySelector('form.users-configuration-form #user_profession_id');
  if(professionDropdown) {
    professionDropdown.addEventListener('change', function(event){
      var professionID = event.target.value
      var professionCategoryDropdowns = document.getElementsByClassName('js-user-profession-categories');
      var professionSubCategoryDropdowns = document.getElementsByClassName('js-user-profession-subcategories');
      var specificDropdownForProfession = document.getElementById('users_profession_categories_for_profession_'+professionID);
      Array.from(professionCategoryDropdowns).forEach(function(professionCategoryDropdown){
        professionCategoryDropdown.classList.add('hidden');
        professionCategoryDropdown.querySelector('select').disabled = true;
      });
      Array.from(professionSubCategoryDropdowns).forEach(function(professionSubCategoryDropdown){
        professionSubCategoryDropdown.classList.add('hidden');
        professionSubCategoryDropdown.querySelector('select').disabled = true;
      });
      specificDropdownForProfession.querySelector('select').disabled = false;
      specificDropdownForProfession.classList.remove('hidden');
    });
  }

  $('form.users-configuration-form .user_profession_category_id').on('change', function(){
    var categoryID = event.target.value
    var professionSubCategoryDropdowns = document.getElementsByClassName('js-user-profession-subcategories');
    var specificDropdownForCategory = document.getElementById('users_profession_subcategories_for_category_'+categoryID);
    Array.from(professionSubCategoryDropdowns).forEach(function(professionSubCategoryDropdown){
      professionSubCategoryDropdown.classList.add('hidden');
      professionSubCategoryDropdown.querySelector('select').disabled = true;
    });
    specificDropdownForCategory.querySelector('select').disabled = false;
    specificDropdownForCategory.classList.remove('hidden');
  });

  var specializationDropdown = document.querySelector('form.users-configuration-form #user_specialization_id');
  if(specializationDropdown) {
    specializationDropdown.addEventListener('change', function(event){
      var specializationID = event.target.value
      var specializationCategoryDropdowns = document.getElementsByClassName('js-user-specialization-categories');
      var specializationSubCategoryDropdowns = document.getElementsByClassName('js-user-specialization-subcategories');
      var specificDropdownForSpecialization = document.getElementById('users_specialization_categories_for_specialization_'+specializationID);
      Array.from(specializationCategoryDropdowns).forEach(function(specializationCategoryDropdown){
        specializationCategoryDropdown.classList.add('hidden');
        specializationCategoryDropdown.querySelector('select').disabled = true;
      });
      Array.from(specializationSubCategoryDropdowns).forEach(function(specializationSubCategoryDropdown){
        specializationSubCategoryDropdown.classList.add('hidden');
        specializationSubCategoryDropdown.querySelector('select').disabled = true;
      });
      specificDropdownForSpecialization.querySelector('select').disabled = false;
      specificDropdownForSpecialization.classList.remove('hidden');
    });
  }

  $('form.users-configuration-form .user_specialization_category_id').on('change', function(){
    var categoryID = event.target.value
    var specializationSubCategoryDropdowns = document.getElementsByClassName('js-user-specialization-subcategories');
    var specificDropdownForSubcategory = document.getElementById('users_specialization_subcategories_for_category_'+categoryID);
    Array.from(specializationSubCategoryDropdowns).forEach(function(specializationSubCategoryDropdown){
      specializationSubCategoryDropdown.classList.add('hidden');
      specializationSubCategoryDropdown.querySelector('select').disabled = true;
    });
    specificDropdownForSubcategory.querySelector('select').disabled = false;
    specificDropdownForSubcategory.classList.remove('hidden');
  });

  $('form.users-registration-form #user_accept_terms_and_condition').on('change', function(){
    var registrationFormSubmitBtn = document.getElementById('registration-form-submit_btn');
    if(event.target.checked) {
      registrationFormSubmitBtn.classList.remove('hidden');
    } else {
      registrationFormSubmitBtn.classList.add('hidden');
    }
  });

  $('form.client-invitation-form #client_accept_terms_and_condition').on('change', function(){
    var invitationFormSubmitBtn = document.getElementById('invitation-form-submit_btn');
    if(event.target.checked) {
      invitationFormSubmitBtn.classList.remove('hidden');
    } else {
      invitationFormSubmitBtn.classList.add('hidden');
    }
  });
});