document.addEventListener('turbo:load', function () {

  var inputLists = Array.prototype.slice.call(document.querySelectorAll(".js-inputlist"), 0);

  if( inputLists.length > 0 ) {
    inputLists.forEach(function (inputList) {
      inputList.addEventListener( "click", wasBtnClicked );
    });
  }

  function wasBtnClicked(e) {
    if(e.target.classList.contains("js-inputlist-btn") || e.target.parentNode.classList.contains("js-inputlist-btn")){
      var btn = e.target.classList.contains("js-inputlist-btn") ? e.target : e.target.parentNode;
      var item = btn.parentNode;
      var list = item.parentNode;
      var listNodes = Array.prototype.slice.call( list.children );

      if( listNodes.length - 1 == listNodes.indexOf( item ) ) {
        // => add new item
        list.dataset.inputlistCount = parseInt(list.dataset.inputlistCount, 10) + 1;
        var templateHTML = _template(list.dataset.inputlistName, list.dataset.inputlistCount);
        list.insertAdjacentHTML('beforeend', templateHTML);
        var nodes = list.querySelectorAll('input.u_inputText');
        nodes[nodes.length- 1].focus();
      } else {
        // => remove item
        item.remove();
      }
    }
  }

  function _template (name, id) {
    return `
      <div class="u_inputList-item">
        <input class="u_inputText" type="text" name="${name}[${id}][name]">
        <a class='u_buttonCircle js-inputlist-btn'>
          <span>-</span><span>+</span>
        </a>
      </div>`
  }

});