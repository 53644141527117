import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rating"
export default class extends Controller {
  static targets = ["star", "value", "ratingValue"];

  initialize() {
    this.ratingValue = 0; // Initial rating value
  }

  setRating(event) {
    const starValue = parseFloat(event.currentTarget.dataset.value);
    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left;

    // Determine if it's a full or half-star selection
    if (clickX < rect.width / 2) {
      this.ratingValue = starValue - 0.5;
    } else {
      this.ratingValue = starValue;
    }

    this.valueTarget.textContent = this.ratingValue;
    this.ratingValueTarget.value = this.ratingValue;
    this.updateStars();
  }

  updateStars() {
    this.starTargets.forEach((star, index) => {
      const value = index + 1;
      star.classList.remove("empty-star", "half-empty-star", "full-star");

      if (this.ratingValue >= value) {
        star.classList.add("full-star"); // Full star
      } else if (this.ratingValue + 0.5 === value) {
        star.classList.add("half-empty-star"); // Half star
      } else {
        star.classList.add("empty-star"); // Empty star
      }
    });
  }

  resetRating() {
    this.updateStars();
  }
}
