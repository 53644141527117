import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="evaluation-rating"
export default class extends Controller {
  static targets = ["statedLearningStar", "statedLearningValue", "statedLearningScore", "teachingMethodStar", "teachingMethodValue", "teachingMethodScore", "visualPresentationStar", "visualPresentationValue", "visualPresentationScore", "recommendTrainingStar", "recommendTrainingValue", "recommendTrainingScore", "overallExperienceStar", "overallExperienceValue", "overallExperienceScore"];

  initialize() {
    // Initial rating value
    this.statedLearningScore = 0;
    this.teachingMethodScore = 0;
    this.visualPresentationScore = 0;
    this.recommendTrainingScore = 0;
    this.overallExperienceScore = 0;
  }

  setStatedLearningScore(event) {
    const starValue = parseFloat(event.currentTarget.dataset.value);
    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left;

    // Determine if it's a full or half-star selection
    if (clickX < rect.width / 2) {
      this.statedLearningScore = starValue - 0.5;
    } else {
      this.statedLearningScore = starValue;
    }

    this.statedLearningValueTarget.textContent = this.statedLearningScore;
    this.statedLearningScoreTarget.value = this.statedLearningScore;
    this.updateStars(this.statedLearningStarTargets, this.statedLearningScore);
  }

  setTeachingMethodScore(event) {
    const starValue = parseFloat(event.currentTarget.dataset.value);
    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left;

    // Determine if it's a full or half-star selection
    if (clickX < rect.width / 2) {
      this.teachingMethodScore = starValue - 0.5;
    } else {
      this.teachingMethodScore = starValue;
    }

    this.teachingMethodValueTarget.textContent = this.teachingMethodScore;
    this.teachingMethodScoreTarget.value = this.teachingMethodScore;
    this.updateStars(this.teachingMethodStarTargets, this.teachingMethodScore);
  }

  setVisualPresentationScore(event) {
    const starValue = parseFloat(event.currentTarget.dataset.value);
    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left;

    // Determine if it's a full or half-star selection
    if (clickX < rect.width / 2) {
      this.visualPresentationScore = starValue - 0.5;
    } else {
      this.visualPresentationScore = starValue;
    }

    this.visualPresentationValueTarget.textContent = this.visualPresentationScore;
    this.visualPresentationScoreTarget.value = this.visualPresentationScore;
    this.updateStars(this.visualPresentationStarTargets, this.visualPresentationScore);
  }

  setRecommendTrainingScore(event) {
    const starValue = parseFloat(event.currentTarget.dataset.value);
    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left;

    // Determine if it's a full or half-star selection
    if (clickX < rect.width / 2) {
      this.recommendTrainingScore = starValue - 0.5;
    } else {
      this.recommendTrainingScore = starValue;
    }

    this.recommendTrainingValueTarget.textContent = this.recommendTrainingScore;
    this.recommendTrainingScoreTarget.value = this.recommendTrainingScore;
    this.updateStars(this.recommendTrainingStarTargets, this.recommendTrainingScore);
  }

  setOverallExperienceScore(event) {
    const starValue = parseFloat(event.currentTarget.dataset.value);
    const rect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - rect.left;

    // Determine if it's a full or half-star selection
    if (clickX < rect.width / 2) {
      this.overallExperienceScore = starValue - 0.5;
    } else {
      this.overallExperienceScore = starValue;
    }

    this.overallExperienceValueTarget.textContent = this.overallExperienceScore;
    this.overallExperienceScoreTarget.value = this.overallExperienceScore;
    this.updateStars(this.overallExperienceStarTargets, this.overallExperienceScore);
  }

  updateStars(starTargets, ratingScore) {
    starTargets.forEach((star, index) => {
      const value = index + 1;
      star.classList.remove("empty-star", "half-empty-star", "full-star");

      if (ratingScore >= value) {
        star.classList.add("full-star"); // Full star
      } else if (ratingScore + 0.5 === value) {
        star.classList.add("half-empty-star"); // Half star
      } else {
        star.classList.add("empty-star"); // Empty star
      }
    });
  }
}
