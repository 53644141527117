document.addEventListener('turbo:load', function () {

  var editableRings = Array.prototype.slice.call(document.querySelectorAll(".js-editableRing"), 0);

  if( editableRings.length > 0 ) {

    editableRings.forEach(function (editableRing) {
      var input = editableRing.querySelector('.js-editableRing-input');
      var btnPlus = editableRing.querySelector('.js-editableRing-btnPlus');
      var btnLess = editableRing.querySelector('.js-editableRing-btnLess');
      var ring = editableRing.querySelector('.u_progressRing-circle');

      btnPlus.addEventListener( "click", valuePlus );
      btnLess.addEventListener( "click", valueLess );
      input.addEventListener( "change", updateRing );

      updateRing();

      function valuePlus (e) {
        input.value = parseInt(input.value) + 25;
        if (input.value > 100) {
          input.value = 100;
        }
        updateRing();
      }

      function valueLess (e) {
        input.value = parseInt(input.value) - 25;
        if (input.value < 0) {
          input.value = 0;
        }
        updateRing();
      }

      function updateRing () {
        if (input.value >= 100) {
          input.value = 100;
          btnPlus.removeEventListener( "click", valuePlus );
          btnPlus.classList.add('disabled');
        }
        if (input.value >= 1) {
          btnLess.addEventListener( "click", valueLess );
          btnLess.classList.remove('disabled');
        }
        if (input.value <= 0) {
          input.value = 0
          btnLess.removeEventListener( "click", valueLess );
          btnLess.classList.add('disabled');
        }
        if (input.value <= 99) {
          btnPlus.addEventListener( "click", valuePlus );
          btnPlus.classList.remove('disabled');
        }

        var calculated_value = 208 - ( ( input.value / 100 ) * 208 ) ;
        ring.style.strokeDashoffset = calculated_value;
      }
    });
  }

});