import { I18n } from "i18n-js";
import fr from "../../translations/fr.json";
import en from "../../translations/en.json";

$(document).on('turbo:load', function () {
  window.userConfigurationForm();
});

window.userConfigurationForm = function(){
  const i18n = new I18n({
    fr,
    en,
  });

  var showHideVideoProviderType = function() {
    let user_presentation_provider_select = document.getElementById('user_presentation_provider');
    let youtube_video_input = document.getElementById('users_youtube_video');
    let vimeo_video_input = document.getElementById('users_vimeo_video');
    if (user_presentation_provider_select) {
      if (user_presentation_provider_select.value == 'youtube') {
        youtube_video_input.classList.remove('is-hidden');
        vimeo_video_input.classList.add('is-hidden');
      } else {
        vimeo_video_input.classList.remove('is-hidden');
        youtube_video_input.classList.add('is-hidden');
      }
    }
  };
  let user_presentation_provider_select = document.getElementById('user_presentation_provider');
  if (user_presentation_provider_select) {
    user_presentation_provider_select.addEventListener('change', showHideVideoProviderType.bind(user_presentation_provider_select));
    showHideVideoProviderType();
  }

  if( $('#user_trainings').length ) {
    $('#user_trainings tr').first().append('<td><a data-association-insertion-node="tbody#user_trainings" data-association-insertion-method="append" class="button is-success is-small add_fields" data-association="user_training" data-associations="user_trainings" data-association-insertion-template="<tr class=&quot;line-item nested-fields&quot;> <td> <input class=&quot;input&quot; type=&quot;text&quot; name=&quot;user[user_trainings_attributes][new_user_trainings][training]&quot; id=&quot;user_user_trainings_attributes_new_user_trainings_training&quot; /></td> <td> <input class=&quot;input&quot; type=&quot;text&quot; name=&quot;user[user_trainings_attributes][new_user_trainings][institute]&quot; id=&quot;user_user_trainings_attributes_new_user_trainings_institute&quot; /></td> <td> <input class=&quot;input u_inputText maxed-width&quot; type=&quot;date&quot; name=&quot;user[user_trainings_attributes][new_user_trainings][date]&quot; id=&quot;user_user_trainings_attributes_new_user_trainings_date&quot;></td> <td> <div class=&quot;select is-fullwidth&quot;> <select name=&quot;user[user_trainings_attributes][new_user_trainings][level]&quot; id=&quot;user_user_trainings_attributes_new_user_trainings_level&quot;><option selected=&quot;selected&quot; value=&quot;progress&quot;>In progress</option><option value=&quot;complete&quot;>Completed</option><option value=&quot;stop&quot;>Stopped</option></select></div></td> <td class=&quot;remove&quot;> <input value=&quot;false&quot; type=&quot;hidden&quot; name=&quot;user[user_trainings_attributes][new_user_trainings][_destroy]&quot; id=&quot;user_user_trainings_attributes_new_user_trainings__destroy&quot; /><a class=&quot;button is-danger is-small remove_fields dynamic&quot; href=&quot;#&quot;>-</a> </td></tr>" href="#">+</a></td>');
    $('#user_trainings .remove').first().remove();
  }

  if( $('#user_experiences').length ) {
    $('#user_experiences tr').first().append('<td><a data-association-insertion-node="tbody#user_experiences" data-association-insertion-method="append" class="button is-success is-small add_fields" data-association="user_experience" data-associations="user_experiences" data-association-insertion-template="<tr class=&quot;line-item nested-fields&quot;> <td> <input class=&quot;input&quot; type=&quot;text&quot; name=&quot;user[user_experiences_attributes][new_user_experiences][job_title]&quot; id=&quot;user_user_experiences_attributes_new_user_experiences_job_title&quot; /> </td> <td> <input class=&quot;input&quot; type=&quot;text&quot; name=&quot;user[user_experiences_attributes][new_user_experiences][company]&quot; id=&quot;user_user_experiences_attributes_new_user_experiences_company&quot; /></td> <td> <input class=&quot;input u_inputText maxed-width&quot; type=&quot;date&quot; name=&quot;user[user_experiences_attributes][new_user_experiences][from_date]&quot; id=&quot;user_user_experiences_attributes_new_user_experiences_from_date&quot;></td> <td> <input class=&quot;input u_inputText maxed-width&quot; type=&quot;date&quot; name=&quot;user[user_experiences_attributes][new_user_experiences][to_date]&quot; id=&quot;user_user_experiences_attributes_new_user_experiences_to_date&quot;></td> <td class=&quot;remove&quot;> <input value=&quot;false&quot; type=&quot;hidden&quot; name=&quot;user[user_experiences_attributes][new_user_experiences][_destroy]&quot; id=&quot;user_user_experiences_attributes_new_user_experiences__destroy&quot; /><a class=&quot;button is-danger is-small remove_fields dynamic&quot; href=&quot;#&quot;>-</a> </td></tr>" href="#">+</a></td>');
    $('#user_experiences .remove').first().remove();
  }

  if( $('#user_skills').length ) {
    i18n.locale = $('body').data('locale')
    $('#user_skills tr').first().append('<td><a data-association-insertion-node="tbody#user_skills" data-association-insertion-method="append" class="button is-success is-small add_fields" data-association="user_training" data-associations="user_skills" data-association-insertion-template="<tr class=&quot;line-item nested-fields&quot;> <td> <input class=&quot;input&quot; type=&quot;text&quot; name=&quot;user[user_skills_attributes][new_user_skills][name]&quot; id=&quot;user_user_skills_attributes_new_user_skills_training&quot; /></td> <td> <div class=&quot;select is-fullwidth&quot;> <select name=&quot;user[user_skills_attributes][new_user_skills][level]&quot; id=&quot;user_user_skills_attributes_new_user_skills_level&quot;><option selected=&quot;selected&quot; value=&quot;beginner&quot;>' + i18n.t("skill_beginner_level") + '</option><option value=&quot;intermediate&quot;>' + i18n.t("skill_intermediate_level") + '</option><option value=&quot;expert&quot;>' + i18n.t("skill_expert_level") + '</option></select></div></td> <td class=&quot;remove&quot;> <input value=&quot;false&quot; type=&quot;hidden&quot; name=&quot;user[user_skills_attributes][new_user_skills][_destroy]&quot; id=&quot;user_user_skills_attributes_new_user_skills__destroy&quot; /><a class=&quot;button is-danger is-small remove_fields dynamic&quot; href=&quot;#&quot;>-</a> </td></tr>" href="#">+</a></td>');
    $('#user_skills .remove').first().remove();
  }

  $('.coach-profile').on('click', function() {
    var tab = $(this).attr('id');
    if (tab === undefined) {
      tab = $('#public-profile-tabs ul li.is-active').next().attr('id');
    }

    if (tab !== $('#public-profile-tabs ul li.is-active').attr('id')) {
      $('#public-profile-tabs li').removeClass('is-active');
      $('#' + tab).addClass('is-active');

      $('#tab-content div').removeClass('is-active');
      $('div[data-content="' + tab + '"]').addClass('is-active');

    }
  });
}
