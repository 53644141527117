export function changePaymentType(element) {
  const removeFromBankWrapper = document.getElementById('remove-from-bank-wrapper');
  if (removeFromBankWrapper) {
    const removeFromBankInputs = removeFromBankWrapper.querySelectorAll('input');
    if (element.value == 'disbursement') {
      removeFromBankWrapper.classList.remove('hidden');
      if (removeFromBankInputs) {
        removeFromBankInputs.forEach((removeFromBankInput) => {
          removeFromBankInput.removeAttribute("disabled");
        });
      }
    } else {
      removeFromBankWrapper.classList.add('hidden');
      if (removeFromBankInputs) {
        removeFromBankInputs.forEach((removeFromBankInput) => {
          removeFromBankInput.setAttribute("disabled", true);
        });
      }
    }
  }
}

document.addEventListener('turbo:load', function () {
  if (document.getElementsByClassName('payment-form').length) {
    const payment_type_element = document.getElementById('payment_payment_type');
    payment_type_element.addEventListener('change', changePaymentType.bind(this, payment_type_element));
    changePaymentType(payment_type_element);
  }
});
