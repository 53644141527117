function handleExtensionChange(extension_field) {
  let reports_filter_form = document.getElementById('reports-filter-form');
  if (extension_field.value == 'pdf') {
    reports_filter_form.target = '_blank';
  } else {
    reports_filter_form.removeAttribute('target');
  }
}

document.addEventListener('turbo:load', function () {
  let reports_filter_form = document.getElementById('reports-filter-form');
  let reports_extension_select = document.getElementById('reports-extension-select');

  if(reports_filter_form && reports_extension_select) {
    reports_extension_select.addEventListener('change', handleExtensionChange.bind(this, reports_extension_select));
  }
});
