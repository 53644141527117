var lineItemPriceChanged = false;

function timeToQty(time_val) {
  let found = time_val.match(/(.*):(.*)/);
  if (found) {
    return parseFloat(found[1]) + parseFloat(found[2]) / 60;
  }

  return parseFloat(time_val.replace(',', '.'));
}

function updateMainAddress(clientAddr) {
  let mainAddress = document.getElementById("invoice_main_address");
  let billingAddress = document.getElementById("invoice_billing_address");
  let billingIsMain = document.getElementById("billing_same_as_main_address");
  mainAddress.value = clientAddr;

  if (billingIsMain.checked) {
    billingAddress.value = clientAddr;
  }
}

function updateHourlyRate(hourly_rate) {
  if (hourly_rate == null) {
    return;
  }
  
  const line_items_price = document.querySelectorAll('.line_item_price');
  line_items_price.forEach((element) => {
    element.value = hourly_rate;
    updateLineItemAmount(element);
  });
}

export function updatePriceAndDescriptionFromInvoiceItem(element) {
    $.get( "/users/invoice_items/"+element.value, function( data ) {
      const priceEl = element.parentNode.parentNode.parentNode.parentNode.querySelector('.line_item_price');
      const descriptionEl = element.parentNode.parentNode.parentNode.parentNode.querySelector('.line_item_description');
      const taxableEl = element.parentNode.parentNode.parentNode.parentNode.querySelector('.line_item_is_taxable');
      if (priceEl) {
        priceEl.value = (data.rate_cents / 100).toFixed(2);
      }
      if (descriptionEl) {
        descriptionEl.innerHTML = data.description;
      }
      if (taxableEl) {
        taxableEl.checked = data.is_taxable;
      }
      updateLineItemAmount(element.parentNode.parentNode);
    }, "json" );

}

export function changeServiceType(element) {
  const invoiceItemsBankContainerEl = element.parentNode.parentNode.querySelector('.select_invoice_item_bank');
  const invoiceItemsProductContainerEl = element.parentNode.parentNode.querySelector('.select_invoice_item_product');
  const invoiceItemsAssessmentBundleContainerEl = element.parentNode.parentNode.querySelector('.select_invoice_item_assessment_bundle');
  const invoiceItemsPackageContainerEl = element.parentNode.parentNode.querySelector('.select_invoice_item_package');
  const invoiceItemsBankListEl = element.parentNode.parentNode.querySelector('.line_item_invoice_item_id_bank');
  const invoiceItemsProductListEl = element.parentNode.parentNode.querySelector('.line_item_invoice_item_id_product');
  const invoiceItemsAssessmentBundleListEl = element.parentNode.parentNode.querySelector('.line_item_invoice_item_id_assessment_bundle');
  const invoiceItemsPackageListEl = element.parentNode.parentNode.querySelector('.line_item_invoice_item_id_package');
  const invoiceItemsOtherEl = element.parentNode.parentNode.querySelector('.select_invoice_item_other');
  if (invoiceItemsOtherEl) {
    invoiceItemsOtherEl.classList.add('hidden');
  }
  if (element.value == 'Bank') {
    if (invoiceItemsBankListEl) {
      invoiceItemsBankListEl.removeAttribute("disabled");
    }
    if (invoiceItemsBankContainerEl) {
      invoiceItemsBankContainerEl.classList.remove('hidden');
    }
    if (invoiceItemsProductListEl) {
      invoiceItemsProductListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsProductContainerEl) {
      invoiceItemsProductContainerEl.classList.add('hidden');
    }
    if (invoiceItemsAssessmentBundleListEl) {
      invoiceItemsAssessmentBundleListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsAssessmentBundleContainerEl) {
      invoiceItemsAssessmentBundleContainerEl.classList.add('hidden');
    }
    if (invoiceItemsPackageListEl) {
      invoiceItemsPackageListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsPackageContainerEl) {
      invoiceItemsPackageContainerEl.classList.add('hidden');
    }
  } else if (element.value == 'Product') {
    if (invoiceItemsProductListEl) {
      invoiceItemsProductListEl.removeAttribute("disabled");
    }
    if (invoiceItemsProductContainerEl) {
      invoiceItemsProductContainerEl.classList.remove('hidden');
    }
    if (invoiceItemsBankListEl) {
      invoiceItemsBankListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsBankContainerEl) {
      invoiceItemsBankContainerEl.classList.add('hidden');
    }
    if (invoiceItemsAssessmentBundleListEl) {
      invoiceItemsAssessmentBundleListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsAssessmentBundleContainerEl) {
      invoiceItemsAssessmentBundleContainerEl.classList.add('hidden');
    }
    if (invoiceItemsPackageListEl) {
      invoiceItemsPackageListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsPackageContainerEl) {
      invoiceItemsPackageContainerEl.classList.add('hidden');
    }
  } else if (element.value == 'AssessmentBundle') {
    if (invoiceItemsProductListEl) {
      invoiceItemsProductListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsProductContainerEl) {
      invoiceItemsProductContainerEl.classList.add('hidden');
    }
    if (invoiceItemsBankListEl) {
      invoiceItemsBankListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsBankContainerEl) {
      invoiceItemsBankContainerEl.classList.add('hidden');
    }
    if (invoiceItemsAssessmentBundleListEl) {
      invoiceItemsAssessmentBundleListEl.removeAttribute("disabled");
    }
    if (invoiceItemsAssessmentBundleContainerEl) {
      invoiceItemsAssessmentBundleContainerEl.classList.remove('hidden');
    }
    if (invoiceItemsPackageListEl) {
      invoiceItemsPackageListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsPackageContainerEl) {
      invoiceItemsPackageContainerEl.classList.add('hidden');
    }
  } else if (element.value == 'Package') {
    if (invoiceItemsProductListEl) {
      invoiceItemsProductListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsProductContainerEl) {
      invoiceItemsProductContainerEl.classList.add('hidden');
    }
    if (invoiceItemsBankListEl) {
      invoiceItemsBankListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsBankContainerEl) {
      invoiceItemsBankContainerEl.classList.add('hidden');
    }
    if (invoiceItemsAssessmentBundleListEl) {
      invoiceItemsAssessmentBundleListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsAssessmentBundleContainerEl) {
      invoiceItemsAssessmentBundleContainerEl.classList.add('hidden');
    }
    if (invoiceItemsPackageListEl) {
      invoiceItemsPackageListEl.removeAttribute("disabled");
    }
    if (invoiceItemsPackageContainerEl) {
      invoiceItemsPackageContainerEl.classList.remove('hidden');
    }
  } else {
    if (invoiceItemsBankListEl) {
      invoiceItemsBankListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsBankContainerEl) {
      invoiceItemsBankContainerEl.classList.add('hidden');
    }
    if (invoiceItemsProductListEl) {
      invoiceItemsProductListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsProductContainerEl) {
      invoiceItemsProductContainerEl.classList.add('hidden');
    }
    if (invoiceItemsAssessmentBundleListEl) {
      invoiceItemsAssessmentBundleListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsAssessmentBundleContainerEl) {
      invoiceItemsAssessmentBundleContainerEl.classList.add('hidden');
    }
    if (invoiceItemsPackageListEl) {
      invoiceItemsPackageListEl.setAttribute("disabled", true);
    }
    if (invoiceItemsPackageContainerEl) {
      invoiceItemsPackageContainerEl.classList.add('hidden');
    }
  }
  if (element.value == 'Other') {
    if (invoiceItemsOtherEl) {
      invoiceItemsOtherEl.classList.remove('hidden');
    }
  } 
}

// Update billing address on client change
function loadClientData(client) {
  if (typeof client === "undefined") {
    return;
  }

  let xhr = new XMLHttpRequest();
  xhr.open('GET', `/users/clients/${client.value}.json`);
  xhr.onload = function () {
    if (xhr.status === 200) {
      let clientData = JSON.parse(xhr.responseText);

      if (clientData.full_address) {
        updateMainAddress(clientData.full_address);
      }

      if (!lineItemPriceChanged && clientData.hourly_rate) {
        updateHourlyRate((clientData.hourly_rate.fractional/100.0).toFixed(2));
      }
    }
  };
  xhr.send();
}

function toggleBillingAddress() {
  let billingAddress = document.getElementById("invoice_billing_address");
  let billTo = document.getElementById("invoice_bill_to");

  if (this.checked) {
    let mainAddress = document.getElementById("invoice_main_address");
    billingAddress.setAttribute("disabled", true);
    billingAddress.value = mainAddress.value;
    billTo.setAttribute("disabled", true);
  } else {
    billingAddress.removeAttribute("disabled");
    billTo.removeAttribute("disabled");
  }
}

// Update due date on sale_condition change if data-due-date-offset is set
function updateDueDate(cond) {
  let offset = cond.selectedOptions[0].dataset.dueDateOffset;

  if (offset) {
    let dueDate = document.getElementById("invoice_due_date");
    let date = new Date();
    date.setDate(date.getDate() + parseInt(offset));
    dueDate.value = date.toISOString().slice(0, 10);
  }
}

// Update taxes and total
function computeTaxes() {
  let subtotal = document.getElementById("invoice_subtotal").value;
  let discount = document.getElementById("invoice_discount").value;
  let buyer_application_fees = document.getElementById("invoice_buyer_application_fees");
  let totalField = document.getElementById("invoice_total");
  let taxesFields = document.getElementsByClassName("invoice_tax");
  // let compound = document.getElementById("invoice_tax_profile_id").selectedOptions[0].dataset.compound;
  let total = parseFloat(subtotal.replace(/[^(\d+)\.(\d+)]/g,"")) - parseFloat(discount.replace(/[^(\d+)\.(\d+)]/g,""));

  let plate_form_charge = 0.0
  if(buyer_application_fees) {
    plate_form_charge = (parseFloat(subtotal.replace(/[^(\d+)\.(\d+)]/g,"")) * parseFloat(buyer_application_fees.dataset.applicationFeesRate))/100;
    buyer_application_fees.value = plate_form_charge.toFixed(2);
  }

  let lineItems = document.getElementsByClassName("line-item");

  let taxableAmount = 0.0;

  Array.prototype.forEach.call(lineItems, (lineItem) => {
    let isTaxable = lineItem.getElementsByClassName('line_item_is_taxable');
    if (isTaxable[0] && isTaxable[0].checked) {
      taxableAmount += Number(lineItem.getElementsByClassName('line_item_amount')[0].value);
    }
  });
  let taxBase = (taxableAmount - discount);

  Array.prototype.forEach.call(taxesFields, (taxField) => {
    // In compound taxes, we need to compute taxes on subtotal + previous tax amount
    // if (compound === "true") {
    //   taxBase = total;
    // }

    let taxAmount = (taxBase * parseFloat(taxField.dataset.taxRate))/100;

    // Change the field value
    // TODO - Might not fit all currencies to fix with 2 decimals
    taxField.value = taxAmount.toFixed(2);

    // Update Total
    total += taxAmount;
  });

  total += plate_form_charge;

  // Set the final value in total field
  totalField.value = total.toFixed(2);
}

function updateTaxLines(taxes) {
  let taxesDiv = document.getElementsByClassName("invoice_taxes");
  taxesDiv[0].innerHTML = '';

  taxes.map((tax) => {
    taxesDiv[0].innerHTML += `
      <div class="field">
        <label for="${tax.code}" class="label">${tax.name}</label>
        <input disabled="disabled" class="input invoice_tax" type="text" name="invoice[${tax.code}]" id="invoice_${tax.code}" data-tax-rate="${tax.rate}" />
      </div>`;
  });
}

// Update tax lines when tax profile changes
function loadTaxProfileData(taxProfile) {
  if (typeof taxProfile === "undefined") {
    return;
  }

  let xhr = new XMLHttpRequest();
  xhr.open("GET", `/tax_profiles/${taxProfile.value}.json`);
  xhr.onload = function () {
    if (xhr.status === 200) {
      let tpData = JSON.parse(xhr.responseText);

      if (tpData.taxes) {
        updateTaxLines(tpData.taxes);
        computeTaxes();
      }
    }
  };
  xhr.send();

}

// Update subtotal
export function updateSubtotal() {
  let lineItemsAmounts = document.getElementsByClassName("line_item_amount");
  let subtotal = document.getElementById("invoice_subtotal");

  let subtotalVal = 0;

  Array.prototype.forEach.call(lineItemsAmounts, (li) => {
    subtotalVal += parseFloat(li.value);
  });

  subtotal.value = subtotalVal.toFixed(2);

  if (document.getElementById('invoice_discount_type') && document.getElementById('invoice_discount_type').value === 'percent') {
    updateDiscountValueFromPercentage.call(document.getElementById('invoice_discount_percent'));
  } else {
    computeTaxes();
  }
}

function updateDiscountValueFromPercentage() {
  let discount = document.getElementById('invoice_discount');
  let subtotal = parseFloat(document.getElementById('invoice_subtotal').value);
  let percentage = parseFloat(this.value || 0.0) / 100.0;

  discount.value = (subtotal * percentage).toFixed(2);
  computeTaxes();
}

function updateDiscountValue(discount) {
  let discount_val_field = document.getElementById('invoice_discount');
  discount_val_field.value = parseFloat(discount.value.replace(/[^(\d+)\.(\d+)]/g, '') || 0.0).toFixed(2);
  computeTaxes();
}

export function updateLineItemAmount(element) {
  let rowEl = element.parentNode.parentNode;
  let qty = timeToQty(rowEl.querySelector('.line_item_qty').value) || 0;
  let price = rowEl.querySelector('.line_item_price').value || 0;
  let amount = rowEl.querySelector('.line_item_amount');
  
  amount.value = (qty * parseFloat(price)).toFixed(2);
  updateSubtotal();
}

function handleStatusChange(status_field) {
  if (status_field.value == 'cancelled') {
    document.getElementById('invoice_cancellation_notes').parentElement.classList.remove('is-hidden');
  } else {
    document.getElementById('invoice_cancellation_notes').parentElement.classList.add('is-hidden');
  }
}

function handlePaymentMethodChange(payment_method) {
  const payment_reference_types = document.querySelectorAll('.payment-reference-types');
  payment_reference_types.forEach(element => {
    element.classList.add('is-hidden');
  });
  let other_payment_note = document.getElementById('invoice_other_payment_note').parentElement;
  let cheque_number = document.getElementById('invoice_cheque_number').parentElement;
  let payment_reference_number = document.getElementById('invoice_payment_reference_number').parentElement;
  let credit_card_type = document.getElementById('invoice_credit_card_type').parentElement;
  if (this.selectedOptions[0].dataset.paymentMethodName == 'Other') {
    other_payment_note.classList.remove('is-hidden');
  } else if (this.selectedOptions[0].dataset.paymentMethodName == 'Check') {
    cheque_number.classList.remove('is-hidden');
  } else if ((this.selectedOptions[0].dataset.paymentMethodName == 'Online Payment') || (this.selectedOptions[0].dataset.paymentMethodName == 'Paypass') || (this.selectedOptions[0].dataset.paymentMethodName == 'Square') || (this.selectedOptions[0].dataset.paymentMethodName == 'Interac')) {
    payment_reference_number.classList.remove('is-hidden');
  } else if (this.selectedOptions[0].dataset.paymentMethodName == 'Credit Card') {
    credit_card_type.classList.remove('is-hidden');
  }
}

function changeVisibleDiscountField() {
  let discount = document.getElementById('invoice_discount_amount');
  let discount_percentage = document.getElementById('invoice_discount_percent');

  if (this.value == 'amount') {
    discount.parentElement.classList.remove('is-hidden');
    discount_percentage.parentElement.classList.add('is-hidden');
    updateDiscountValue(discount);
  } else {
    discount_percentage.parentElement.classList.remove('is-hidden');
    discount.parentElement.classList.add('is-hidden');
    updateDiscountValueFromPercentage.call(discount_percentage);
  }
}

document.addEventListener('turbo:load', function () {
  if (document.getElementsByClassName('invoice-form').length) {

    computeTaxes();

    const invoice_client = document.getElementById('invoice_client_id');
    invoice_client.addEventListener('change', loadClientData.bind(this, invoice_client));

    const sale_condition = document.getElementById('invoice_sale_condition');
    if (sale_condition) {
      sale_condition.addEventListener('change', updateDueDate.bind(this, sale_condition));
    }
    const tax_profile = document.getElementById('invoice_tax_profile_id');
    if (tax_profile) {
      tax_profile.addEventListener('change', loadTaxProfileData.bind(this, tax_profile));
    }

    // Update discount value when discount type changes
    const discount_type = document.getElementById('invoice_discount_type');
    if (discount_type) {
      discount_type.addEventListener('change', changeVisibleDiscountField.bind(discount_type));
    }

    // Update subtotal on discount change
    const discount = document.getElementById('invoice_discount_amount');
    if (discount) {
      discount.addEventListener('change', updateDiscountValue.bind(this, discount));
    }

    const discount_percentage = document.getElementById('invoice_discount_percent');
    if (discount_percentage) {
      discount_percentage.addEventListener('change', updateDiscountValueFromPercentage.bind(discount_percentage));
    }

    const status = document.getElementById('invoice_status');
    if (status) {
      status.addEventListener('change', handleStatusChange.bind(this, status));
    }

    const payment_method = document.getElementById('invoice_payment_method_id');
    if (payment_method) {
      payment_method.addEventListener('change', handlePaymentMethodChange.bind(payment_method));
    }

    const billingSameAsMain = document.getElementById("billing_same_as_main_address");
    if (billingSameAsMain) {
      billingSameAsMain.addEventListener('change', toggleBillingAddress.bind(billingSameAsMain));
    }

    // NOTE: Updating amount also updates the subtotal and taxes
    // Update amount on qty change

    const line_items_invoice_item_id = document.querySelectorAll('.line_item_invoice_item_id');
    line_items_invoice_item_id.forEach(element => {
      element.addEventListener('change', updatePriceAndDescriptionFromInvoiceItem.bind(this, element));
    });
    
    const line_items_service_type = document.querySelectorAll('.line_item_service_type');
    line_items_service_type.forEach(element => {
      element.addEventListener('change', changeServiceType.bind(this, element));
    });

    const line_items_qty = document.querySelectorAll('.line_item_qty');
    line_items_qty.forEach(element => {
      element.addEventListener('change', updateLineItemAmount.bind(this, element));
    });

    // Update amount on service rate change
    const line_items_price = document.querySelectorAll('.line_item_price');
    line_items_price.forEach(element => {
      element.addEventListener('change', () => {lineItemPriceChanged = true;});
      element.addEventListener('change', updateLineItemAmount.bind(this, element));
    });

    // Update amount on service rate change
    const line_items_is_taxable = document.querySelectorAll('.line_item_is_taxable');
    line_items_is_taxable.forEach(element => {
      element.addEventListener('change', () => {lineItemPriceChanged = true;});
      element.addEventListener('change', updateLineItemAmount.bind(this, element));
    });
  }
});
