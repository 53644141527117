import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="map-places"
export default class extends Controller {
  static targets = [ "field", "latitude", "longitude", "timezone", "address_line1", "address_line2", "city", "state", "country", "postal_code", "place_id" ]

  connect() {
    this.addressAutocomplete(document.getElementById("autocomplete-container"), (data) => {
      this.address_line1Target.value = data.properties.address_line1;
      this.address_line2Target.value = data.properties.address_line2;
      this.cityTarget.value = data.properties.city;
      this.stateTarget.value = data.properties.state;
      this.countryTarget.value = data.properties.country;
      this.postal_codeTarget.value = data.properties.postcode;
      this.timezoneTarget.value = JSON.stringify(data.properties.timezone);
      this.place_idTarget.value = data.properties.place_id;
      this.latitudeTarget.value = data.properties.lat;
      this.longitudeTarget.value = data.properties.lon;
    });
  }

  /* 
    The addressAutocomplete takes a container element (div) as a parameter
  */
  addressAutocomplete(containerElement, callback) {
    // find input element
    var inputElement = document.getElementById("autocomplete-field");
    var geoapify_api_key = document.getElementById("user_geoapify_api_key")

    /* Active request promise reject function. To be able to cancel the promise when a new request comes */
    var currentPromiseReject;

    /* Current autocomplete items data (GeoJSON.Feature) */
    var currentItems;

    if (inputElement) {
      /* Execute a function when someone writes in the text field: */
      inputElement.addEventListener("input", function(e) {
        /* Close any already open dropdown list */
        closeDropDownList(containerElement);

        var currentValue = this.value;

        // Cancel previous request promise
        if (currentPromiseReject) {
          currentPromiseReject({
            canceled: true
          });
        }

        if (!currentValue) {
          return false;
        }

        /* Create a new promise and send geocoding request */
        var promise = new Promise((resolve, reject) => {
          currentPromiseReject = reject;

          var url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(currentValue)}&limit=5&apiKey=${geoapify_api_key.value}`;

          fetch(url)
            .then(response => {
              // check if the call was successful
              if (response.ok) {
                response.json().then(data => resolve(data));
              } else {
                response.json().then(data => reject(data));
              }
            });
        });

        promise.then((data) => {
          currentItems = data.features;

          /*create a DIV element that will contain the items (values):*/
          var autocompleteItemsElement = document.createElement("div");
          autocompleteItemsElement.setAttribute("class", "autocomplete-items");
          containerElement.appendChild(autocompleteItemsElement);

          /* For each item in the results */
          data.features.forEach((feature, index) => {
            /* Create a DIV element for each element: */
            var itemElement = document.createElement("DIV");
            /* Set formatted address as item value */
            itemElement.innerHTML = feature.properties.formatted;

            /* Set the value for the autocomplete text field and notify: */
            itemElement.addEventListener("click", function(e) {
              inputElement.value = currentItems[index].properties.formatted;
              callback(currentItems[index]);
              /* Close the list of autocompleted values: */
              closeDropDownList(containerElement);
            });

            autocompleteItemsElement.appendChild(itemElement);
          });
        }, (err) => {
          if (!err.canceled) {
            console.log(err);
          }
        });
      });
    }
  }
}

function closeDropDownList(containerElement) {
  var autocompleteItemsElement = containerElement.querySelector(".autocomplete-items");
  if (autocompleteItemsElement) {
    containerElement.removeChild(autocompleteItemsElement);
  }
}
