import grapesjs from 'grapesjs';
import customCodePlugin from 'grapesjs-custom-code'; 


var grapesJsEditor;
document.addEventListener("turbo:load", function() {
  if(document.querySelector('#admin_gjs')) {
    var lastSavedAt = 0;
    var gjsContainer = document.getElementById('admin_gjs');
    var saveBtn = document.querySelector('.save-btn');

    function assetsRemovalPlugin(editor){
      // Get the Asset Manager module
      const am = editor.AssetManager;
      // Extend the original `image` and add a confirm dialog before removing it
      am.addType('image', {
        view: {
          onRemove(e) {
            e.stopPropagation();
            const model = this.model;
            if (confirm('Are you sure?')) {
              model.collection.remove(model);
              var xhttp = new XMLHttpRequest();
              xhttp.open("POST", "grapesjs_asset_remove", true);
              xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
              xhttp.send("file="+model.id);
            }
          }
        },
      });
    }
    function clmsBlocks(editor){
      editor.BlockManager.add('button', {
        attributes: {
          class: 'fa fas fa-square'
        },
        category: 'Basic',
        label: 'Button',
        content: '<a href="#" class="">Go!</button>',
        // content: '<button type="button" class="">Go!</button>',
      });
    }

    initializeGrapesJS();

    function initializeGrapesJS() {
      grapesJsEditor = grapesjs.init({
        container : '#admin_gjs',
        allowScripts: 1,
        // fromElement: true,
        // Disable the storage manager for the moment
        storageManager: false,
        // Avoid any default panel
        // panels: { defaults: [] },
        plugins: [
          'gjs-blocks-basic', 
          assetsRemovalPlugin,
          customCodePlugin,
          clmsBlocks
        ],
        pluginsOpts: {
          'gjs-blocks-basic': {/* ...options */}
        },
        storageManager: {
          type: 'remote',
          autosave: false,
          // stepsBeforeSave: 2,
          urlStore: 'grapesjs_store',
          urlLoad: 'grapesjs_load',
          params: {}, // Custom parameters to pass with the remote storage request, eg. CSRF token
          headers: {}, // Custom headers for the remote storage request
        },
        assetManager: {
          addBtnText: "Ajouter l'image",
          upload: 'grapesjs_asset_upload',
          assets: gjsContainer.dataset.assets.split(';'),
          // multiUpload: false,
          // autoAdd: 1,
        },
      });
    }
    
    // Fonctions pour les administrateurs : 
    if (gjsContainer.dataset.advanced === 'true') {
      //////////////////////////////////////////
      // Bouton "View code deviens Edit code" //
      //////////////////////////////////////////
      // @see https://github.com/artf/grapesjs/issues/324
      var pfx = grapesJsEditor.getConfig().stylePrefix
      var modal = grapesJsEditor.Modal
      var cmdm = grapesJsEditor.Commands
      var htmlCodeViewer = grapesJsEditor.CodeManager.getViewer('CodeMirror').clone()
      var cssCodeViewer = grapesJsEditor.CodeManager.getViewer('CodeMirror').clone()
      var pnm = grapesJsEditor.Panels
      var container = document.createElement('div')
      var btnEdit = document.createElement('button')

      htmlCodeViewer.set({
        codeName: 'htmlmixed',
        readOnly: 0,
        theme: 'hopscotch',
        autoBeautify: true,
        autoCloseTags: true,
        autoCloseBrackets: true,
        lineWrapping: true,
        styleActiveLine: true,
        smartIndent: true,
        indentWithTabs: true
      })

      cssCodeViewer.set({
        codeName: 'css',
        readOnly: 0,
        theme: 'hopscotch',
        autoBeautify: true,
        autoCloseTags: true,
        autoCloseBrackets: true,
        lineWrapping: true,
        styleActiveLine: true,
        smartIndent: true,
        indentWithTabs: true
      })

      btnEdit.innerHTML = 'Importer'
      btnEdit.className = pfx + 'btn-prim ' + pfx + 'btn-import'
      btnEdit.onclick = function () {
        var html = htmlCodeViewer.editor.getValue()
        var css = cssCodeViewer.editor.getValue()
        grapesJsEditor.DomComponents.getWrapper().set('content', '')
        grapesJsEditor.setComponents(html.trim())
        grapesJsEditor.setStyle(css)
        modal.close()
      }

      cmdm.add('edit-code', {
        run: function (editor, sender) {
          sender && sender.set('active', 0)
          var htmlViewer = htmlCodeViewer.editor
          var cssViewer = cssCodeViewer.editor
          modal.setTitle('Edit code')
          if (!htmlViewer && !cssViewer) {
            var txtlabel = document.createElement('label')
            txtlabel.appendChild(document.createTextNode("HTML"));
            var txtarea = document.createElement('textarea')
            var csslabel = document.createElement('label')
            csslabel.appendChild(document.createTextNode("CSS"));
            var cssarea = document.createElement('textarea')
            
            container.appendChild(txtlabel)
            container.appendChild(txtarea)
            container.appendChild(csslabel)
            container.appendChild(cssarea)
            container.appendChild(btnEdit)
            htmlCodeViewer.init(txtarea)
            cssCodeViewer.init(cssarea)
            htmlViewer = htmlCodeViewer.editor
            cssViewer = cssCodeViewer.editor
          }
          var InnerHtml = editor.getHtml()
          var Css = editor.getCss()
          modal.setContent('')
          modal.setContent(container)
          htmlCodeViewer.setContent(InnerHtml)
          cssCodeViewer.setContent(Css)
          modal.open()
          htmlViewer.refresh()
          cssViewer.refresh()
        }
      })

      pnm.addButton('options',
        [
          {
            id: 'edit',
            className: 'fa fa-upload',
            command: 'edit-code',
            attributes: {
              title: 'Edit Code'
            }
          }
        ]
      )
    } // Fin fonctions pour les administrateurs
    
    // btn to explicitely save
    if(saveBtn) {
      grapesJsEditor.on('change:changesCount', e => {
        if (grapesJsEditor.getDirtyCount() > 0) {
          var saveInfo = document.querySelector('.autosave-info');
          saveInfo.classList.add('unsaved');
          saveInfo.innerHTML = "Dernières modifications non-sauvegardées...";
          
          // manual timebased autosave based on time
          if ((Date.now() - lastSavedAt) >= 2000) {
            saveNow();
          }
        
        } else {
          var saveInfo = document.querySelector('.autosave-info');
          if (saveInfo) {
            saveInfo.classList.remove('saving');
            saveInfo.innerHTML = "Sauvegardé!";
            lastSavedAt = Date.now();
          }
        }
      });
      
      saveBtn.addEventListener('click', function(event){
        event.preventDefault();
        // dirtyCount holds the number of changes since last save
        if (grapesJsEditor.getDirtyCount() > 0) {
          saveNow();
        }
        
      });
      
      function saveNow() {
        var saveInfo = document.querySelector('.autosave-info');
        saveInfo.classList.add('saving');
        saveInfo.classList.remove('unsaved');
        saveInfo.innerHTML = "Sauvegarde en cours...";
        // send save only if changes were made...
        
        grapesJsEditor.store(res => { 
          // disabled... will happen in the on('change:changesCount') above anyway
          // var saveInfo = document.querySelector('.autosave-info');
          // if (saveInfo) {
          //   saveInfo.classList.remove('saving');
          //   saveInfo.innerHTML = "Sauvegardé!";
          // }
        });
      }
    }
  }
});

document.addEventListener("turbo:before-visit", function(event) {
  if (grapesJsEditor && grapesJsEditor.getDirtyCount() > 0) {
    // check if I have unsaved changes
    if (!confirm('Attention, vos modifications ne sont pas sauvegardées. Souhaitez-vous quand même quitter cette page?')) {
      event.preventDefault();
    }
  }
});
