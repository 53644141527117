class NestedFormsLearningElement {
  start() {
    document.addEventListener("turbo:load", function() {
      if (document.getElementById('learning-element-item-list')) {
        this._createDOMEventListeners()
        this.nextID = parseInt(document.getElementById('js-nested-form-learning-element-add').dataset.learningElementCount, 10);
      }
    }.bind(this))
  }

  _addLearningElement () {
    this.nextID = this.nextID + 1
    var templateHTML = this._template(this.nextID)
    document.getElementById('learning-element-item-list').insertAdjacentHTML('beforeend', templateHTML)
    var allRemoveButtonElements = document.getElementsByClassName('js-nested-form-learning-element-remove')
    this._createRemoveEventListener(allRemoveButtonElements[allRemoveButtonElements.length - 1])
  }

  _destroyLearningElement (elementToRemove) {;
    elementToRemove.className = elementToRemove.className + " is-hidden"

    var destroyHiddenField = elementToRemove.getElementsByClassName('js-nested-form-learning-element-destroy')[0]
    if(destroyHiddenField) {
      destroyHiddenField.value = true
    } else {
      elementToRemove.remove()
    }
  };

  // This is to create the events listening to some DOM events
  _createDOMEventListeners () {
    this._createAddEventListener()

    var removeLearningElementButtons = document.getElementsByClassName('js-nested-form-learning-element-remove')
    Array.from(removeLearningElementButtons).forEach(function(element){
      this._createRemoveEventListener(element)
    }.bind(this));
  }

  _template (id) {
    return `
      <div class="learning-element-item m-b-md">
        <div class="columns">
          <div class="column is-four-fifths">
            <input class="input" type="text" name="scheduled_session[learning-elements_attributes][${id}][name]">
          </div>
          <div class='column'>
            <a class='js-nested-form-learning-element-remove button is-info is-outlined'>-</a>
          </div>
        </div>
      </div>`
  }

  _createAddEventListener () {
    var addLearningElementButton = document.getElementById('js-nested-form-learning-element-add')
    if (addLearningElementButton) {
      addLearningElementButton.addEventListener('click', function(event) {
        event.preventDefault()
        this._addLearningElement()
      }.bind(this));
    }
  }

  _createRemoveEventListener (element) {
    element.addEventListener('click', function(event) {
      event.preventDefault()
      var elementToRemove = event.target.parentElement.parentElement.parentElement

      if (elementToRemove.className.indexOf('learning-element-item') != -1) {
        this._destroyLearningElement(elementToRemove)
      } else {
        throw new Error('Noooooo.... :(');
      }
    }.bind(this));
  }
}
export default NestedFormsLearningElement
