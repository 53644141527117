// Firefox has a visual difference that I was not able to fix via CSS. This is a hack that makes it look nicer.

document.addEventListener( 'turbo:load', function () {
  if (navigator.userAgent.indexOf('Firefox') != -1) {
    var outputs = document.querySelectorAll( 'output' );
    [].forEach.call( outputs, function ( output ) {
      output.style.position = 'relative';
    } );
  }
} );
