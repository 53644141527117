document.addEventListener("turbo:load", function() {
  var featuredArticleContentSwitches = Array.prototype.slice.call(document.querySelectorAll(".isFeaturedArticleContentSwitch"), 0);

  if( featuredArticleContentSwitches.length > 0 ) {
    featuredArticleContentSwitches.forEach(function (featuredArticleContentSwitche) {
      var visibleBtn = featuredArticleContentSwitche.querySelector('.isFeaturedVisibilitySwitch-visible');
      var hiddenBtn = featuredArticleContentSwitche.querySelector('.isFeaturedVisibilitySwitch-hidden');
      var public_featured_article = document.querySelector(".articleFeaturedTrue");
      var private_featured_article = document.querySelector(".articleFeaturedFalse");

      hiddenBtn.addEventListener( "click", hideContent );
      visibleBtn.addEventListener( "click", showContent );

      function hideContent () {
        public_featured_article.classList.add('hidden')
        private_featured_article.classList.remove('hidden')
      }

      function showContent () {
        public_featured_article.classList.remove('hidden')
        private_featured_article.classList.add('hidden')
      }
    });
  }
});
