class ListFilter {
  start() {
    document.addEventListener("turbo:load", function() {
      this.inputs = $('.js-filter-list');
      this.inputs.each(function(index, input) {
        let targets = $($(input).data('filter-elements'));
        let filterElement = $(input).data('filter-txt');
        this._init(input, targets, filterElement);
      }.bind(this));
    }.bind(this))
  }

  _init(input, targets, filterElement) {
    let _input = $(input);
    _input.on('keyup', this._filter.bind(this, targets, filterElement));
  }

  _filter(targets, filterElement, ev) {
    let txtValue = '';
    let filter = $(ev.currentTarget).val().toUpperCase();

    console.log(filterElement);

    targets.each(function(index, row) {
      let el = $(row).find(filterElement);
      if(el.length) {
        txtValue = el.text();
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          $(row).css('display', '');
        }else {
          $(row).css('display', 'none');
        }
      }
    });
  }
}

export default ListFilter
