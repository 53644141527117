import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flow-scroll"
export default class extends Controller {
  static targets = ["messages"]
  static values = { clientId: Number, userId: Number, courseConversationId: Number, flowConversationId: Number, courseId: Number, lastMessageUpdateUrl: String, lastFlowUpdateUrl: String }

  connect() {
    this.scrollToBottom();
    document.addEventListener("turbo:before-stream-render", this.setupTurboStreamListener.bind(this));
  }

  disconnect() {
    // Clean up listener to avoid duplicates
    document.removeEventListener("turbo:before-stream-render", this.setupTurboStreamListener.bind(this));
  }

  // Called when new Turbo Stream content is inserted
  scroll() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    const messagesElement = this.messagesTarget;
    if (messagesElement) {
      messagesElement.scrollTop = messagesElement.scrollHeight;
    }
  }

  setupTurboStreamListener(event) {
    // Listen for Turbo Stream changes and scroll when new messages are added
    if (event.target.action === 'append') {
      setTimeout(() => {
        this.scroll();
        const targetId = event.target.getAttribute("target");
        if (targetId === `conversation_${this.courseConversationIdValue}_messages_client_${this.clientIdValue}_course_${this.courseIdValue}`) {
          this.updateClientMessageLastRead()
        } else if (targetId === `conversation_${this.courseConversationIdValue}_messages_user_${this.userIdValue}_course_${this.courseIdValue}`) {
          this.updateUserMessageLastRead()
        } else if (targetId === `flows_client_${this.clientIdValue}_course_${this.courseIdValue}`) {
          this.updateClientflowLastRead()
        } else if (targetId === `flows_user_${this.userIdValue}_course_${this.courseIdValue}`) {
          this.updateUserflowLastRead()
        }
      }, 500);
    }
  }

  updateClientMessageLastRead(event) {
    if (this.courseConversationIdValue) {
      const formData = new FormData();
      formData.append('client_id', this.clientIdValue);
      formData.append('conversation_id', this.courseConversationIdValue);
      formData.append('course_id', this.courseIdValue);
      fetch(this.lastMessageUpdateUrlValue, {
        method: 'POST',
        headers: { 'X-CSRF-Token': document.querySelector("[name='csrf-token']").content },
        body: formData
      })
    }
  }

  updateUserMessageLastRead(event) {
    if (this.courseConversationIdValue) {
      const formData = new FormData();
      formData.append('user_id', this.userIdValue);
      formData.append('conversation_id', this.courseConversationIdValue);
      formData.append('course_id', this.courseIdValue);
      fetch(this.lastMessageUpdateUrlValue, {
        method: 'POST',
        headers: { 'X-CSRF-Token': document.querySelector("[name='csrf-token']").content },
        body: formData
      })
    }
  }

  updateClientflowLastRead(event) {
    if (this.flowConversationIdValue) {
      const formData = new FormData();
      formData.append('client_id', this.clientIdValue);
      formData.append('flow_Conversation_id', this.flowConversationIdValue);
      fetch(this.lastFlowUpdateUrlValue, {
        method: 'POST',
        headers: { 'X-CSRF-Token': document.querySelector("[name='csrf-token']").content },
        body: formData
      })
    }
  }

  updateUserflowLastRead() {
    if (this.flowConversationIdValue) {
      const formData = new FormData();
      formData.append('user_id', this.userIdValue);
      formData.append('flow_Conversation_id', this.flowConversationIdValue);
      fetch(this.lastFlowUpdateUrlValue, {
        method: 'POST',
        headers: { 'X-CSRF-Token': document.querySelector("[name='csrf-token']").content },
        body: formData
      })
    }
  }
}
