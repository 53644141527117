import Rails from '@rails/ujs';

document.addEventListener('turbo:load', function () {

  var isAdvancedUpload = function() {
    var div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  }();

  var uploadElements = document.querySelectorAll('.js-customUploadHolder');
  Array.prototype.forEach.call( uploadElements, function( uploadElement ) {
    var input = uploadElement.querySelector( 'input[type="file"]' );
    var form = uploadElement.closest('form');
    var droppedFiles = false;
    
    // letting the server side to know we are going to make an Ajax request
    var ajaxFlag = document.createElement( 'input' );
    ajaxFlag.setAttribute( 'type', 'hidden' );
    ajaxFlag.setAttribute( 'name', 'ajax' );
    ajaxFlag.setAttribute( 'value', 1 );
    form.appendChild( ajaxFlag );

    // automatically submit the form on file select
    // if the form is autosaved, no need for this additionnal submit
    if( !form.classList.contains( 'autosave' ) ) {
      input.addEventListener( 'change', function( e ) {
        uploadElement.classList.add( 'is-changed' );
        Rails.fire(form, 'submit');
      });
    }

    [ 'drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop' ].forEach( function( event ) {
      form.addEventListener( event, function( e ) {
        // preventing the unwanted behaviours
        e.preventDefault();
        e.stopPropagation();
      });
    });

    [ 'dragover', 'dragenter' ].forEach( function( event ) {
      uploadElement.addEventListener( event, function() {
        uploadElement.classList.add( 'is-dragover' );
      });
    });
    [ 'dragleave', 'dragend', 'drop' ].forEach( function( event ) {
      uploadElement.addEventListener( event, function() {
        uploadElement.classList.remove( 'is-dragover' );
      });
    });
    uploadElement.addEventListener( 'drop', function( e ) {
      input.files = e.dataTransfer.files; // assign files that were dropped to the input field
      uploadElement.classList.add( 'is-changed' );
      Rails.fire(form, 'submit');
    });
  });

});