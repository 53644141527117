class WarningModal {
  start() {
    document.addEventListener("turbo:load", function() {
      this.allModals = document.getElementsByClassName('modal')

      var allWarning_modals = document.getElementsByClassName('js-show-warning-modal')
      for (var i = 0, len = allWarning_modals.length; i < len; i++) {
        this.addShowWarningEventListener(allWarning_modals[i])
      }

      var classesToCloseModal = ['modal-background', 'modal-close']
      for (var i = 0, len = classesToCloseModal.length; i < len; i++) {
        var closeElements = document.getElementsByClassName(classesToCloseModal[i])
        for (var j = 0, len = closeElements.length; j < len; j++) {
          this.addCloseModalEventListener(closeElements[j])
        }
      }
    }.bind(this))
  }

  addShowWarningEventListener(link) {
    link.addEventListener('click', function(event){
      event.preventDefault()

      var modalElement = document.getElementsByClassName('js-show-all-warnings-modal')[0]
      if (modalElement) {
        modalElement.classList.add('is-active')
      }
    })
  }

  addCloseModalEventListener(closeElement){
    closeElement.addEventListener('click', function(event){
      event.preventDefault()
      for (var i = 0, len = this.allModals.length; i < len; i++) {
        this.allModals[i].classList.remove('is-active')
      }
    }.bind(this))
  }
}

export default WarningModal
