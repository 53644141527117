import { I18n } from "i18n-js";
import fr from "../../translations/fr.json";
import en from "../../translations/en.json";

// function switchBtnHasChanged(e) {
  // if(e.target.classList.contains("js-switch-button")){
  //   var switchBtn = e.target;
  //   showHideGroups(switchBtn.name, switchBtn.id);
  // }
// }

function deleteGuest(event) {
  if(event.target.classList.contains("delete-trigger") || event.target.parentNode.classList.contains("delete-trigger")){
    event.preventDefault();
    let parent = event.target.closest(".m_meeting-user");
    parent.remove();
    changeMeetingTypeAndCustomerList();
    warnForMissingFields();

    const download_link = document.querySelector('.m_fullmodal-clientSection .download-link');
    if (download_link) {
      download_link.classList.add('changed');
    }
  }
}

function useInternalVideoMeetingUrl() {
  // @todo compléter petit comportement javascript
  // alert('internals');
}

function useExternalVideoMeetingUrl() {
  // alert('external');
}

function showHideMeetingTypeOther(event) {
  // Attention, ce bout de code est dupliqué dans initialize-calendar.js dans le callback lorsqu'on ouvre la popup
  // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
  let meeting_type_select = document.getElementById('scheduled_session_meeting_type_id');
  let meeting_type_input = document.getElementById('scheduled_session_meeting_type_other');
  if (meeting_type_select && meeting_type_input) {
    if (meeting_type_select.value) {
      meeting_type_input.classList.add('hidden');
    } else {
      meeting_type_input.classList.remove('hidden');
    }
  }
}

function showHideGuestsType(event) {
  // Attention, ce bout de code est dupliqué dans initialize-calendar.js dans le callback lorsqu'on ouvre la popup
  // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
  let meeting_type_select = document.getElementById('scheduled_session_meeting_type_id');
  let customer_or_prospect_select = document.getElementById('scheduled_session_customer_or_prospect');
  const meeting_type_coaching_id_holder = document.getElementById('meeting_type_id_holder');
  if (meeting_type_select && customer_or_prospect_select && meeting_type_coaching_id_holder) {
    if (meeting_type_select.value == meeting_type_coaching_id_holder.value) {
      customer_or_prospect_select.value = 'client';
      customer_or_prospect_select.disabled = true;
    } else {
      customer_or_prospect_select.disabled = false;
    }
  }
}

var changeMeetingTypeAndCustomerList = function() {
  // Attention, ce bout de code est dupliqué dans initialize-calendar.js dans le callback lorsqu'on ouvre la popup
  // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
  let customer_or_prospect = document.getElementById('scheduled_session_customer_or_prospect');
  let meeting_type_select = document.getElementById('scheduled_session_meeting_type_id');
  if (customer_or_prospect && meeting_type_select) {
    // retrieve the currently selected guests IDs
    let client_ids = []
    let client_ids_inputs = document.getElementsByName("scheduled_session[client_ids][]");
    if (client_ids_inputs) {
      for(let i = 0;i < client_ids_inputs.length; i++) {
        if (!client_ids_inputs[i].disabled && client_ids_inputs[i].value) {
          client_ids.push(client_ids_inputs[i].value);
        }
      }
    }
    let user_ids = []
    let user_ids_inputs = document.getElementsByName("scheduled_session[user_ids][]");
    if (user_ids_inputs) {
      for(let i = 0;i < user_ids_inputs.length; i++) {
        if (!user_ids_inputs[i].disabled && user_ids_inputs[i].value) {
          user_ids.push(user_ids_inputs[i].value);
        }
      }
    }
    $.ajax({
      url: "/"+$('html').attr('lang')+"/users/calendar/get_client_and_meeting_types?customer_or_prospect="+customer_or_prospect.value+"&client_ids="+client_ids+"&user_ids="+user_ids+"&meeting_type="+meeting_type_select.value,
      type: 'GET',
      dataType: "script",
      processData: false,
      contentType: false,
    });
  }
}

var addGuest = function(event) {
  console.log('addguest');
  // Attention, ce bout de code est dupliqué dans initialize-calendar.js dans le callback lorsqu'on ouvre la popup
  // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
  event.preventDefault();
  const btn_add_guest = document.getElementById('scheduled_session_btn_add_guest');
  const select_guest_id = document.getElementById('scheduled_session_guest_id');
  const guests_wrapper = document.getElementById('guests-wrapper');
  const guests_template = document.querySelector('#guests-wrapper .m_meeting-user.template');
  const download_link = document.querySelector('.m_fullmodal-clientSection .download-link');
  if (btn_add_guest && select_guest_id) {
    const guest_id = select_guest_id.value;
    if (guest_id) {
      const selected_guest_type = select_guest_id.options[select_guest_id.selectedIndex].dataset.guestType;
      // check if I already have this guest in the list
      const guest_node_id = 'guest-'+selected_guest_type+'-'+guest_id;
      console.log(guest_node_id, 'guest_node_id');
      if (!document.getElementById(guest_node_id)) {
        let guest = guests_template.cloneNode(true);
        let guest_name = guest.querySelector('span.name');
        let guest_email = guest.querySelector('span.email');
        let guest_avatar = guest.querySelector('span.avatar');
        let guest_input = guest.querySelector('input');
        const selected_name = select_guest_id.options[select_guest_id.selectedIndex].text;
        const selected_email = select_guest_id.options[select_guest_id.selectedIndex].dataset.email;
        let avatarURI;
        if (selected_guest_type == 'user') {
          avatarURI = '/users/firm/employees/'+guest_id+"/avatar";
          guest_input.name = 'scheduled_session[user_ids][]';
        } else {
          avatarURI = '/users/clients/'+guest_id+'/avatar';
          guest_input.name = 'scheduled_session[client_ids][]';
        }
        guest_name.innerHTML = selected_name;
        guest_email.innerHTML = selected_email;
        guest_input.value = guest_id;
        guest_input.disabled = false;
        guest.classList.remove("hidden", "template");
        guest.id = guest_node_id;
        guests_wrapper.appendChild(guest);
        
        $.get( "/"+$('html').attr('lang')+avatarURI, function( data ) {
          guest_avatar.innerHTML = data;
          // $('.initialjs-avatar').initial();
        });
        changeMeetingTypeAndCustomerList();

        if (download_link) {
          download_link.classList.add('changed');
        }
      } else {
        // guest already added, ignore.
      }
      warnForMissingFields();
    }
  }
}

var warnForMissingFields = function() {
  const i18n = new I18n({fr, en});
  i18n.locale = $('body').data('locale');

  const guests_wrapper = document.getElementById('guests-wrapper');
  let warning_line = document.getElementById('warning-line');
  if (guests_wrapper && warning_line) {
    // check if I have at least one guest to the meeting
    let message = null;
    const guests_count = guests_wrapper.querySelectorAll('.m_meeting-user:not(.template)').length;
    if (guests_count > 0) {
      // Guest(s) present, check for location
      let location_present = false;
      const meeting_type = document.querySelector('input[name="meeting_type"]:checked').value
      if (meeting_type == 'phone') {
        // check if a phone is selected
        location_present = document.querySelector('input[name="scheduled_session[phone_number]"]:checked') !== null
      } else if (meeting_type == 'virtual') {
        // Virtual mode is always ok, if empty we generate teh url ourselves
        location_present = true;
      } else {
        // Physical, check if a location is checked
        location_present = document.querySelector('input[name="scheduled_session[location_id]"]:checked') !== null 
      }
      if (!location_present) {
        // Show warning about missing location
        message = i18n.t("warning_no_location");
      }
    } else {
      // Show warning for 0 guests
      message = i18n.t("warning_no_guest");
    }
    if (message == null) {
      // hide warning_line
      warning_line.classList.add("hidden");
    } else {
      // show warning_line
      warning_line.querySelector('.warning-line-text').innerHTML = message;
      warning_line.classList.remove('hidden');
    }
  }
}

document.addEventListener('turbo:load', function () {
  // Delegate event listener because event might happen in modal
  // document.addEventListener( "click", switchBtnHasChanged );

  // document.addEventListener("modalContentChanged", function (e) {
  //   setInitialState();
  // });

  let guests_wrapper = document.getElementById('guests-wrapper');
  if (guests_wrapper) {
    guests_wrapper.addEventListener( "click", deleteGuest );
  }

  let meeting_type_select = document.getElementById('scheduled_session_meeting_type_id');
  if (meeting_type_select) {
    meeting_type_select.addEventListener('change', showHideMeetingTypeOther.bind(meeting_type_select));
    showHideMeetingTypeOther();
    meeting_type_select.addEventListener('change', showHideGuestsType.bind(meeting_type_select));
    showHideGuestsType();
  }

  let video_meeting_internal_link = document.getElementById('video_meeting_internal_link');
  let video_meeting_external_url = document.getElementById('scheduled_session_video_meeting_external_url');
  if (video_meeting_internal_link && video_meeting_external_url) {
    video_meeting_internal_link.addEventListener('click', useInternalVideoMeetingUrl());
    video_meeting_external_url.addEventListener('keyup', useExternalVideoMeetingUrl());
  }
  window.setInitialPhoneNumberSelectState();
  
  let customer_or_prospect = document.getElementById('scheduled_session_customer_or_prospect');
  if (customer_or_prospect) {
    customer_or_prospect.addEventListener('change', changeMeetingTypeAndCustomerList.bind(customer_or_prospect));
    changeMeetingTypeAndCustomerList();
  }
  
  let btn_add_guest = document.getElementById('scheduled_session_btn_add_guest');
  if (btn_add_guest) {
    btn_add_guest.addEventListener('click', addGuest.bind(btn_add_guest));
  }

  let meeting_location_type_tabs = document.querySelector('.m_fullmodal-meetingSection .u_switchBtn');
  if (meeting_location_type_tabs) {
    meeting_location_type_tabs.addEventListener('click', () => warnForMissingFields());
  }
  document.querySelectorAll('.m_fullmodal-meetingSection input[type=radio]').forEach(radio => 
    radio.addEventListener('click', () => warnForMissingFields())
  );

  warnForMissingFields();
});

window.setInitialPhoneNumberSelectState = function() {
  const phone_numbers_radios = document.getElementsByName("scheduled_session[phone_number]");
  phone_numbers_radios.forEach(item => {
    item.addEventListener('click', event => {
      //handle click
      PhoneNumberSelectStateUpdate();
    })
  })
  PhoneNumberSelectStateUpdate();
}

function PhoneNumberSelectStateUpdate() {
  if(document.getElementsByName("scheduled_session[phone_number]").length && document.querySelector('#other_number:checked') !== null) {
    document.getElementsByName("scheduled_session[other_phone_number]")[0].parentElement.classList.remove("hidden")
  } else if (document.getElementsByName("scheduled_session[other_phone_number]").length) {
    document.getElementsByName("scheduled_session[other_phone_number]")[0].parentElement.classList.add("hidden")
  }
}

window.displayClientPhoneNumberOnChange = function () {
  let client_select = document.getElementById('scheduled_session_guest_id');
  if (client_select) {
    client_select.addEventListener('change', event => {
      //handle change
      displayClientPhoneNumber();
    });
    displayClientPhoneNumber();
  }
}

function displayClientPhoneNumber() {
  let client_select = document.getElementById('scheduled_session_guest_id');
  let client_text = document.getElementById('scheduled_session_client_number_text').value;
  document.getElementById('scheduled_session_client_number').innerHTML = client_text;

  if (client_select && client_select.value) {
    let phone = client_select.options[client_select.selectedIndex].getAttribute('data-phone');
    if (phone) {
      res = client_text.concat(' ', phone);
      document.getElementById('scheduled_session_client_number').innerHTML = res;
    }
  }
}
