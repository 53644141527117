import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash-removal"
export default class extends Controller {
  static targets = [ "flashMessage" ]
  
  remove(event) {
    // Get the parent flash message
    const flashMessage = event.target.closest('.notification');
    if (flashMessage) {
      flashMessage.remove();
    }
  }
}
