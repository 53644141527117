import { I18n } from "i18n-js";
import fr from "../translations/fr.json";
import en from "../translations/en.json";

document.addEventListener("turbo:load", function() {
  const i18n = new I18n({
    fr,
    en,
  });

  var goalForms = document.getElementsByClassName('goal-update-form');
  for (var i = 0, len = goalForms.length; i < len; i++) {
    goalForms[i].addEventListener('ajax:success', function(event, a, b, c, d){
      i18n.locale = $('body').data('locale');
      // hide error message
      var errorBox = event.target.parentElement.getElementsByClassName('goal-form-error')[0]
      errorBox.style.display = 'none'

      // show success message
      var successBox = event.target.parentElement.getElementsByClassName('goal-form-success')[0]
      successBox.innerHTML = i18n.t("update_completed")
      successBox.style.display = 'block'

      // hide the form
      event.target.style.display = 'none'

      // dispatch event so we can re-render the chart
      var goalCheckpointAddedEvent = new CustomEvent('goalCheckpointAdded', {detail: event.detail[0]});
      event.target.dispatchEvent(goalCheckpointAddedEvent);
    })

    goalForms[i].addEventListener('ajax:error', function(event){
      i18n.locale = $('body').data('locale');
      // hide success message
      var successBox = event.target.parentElement.getElementsByClassName('goal-form-success')[0]
      successBox.style.display = 'none'

      // show error message
      var errorBox = event.target.parentElement.getElementsByClassName('goal-form-error')[0]
      errorBox.innerHTML = i18n.t("select_motivation_and_prioritization")
      errorBox.style.display = 'block'
    })
  }
});
