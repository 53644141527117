document.addEventListener("turbo:load", function() {
  if ($('#plan_private').length) {
    $('#plan_private').on('change', function() {
      togglePrivateLink();
    });
    togglePrivateLink();
  }

  function togglePrivateLink(){
    if ($('#plan_private').is(':checked')){
      $('.private-link').removeClass("is-hidden");
    } else {
      $('.private-link').addClass("is-hidden");
    }
  }
});
