import bulmaCalendar from './vendors/bulma-calendar';

["turbo:load", "turbo:render"].forEach((event) => {
  document.addEventListener(event, function() {
    // hide the bulma default calendar picker, we will remove it once final confirmation.
    // initializeBulmaCalendarPicker();
  });
});

window.initializeBulmaCalendarPicker = function() {
  var datePickers = bulmaCalendar.attach('[type="date"]', {
    displayMode: 'dialog',
    dateFormat: 'dd/MM/yyyy',
    lang: i18n.locale,
    showFooter: false
  });
}
