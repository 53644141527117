document.addEventListener('turbo:load', function () {
  var modals = Array.prototype.slice.call(document.querySelectorAll(".js-fullmodal"), 0);

  modals.forEach(function (modal) {
    var config = { childList: true, subtree: true };

    var callback = function(mutationsList, observer) {
      for(var mutation of mutationsList) {

        if (mutation.type == 'childList') {
          var event = new Event('modalContentChanged');
          document.dispatchEvent(event);
        }
      }
    };

    var observer = new MutationObserver(callback);

    observer.observe(modal, config);

  });

});