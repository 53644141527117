class NestedFormsTask {
  start() {
    document.addEventListener("turbo:load", function() {
      if (document.getElementById('task-item-list')) {
        this._createDOMEventListeners()
      }
    }.bind(this))
  }

  _addTask () {
    let nextID;
    // todo get nextID via ajax call by creating new empty task
    // todo clean above everywhere nextID is used
    let xhr = new XMLHttpRequest();
    xhr.open('GET', `new-task-id`);
    xhr.nestedFormsTaskInstance = this;
    xhr.onload = function () {
      if (xhr.status === 200) {
        let data = JSON.parse(xhr.responseText);
        var templateHTML = xhr.nestedFormsTaskInstance._template(data.id);
        document.getElementById('task-item-list').insertAdjacentHTML('beforeend', templateHTML)
      }
    };
    xhr.send();    
  }

  // This is to create the events listening to some DOM events
  _createDOMEventListeners () {
    this._createAddEventListener()
  }

  _template (id) {
    var templateHTML = document.getElementById('task-item-template').innerHTML
    templateHTML = templateHTML.replace(/\$\{id\}/g, id)
    return templateHTML
  }

  _createAddEventListener () {
    var addTaskButton = document.getElementById('js-nested-form-task-add')
    if (addTaskButton) {
      addTaskButton.addEventListener('click', function(event) {
        event.preventDefault()
        this._addTask()
      }.bind(this));
    }
  }
}
export default NestedFormsTask
