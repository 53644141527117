function showHideToolContentType(event) {
  let checked_status = false
  let tool_content_type_radios = document.getElementsByName('tool[content_type]');
  let tool_content_type_fields = document.getElementsByClassName('tool-content-type-field');
  if (tool_content_type_radios) {
    for (var i = 0, len = tool_content_type_fields.length; i < len; i++) {
      tool_content_type_fields[i].classList.add('hidden');
    }
    for (var i = 0, len = tool_content_type_radios.length; i < len; i++) {
      if (tool_content_type_radios[i].checked) {
        content_type = tool_content_type_radios[i].value
        document.getElementById('tool-content-type-field-'+content_type).classList.remove('hidden');
      }
    }
  }
  document.getElementById('tool_questionnaire').value = content_type == 'questionnaire'
  document.getElementById('tool_assessment').value = content_type == 'assessment'
}

document.addEventListener('turbo:load', function () {
  let tool_content_type_radios = document.getElementsByName('tool[content_type]');
  if (tool_content_type_radios) {
    for (var i = 0, len = tool_content_type_radios.length; i < len; i++) {
      tool_content_type_radios[i].addEventListener('change', showHideToolContentType.bind(tool_content_type_radios[i]));
    }
  }
});
