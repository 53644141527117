function showHideAssignmentTypeOther(event) {
  // Attention, ce bout de code est dupliqué dans initialize-calendar.js dans le callback lorsqu'on ouvre la popup
  // C'est un hack temporaire car je n'ai pas vu comment rendre ce morceau disponible aux 2 endroits pour le moment...
  let assignment_type_select = document.getElementById('assignment_assignment_type_id');
  let assignment_type_input = document.getElementById('assignment_assignment_type_other');
  if (assignment_type_select && assignment_type_input) {
    if (assignment_type_select.value) {
      assignment_type_input.classList.add('hidden');
    } else {
      assignment_type_input.classList.remove('hidden');
    }
  }
}

function initAssignmentForm() {
  let assignment_type_select = document.getElementById('assignment_assignment_type_id');
  if (assignment_type_select) {
    assignment_type_select.addEventListener('change', showHideAssignmentTypeOther.bind(assignment_type_select));
    showHideAssignmentTypeOther();
  }
}

document.addEventListener('turbo:load', initAssignmentForm);
document.addEventListener('ajax:success', initAssignmentForm);
document.addEventListener('ajax:complete', initAssignmentForm);
