import ajaxCall from '../utilities/ajax.js';
import {changeQuestionOptions, addRemoveHiddenClassOnCondition, addRemoveHiddenClassOnAssessmentOptions} from "../assessment_questions/form";



export function checkOtherSiblingsHaveChildQuestion(element, i) {
  const assessment_parent_question = document.querySelectorAll('.assessment_parent_question');

  const parentQuestionID = assessment_parent_question[0].value;
  const optionID = element.value;
  let siblingsUrl = element.dataset.siblingsUrl;
  siblingsUrl = siblingsUrl + '?question_id=' + parentQuestionID + '&option_id=' + optionID;
  ajaxCall(siblingsUrl, 'GET', (json_data) => {
      if (json_data.already_have_siblings == true) {
        alert("Other siblings have the child questions, please be careful this may affect the flow.");
      }
    }, (xhr) => {
      //console.log('Unable to create new line item: ' + xhr.status);
    });
  
}

class NestedFormsDisplayRule {
  start() {
    document.addEventListener("turbo:load", function () {
      if (document.getElementById('display_rules_list')) {
        let addBtn = document.getElementById('js-nested-form-display-rule-add');
        if (addBtn === null) {
          return;
        }

        this._createDOMEventListeners();
        this.nextID = parseInt(addBtn.dataset.displayRuleCount, 10);
        this.newItemUrl = addBtn.dataset.newUrl;
        this.destroyItemUrl = addBtn.dataset.destroyUrl;

        const assessment_question_option = document.querySelectorAll('.line_item_assessment_option_id');
        assessment_question_option.forEach((element, index) => {
          element.addEventListener('change', checkOtherSiblingsHaveChildQuestion.bind(this, element, index));
        });
      }
    }.bind(this))
  }

  _addLineItem(html) {
    this.nextID = this.nextID + 1
    let nextIndex = document.getElementById('display_rules_list').children.length;
    document.getElementById('display_rules_list').insertAdjacentHTML('beforeend', html)
    let allRemoveButtonElements = document.getElementsByClassName('js-nested-form-display-rule-remove')
    this._createRemoveEventListener(allRemoveButtonElements[allRemoveButtonElements.length - 1]);
    this._createUpdateEventListener(allRemoveButtonElements[allRemoveButtonElements.length - 1].parentElement.parentElement, nextIndex);
    addRemoveHiddenClassOnCondition();
  }

  _destroyLineItem(elementToRemove) {
    if (typeof elementToRemove.dataset.destroyItemUrl !== "undefined" &&
      elementToRemove.dataset.destroyItemUrl !== "") {
      ajaxCall(elementToRemove.dataset.destroyItemUrl, 'DELETE', () => {
      }, (xhr) => {
        alert('Unable to delete line item');
      });
    }
    elementToRemove.remove()
  };

  // This is to create the events listening to some DOM events
  _createDOMEventListeners() {
    this._createAddEventListener()

    var removeLineItemButtons = document.getElementsByClassName('js-nested-form-display-rule-remove')
    Array.from(removeLineItemButtons).forEach(function (element) {
      this._createRemoveEventListener(element)
    }.bind(this));
  }

  _loadNewLineItem() {
    ajaxCall(this.newItemUrl, 'GET', (json_data) => {
      this._addLineItem(json_data.content);
    }, (xhr) => {
      //console.log('Unable to create new line item: ' + xhr.status);
    });
  }

  _createUpdateEventListener(element, index) {
    element.querySelectorAll('select').forEach(child => {
      if (child.classList.contains('assessment_parent_question')) {
        child.addEventListener('change', changeQuestionOptions.bind(this, child, index))
      }
      if (child.classList.contains('assessment_parent_question_on_scale')) {
        child.addEventListener('change', addRemoveHiddenClassOnAssessmentOptions.bind(this, child, index))
      }
    });
  }

  _createAddEventListener() {
    var addDisplayRuleButton = document.getElementById('js-nested-form-display-rule-add')
    if (addDisplayRuleButton) {
      addDisplayRuleButton.addEventListener('click', function (event) {
        event.preventDefault()
        this._loadNewLineItem()
      }.bind(this));
    }
  }

  _createRemoveEventListener(element) {
    element.addEventListener('click', function (event) {
      event.preventDefault()
      var elementToRemove = event.target.parentElement.parentElement;

      if (elementToRemove.className.indexOf('display_rule') != -1) {
        this._destroyLineItem(elementToRemove);
        // updateSubtotal();
      } else {
        throw new Error('Noooooo.... :(');
      }
    }.bind(this));
  }
}

export default NestedFormsDisplayRule
