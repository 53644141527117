document.addEventListener("turbo:load", function() {
  // scroll to latest message
  if ($('body').hasClass('users-conversations') || $('body').hasClass('clients-conversations') || $('body').hasClass('admins-conversations')) {
    // when loading a conversation, scroll to the last message on page load
    var $bubbles = $('.column-messages .bubbles');
    if ($bubbles.length) {
      $bubbles.scrollTop($bubbles[0].scrollHeight - $bubbles[0].clientHeight);
      if ($('body').hasClass('action-show')) {
        // check in 10 seconds for new messages
        setTimeout(function(){check_new_messages();}, 2000);
      }
    }
  }

  // scroll to latest message
  if (($('body').hasClass('clients-courses') && $('body').hasClass('action-show')) || ($('body').hasClass('users-conversations') && $('body').hasClass('action-index'))) {
    // when loading a conversation, scroll to the last message on page load
    var $messages = $('.messages-list .messages');
    if ($messages.length) {
      $messages.scrollTop($messages[0].scrollHeight - $messages[0].clientHeight);
    }
  }

  // Resize the message text field's height according to content.
  const parentElement = document.getElementById('messages-input-container');
  $('#autoResizeTextArea').on('input', function () {
    this.style.height = 'auto'; // Reset the height to allow shrinking
    this.style.height = (this.scrollHeight) + 'px'; // Set the height based on content

    // Get the computed line height of the textarea
    const lineHeight = parseInt(window.getComputedStyle(this).lineHeight);
    // Calculate the number of rows
    const rows = Math.floor(this.scrollHeight / lineHeight);

    // Change the border-radius of the parent element based on the number of rows
    if (rows >= 3) {
      parentElement.style.borderRadius = '8px'; // Reduce border-radius
    } else if (rows == 2) {
      parentElement.style.borderRadius = '16px'; // Reduce border-radius
    } else {
      parentElement.style.borderRadius = '35px'; // Reset to original border-radius
    }
  });

  // Resize the flux text field's height according to content.
  const parentFluxElement = document.getElementById('fluxes-input-container');
  $('#fluxAutoResizeTextArea').on('input', function () {
    this.style.height = 'auto'; // Reset the height to allow shrinking
    this.style.height = (this.scrollHeight) + 'px'; // Set the height based on content

    // Get the computed line height of the textarea
    const lineHeight = parseInt(window.getComputedStyle(this).lineHeight);
    // Calculate the number of rows
    const rows = Math.floor(this.scrollHeight / lineHeight);

    // Change the border-radius of the parent element based on the number of rows
    if (rows >= 3) {
      parentFluxElement.style.borderRadius = '8px'; // Reduce border-radius
    } else if (rows == 2) {
      parentFluxElement.style.borderRadius = '16px'; // Reduce border-radius
    } else {
      parentFluxElement.style.borderRadius = '35px'; // Reset to original border-radius
    }
  });
});


function check_new_messages(){
  var last_message_id = $('.column-messages .bubbles .speech-bubble:not(.template)').last().data('message');
  if (last_message_id != undefined) {
    $.ajax({
        type: "GET",
        url: window.location + "/new_messages?last_message_id="+last_message_id,
        async: false
    }).done(function(messages){
      if (messages.length > 0) {
        var $bubbles = $('.column-messages .bubbles');
        var $bubbleTemplate = $('.speech-bubble.them.template');
        // add message bubbles
        $.each(messages, function(i, message){
          $lastBubble = $bubbles.find('.speech-bubble:not(.template)').last();
          if ($lastBubble.data('interlocutor') != message.interlocutor.id) {
            // show interlocutor face if this is the last message was from another interlocutor
            var $interlocutorTemplate = $('#interlocutor-template-'+message.interlocutor.id);
            $interlocutor = $interlocutorTemplate.clone();
            $interlocutor.removeAttr("id");
            $interlocutor.removeClass('template');
            $interlocutor.removeClass('hidden');
            $bubbles.append($interlocutor);
          }
          $bubble = $bubbleTemplate.clone();
          $bubble.removeClass('template');
          $bubble.removeClass('hidden');
          $bubble.find('.message-content').html(message.content);
          $bubble.find('.message-time').html(message.time);
          $bubble.data('message', message.id);
          $bubble.data('interlocutor', message.interlocutor.id);
          $bubbles.append($bubble);
          // scroll down to new bubbles
          $bubbles.scrollTop($bubbles[0].scrollHeight - $bubbles[0].clientHeight);
        });
      }
      // Check again in 15 seconds
      setTimeout(function(){check_new_messages();}, 15000);
    });
  } else {
    // Check again in 15 seconds
    setTimeout(function(){check_new_messages();}, 15000);
  }
}