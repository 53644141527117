class TemplateFilters {
  start() {
    document.addEventListener("turbo:load", function() {
      this.currentURL = new URL(window.location)
      // not sure why but sometimes searchParams returns an "undefined" value...
      if (this.currentURL.searchParams) {
        this.categoryID = this.currentURL.searchParams.get('category_id') || "" // defaults to an empty string so we don't have things like "category_id=null" in the URL
      } else {
        this.categoryID = ""
      }
      if(document.getElementById('templates-filters'))
      this._createDOMEventListeners()
    }.bind(this));
  }

  _createDOMEventListeners() {
    var htmlCategoryDropdown = document.getElementById('templates_advance_html_category_id')
    if(htmlCategoryDropdown) {
      htmlCategoryDropdown.addEventListener('change', function(event){
        this.categoryID = event.target.value
        this.applyFilters()
      }.bind(this))
    }
  }

  applyFilters() {
    window.location.search = '?category_id=' + this.categoryID
  }
}

export default TemplateFilters
