import { Controller } from "@hotwired/stimulus";
import NoSleep from 'nosleep.js';
import { I18n } from "i18n-js";
import fr from "../src/translations/fr.json";
import en from "../src/translations/en.json";

// Connects to data-controller="audio-recording"
export default class extends Controller {
  static targets = ['startRecording', 'stopRecording', 'closeAndStopRecording', 'resumeRecording', 'pauseRecording', "recordedAudio", 'timeElapsed', 'saveAudioUrl', 'audioFileAttached', 'waveform', 'sessionStartBtn'];

  connect() {
    this.timerInterval = null;
    this.secondsElapsed = 0;
    this.i18n = new I18n({
      fr,
      en,
    });
    this.i18n.locale = $('body').data('locale');
  }

  startRecording(event) {
    event.preventDefault()
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      if(this.audioFileAttachedTarget.value === '1') {
        var confirm_message = this.i18n.t("confirm_audio_start_again");
      }
      else {
        var confirm_message = this.i18n.t("confirm_audio_start");
      }
      if(this.sessionStartBtnTarget.value == 'true') {
        alert(this.i18n.t('start_meeting_first'));
      } else if(confirm(confirm_message) === true) {
        // const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        const audioCtx = new AudioContext();
        const analyser = audioCtx.createAnalyser();

        var noSleep = new NoSleep();
        var wakeLockEnabled = false;
        // enable screen wake lock
        if (!wakeLockEnabled) {
          noSleep.enable(); // keep the screen on!
          wakeLockEnabled = true;
          console.log('Screen lock enable')
        }

        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          this.recorder = new MediaRecorder(stream);
          const chunks = [];

          const source = audioCtx.createMediaStreamSource(stream);
          source.connect(analyser);

          this.recorder.addEventListener('dataavailable', (event) => {
            chunks.push(event.data);
          });

          // Add an event listener for the 'pause' event
          this.recorder.addEventListener('pause', function() {
            // disable screen wake lock
            if (wakeLockEnabled) {
              noSleep.disable(); // keep the screen on!
              wakeLockEnabled = false;
              console.log('Screen lock disable')
            }
            // Mute the audio waveform
            stream.getAudioTracks().forEach(function(track) {
              track.enabled = false;
            });
          });

          // Add an event listener for the 'resume' event
          this.recorder.addEventListener('resume', function() {
            // enable screen wake lock
            if (!wakeLockEnabled) {
              noSleep.enable(); // keep the screen on!
              wakeLockEnabled = true;
              console.log('Screen lock enable')
            }
            // Unmute the audio waveform
            stream.getAudioTracks().forEach(function(track) {
              track.enabled = true;
            });
          });

          this.recorder.addEventListener('stop', () => {
            // disable screen wake lock
            if (wakeLockEnabled) {
              noSleep.disable(); // keep the screen on!
              wakeLockEnabled = false;
              console.log('Screen lock disable')
            }
            // Create a Blob object from the recorded audio chunks.
            const audioBlob = new Blob(chunks, { type: 'audio/webm' });

            stream.getTracks() // get all tracks from the MediaStream
              .forEach( track => track.stop() ); // stop each of them

            const audioUrl = URL.createObjectURL(audioBlob);
            // set player src
            this.recordedAudioTarget.src = audioUrl;
            // Send recorded audio to Rails server
            this.saveAudio(audioBlob);
          });
          this.startRecorder(event);

          this.visualize(analyser);
        }).catch((error) => {
          console.log("The following error occurred: " + error);
          alert(this.i18n.t("grant_mic_permission"));
        });
      }
    } else {
      alert(this.i18n.t("browser_not_support"));
    }
  }

  pauseRecording (event) {
    event.preventDefault();
    this.recorder.pause()
    this.pauseRecordingTarget.disabled = true;
    this.pauseRecordingTarget.classList.add('is-hidden')
    this.resumeRecordingTarget.disabled = false;
    this.resumeRecordingTarget.classList.remove('is-hidden')
    this.stopTimer();
  }

  resumeRecording (event) {
    event.preventDefault();
    this.recorder.resume()
    this.pauseRecordingTarget.disabled = false;
    this.pauseRecordingTarget.classList.remove('is-hidden')
    this.resumeRecordingTarget.disabled = true;
    this.resumeRecordingTarget.classList.add('is-hidden')
    this.startTimer();
  }

  startRecorder (event) {
    var vm = this;
    event.preventDefault();
    this.recorder.start();
    this.startRecordingTarget.disabled = true
    this.startRecordingTarget.classList.add('is-hidden')
    this.stopRecordingTarget.disabled = false
    this.pauseRecordingTarget.disabled = false;
    this.pauseRecordingTarget.classList.remove('is-hidden')
    // show waveform and hide player
    this.waveformTarget.classList.remove('is-hidden')
    this.recordedAudioTarget.classList.add('is-hidden')
    // pause player when recording start
    this.recordedAudioTarget.pause();
    this.startTimer();
  }

  stopRecording(event) {
    event.preventDefault()
    this.recorder.pause();
    this.stopTimer();
    if(confirm(this.i18n.t("confirm_audio_stop")) === true) {
      this.recorder.stop();
      this.startRecordingTarget.disabled = false
      this.startRecordingTarget.classList.remove('is-hidden')
      this.stopRecordingTarget.disabled = true
      this.pauseRecordingTarget.disabled = true;
      this.pauseRecordingTarget.classList.add('is-hidden')
      this.resumeRecordingTarget.disabled = true;
      this.resumeRecordingTarget.classList.add('is-hidden')
      this.audioFileAttachedTarget.value = 1;
      // show player and hide waveform
      this.waveformTarget.classList.add('is-hidden')
      this.recordedAudioTarget.classList.remove('is-hidden')
      this.secondsElapsed = 0;
    } else {
      this.recorder.resume();
      this.startTimer();
    }
  }

  closeAndStopRecording(event) {
    event.preventDefault()
    if(this.recorder)
      this.recorder.stop();
    this.startRecordingTarget.disabled = false
    this.startRecordingTarget.classList.remove('is-hidden')
    this.stopRecordingTarget.disabled = true
    this.pauseRecordingTarget.disabled = true;
    this.pauseRecordingTarget.classList.add('is-hidden')
    this.resumeRecordingTarget.disabled = true;
    this.resumeRecordingTarget.classList.add('is-hidden')
    this.audioFileAttachedTarget.value = 1;
    this.stopTimer();
    this.secondsElapsed = 0;
    window.location.href = (this.closeAndStopRecordingTarget.href)
  }

  saveAudio(blob) {
    const formData = new FormData();
    formData.append('audio_file', blob);

    try {
      fetch(this.saveAudioUrlTarget.href, {
        method: 'POST',
        headers: { "Accept": "application/json" },
        body: formData,
      })
      .then((response) => response.json())
      .then((data) => {
        if(data.errors)
          alert(data.errors)
        if (navigator.userAgent.includes('Safari')) {
          // mediarecoder api return recorded audio in webm format and webm is not supported by safari
          // so for safari we set converted to mp3 audio.
          this.recordedAudioTarget.src = data.blob_url; // Provide the path to the MP3 file
        }
        console.log(data);
      });
    } catch (error) {
      alert('Error saving audio recording:', error);
    }
  }

  startTimer () {
    var vm = this;
    this.timerInterval = setInterval(() => {
      vm.secondsElapsed = vm.secondsElapsed + 1;
      vm.setTime();
    }, 1000);
  }

  stopTimer () {
    var vm = this;
    clearInterval(vm.timerInterval);
  }

  setTime () {
    this.timeElapsedTarget.innerHTML = `${this.i18n.t('time_elapsed')} ${this.getTimeString(this.secondsElapsed)} seconds  `;
  }

  getTimeString(seconds) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    // Add leading zeroes to hours, minutes, and seconds if needed
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    remainingSeconds = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

    return `${hours}:${minutes}:${remainingSeconds}`;
  }

  visualize(analyser) {
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const canvas = document.getElementById('waveform');
    const canvasCtx = canvas.getContext('2d');

    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

    function draw() {
      const drawVisual = requestAnimationFrame(draw);

      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = 'rgb(240, 245, 251)';
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

      canvasCtx.beginPath();

      const sliceWidth = canvas.width * 1.0 / bufferLength;
      let x = 0;

      for(let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = v * canvas.height / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    draw();
  }
}
