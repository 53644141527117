class ShowPassword {
  start() {
    document.addEventListener("turbo:load", function() {
      var toggleBtn = document.querySelector('.js_showPassword');
      if( toggleBtn ) {
        toggleBtn.addEventListener('click', function(e) {
          var input = e.currentTarget.parentElement.querySelector('input');
          if(e.currentTarget.classList.contains("-isVisible")) {
            e.currentTarget.classList.remove('-isVisible');
            this.changeInputType(input, 'password');
          } else {
            e.currentTarget.classList.add('-isVisible');
            this.changeInputType(input, 'text');
          }
        }.bind(this));
      }
    }.bind(this))
  }

  changeInputType(oldObject, oType) {
    var newObject = document.createElement('input');
    newObject.type = oType;
    if(oldObject.value) newObject.value = oldObject.value;
    if(oldObject.name) newObject.name = oldObject.name;
    if(oldObject.id) newObject.id = oldObject.id;
    if(oldObject.className) newObject.className = oldObject.className;
    oldObject.parentNode.replaceChild(newObject,oldObject);
    return newObject;
  }

}

export default ShowPassword
