document.addEventListener('turbo:load', function () {

  var profileSwitches = Array.prototype.slice.call(document.querySelectorAll(".js-profileVisibilitySwitch"), 0);

  if( profileSwitches.length > 0 ) {

    profileSwitches.forEach(function (profileSwitch) {
      var visibleBtn = profileSwitch.querySelector('.js-profileVisibilitySwitch-visible');
      var hiddenBtn = profileSwitch.querySelector('.js-profileVisibilitySwitch-hidden');

      var content = document.querySelector(".js-profileVisibilitySwitch-content");
      var contentIfHidden = document.querySelector(".js-profileVisibilitySwitch-contentIfHidden");

      hiddenBtn.addEventListener( "click", hideContent );
      visibleBtn.addEventListener( "click", showContent );

      function hideContent () {
        content.style.display = 'none';
        contentIfHidden.style.display = 'block';
      }

      function showContent () {
        content.style.display = 'block';
        contentIfHidden.style.display = 'none';
      }

    });
  }

});