document.addEventListener('turbo:load', function () {
  // Delegate event listener because btn might be in modal
  document.addEventListener( "change", switchBtnHasChanged );

  document.addEventListener("modalContentChanged", function (e) {
    setInitialState();
  });


  function switchBtnHasChanged(e) {
    if(e.target.classList.contains("js-switch-button")){
      var switchBtn = e.target;
      showHideGroups(switchBtn.name, switchBtn.id);
    }
  }

  function setInitialState () {
    var checkedButtons = Array.prototype.slice.call(document.querySelectorAll(".js-switch-button:checked"), 0);
    checkedButtons.forEach(function (checkedButton) {
      showHideGroups(checkedButton.name, checkedButton.id);
    });
  }

  function showHideGroups (group, name) {
    var groups = Array.prototype.slice.call(document.querySelectorAll("[data-switch-group='"+group+"']"), 0);
    groups.forEach(function (a_group) {
      a_group.style.display = 'none';
    });

    var visibleGroups = Array.prototype.slice.call(document.querySelectorAll("[data-switch-group='"+ group +"'][data-switch-name='"+ name +"']"), 0);
    visibleGroups.forEach(function (a_group) {
      a_group.style.display = '';
    });
  }

  setInitialState();
});
