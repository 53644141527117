document.addEventListener('turbo:load', function () {

  var typeSwitches = Array.prototype.slice.call(document.querySelectorAll(".js-newObjectifTypeSwitch"), 0);

  if( typeSwitches.length > 0 ) {

    typeSwitches.forEach(function (typeSwitch) {
      var genericBtn = typeSwitch.querySelector('.js-newObjectifTypeSwitch-generic');
      var specificBtn = typeSwitch.querySelector('.js-newObjectifTypeSwitch-specific');

      var content = document.querySelector(".js-newObjectifTypeSwitch-content");
      var requiredUnlessHiddenInputs = content.querySelectorAll(".js-required-unless-hidden");
      var isGeneric = document.getElementById("goal_goal_type_generic").checked;

      genericBtn.addEventListener( "click", hideContent );
      specificBtn.addEventListener( "click", showContent );

      if (isGeneric) {
        hideContent();
      }

      function hideContent () {
        content.style.display = 'none';
        if( requiredUnlessHiddenInputs.length > 0 ) {
          requiredUnlessHiddenInputs.forEach(b=>b.removeAttribute('required'));
        }
      }

      function showContent () {
        content.style.display = 'block';
        if( requiredUnlessHiddenInputs.length > 0 ) {
          requiredUnlessHiddenInputs.forEach(b=>b.setAttribute('required', 'required'));
        }
      }

    });
  }

});