document.addEventListener('turbo:load', function () {

  var reviewFlows = Array.prototype.slice.call(document.querySelectorAll(".js-reviewFlow"), 0);

  if( reviewFlows.length > 0 ) {
    reviewFlows.forEach(function (reviewFlow) {

      var review = {
        $steps : Array.prototype.slice.call(reviewFlow.querySelectorAll('.js-reviewSteps li'), 0),
        $pages : Array.prototype.slice.call(reviewFlow.querySelectorAll('.js-reviewPages'), 0),
        $progressBar : reviewFlow.querySelector('.js-reviewProgressBar'),
        $currentPage : reviewFlow.querySelector('.js-reviewCurrentPage'),
        $countPage : reviewFlow.querySelector('.js-reviewCountPage'),
        $prevBtn : reviewFlow.querySelector('.js-reviewPrevBtn'),
        $nextBtn : reviewFlow.querySelector('.js-reviewNextBtn')
      };

      var reviewPageLength = review.$steps.length;
      var reviewPageCurrent = 1 ;

      review.$prevBtn.addEventListener( "click", previousPage );
      review.$nextBtn.addEventListener( "click", nextPage );

      review.$steps.forEach(function (reviewStep) {
        reviewStep.addEventListener( "click", changePage );
      });

      updateProgress();

      function updateProgress () {
        review.$steps.forEach(function (reviewStep) {
          reviewStep.classList.remove('active');
        });
        review.$steps[reviewPageCurrent - 1].classList.add('active');

        review.$pages.forEach(function (reviewPage) {
          reviewPage.style.display = 'none';
        });
        review.$pages[reviewPageCurrent - 1].style.display = 'block';
    
        review.$progressBar.style.width =  (reviewPageCurrent / reviewPageLength) * 100 + "%";
        review.$currentPage.innerHTML = reviewPageCurrent;
        review.$countPage.innerHTML = reviewPageLength;
    
        if( reviewPageCurrent === 1)  {
          review.$prevBtn.style.display = 'none';
        } else {
          review.$prevBtn.style.display = 'block';
        }
    
        if( reviewPageCurrent === reviewPageLength)  {
          review.$nextBtn.style.display = 'none';
        } else {
          review.$nextBtn.style.display = 'block';
        }
      }
    
      function nextPage () {
        reviewPageCurrent ++;
        updateProgress();
      }

      function previousPage () {
        reviewPageCurrent --;
        updateProgress();
      }

      function changePage (e) {
        var newPageIndex = review.$steps.indexOf( e.target ) + 1;
        if(newPageIndex !== reviewPageCurrent) {
          reviewPageCurrent = newPageIndex;
          updateProgress();
        }
      }
    });
  }
});