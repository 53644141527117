class ContextMenu {
  start() {
    document.addEventListener("turbo:load", function() {

      var area = document.querySelectorAll('.js_contextMenuArea');
      var menuBtn = document.querySelectorAll('.js_contextMenuBtn');
      var menu = document.querySelectorAll('.js_contextMenu');

      for (var i = 0, len = menuBtn.length; i < len; i++) {
        menuBtn[i].addEventListener('click', function(e) {
          var menu = e.currentTarget.parentElement.querySelector('.js_contextMenu');
          if (window.getComputedStyle(menu).display === 'none'){
            return menu.style.display = 'block';
          } else {
            return menu.style.display = 'none';
          }
        });
      }

      for (var i = 0, len = menu.length; i < len; i++) {
        menu[i].addEventListener('mouseleave', function(e) {
          e.currentTarget.style.display = 'none';
        });
      }

      for (var i = 0, len = area.length; i < len; i++) {
        area[i].addEventListener('mouseleave', function(e) {
          var menu = e.currentTarget.parentElement.querySelector('.js_contextMenu');
          if(menu) {
            menu.style.display = 'none';
          }
        });
      }

    }.bind(this))
  }
}

export default ContextMenu
