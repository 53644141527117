class AllNotesModal {
  start() {
    document.addEventListener("turbo:load", function() {
      this.allModals = document.getElementsByClassName('modal')

      var allNotesLinks = document.getElementsByClassName('js-show-all-notes')
      for (var i = 0, len = allNotesLinks.length; i < len; i++) {
        this.addShowNoteEventListener(allNotesLinks[i])
      }

      var classesToCloseModal = ['modal-background', 'modal-close']
      for (var i = 0, len = classesToCloseModal.length; i < len; i++) {
        var closeElements = document.getElementsByClassName(classesToCloseModal[i])
        for (var j = 0, len = closeElements.length; j < len; j++) {
          this.addCloseModalEventListener(closeElements[j])
        }
      }
    }.bind(this))
  }

  addShowNoteEventListener(link) {
    link.addEventListener('click', function(event){
      event.preventDefault()
      var modalElement = event.currentTarget.parentElement.getElementsByClassName('js-show-all-notes-modal')[0]
      if (modalElement) {
        modalElement.classList.add('is-active')
      }
    })
  }

  addCloseModalEventListener(closeElement){
    closeElement.addEventListener('click', function(event){
      event.preventDefault()
      for (var i = 0, len = this.allModals.length; i < len; i++) {
        this.allModals[i].classList.remove('is-active')
      }
    }.bind(this))
  }
}

export default AllNotesModal
