import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="course-action"
export default class extends Controller {
  static targets = ["recurrenceSelector", "recurrenceContent", "reminderSelector", "reminderContent"];

  recurrenceToggle() {
    const selectedValue = this.recurrenceSelectorTarget.checked;

    if (selectedValue === true) {
      this.recurrenceContentTarget.classList.remove('hidden');
    } else {
      this.recurrenceContentTarget.classList.add('hidden');
    }
  }

  reminderToggle() {
    const selectedValue = this.reminderSelectorTarget.checked;

    if (selectedValue === true) {
      this.reminderContentTarget.classList.remove('hidden');
    } else {
      this.reminderContentTarget.classList.add('hidden');
    }
  }
}
