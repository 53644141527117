class NestedFormsReferrer {
  start() {
    document.addEventListener("turbo:load", function() {
      if (document.getElementById('referrer-item-list')) {
        this._createDOMEventListeners()
        this.nextID = parseInt(document.getElementById('js-nested-form-referrer-add').dataset.referrerCount, 10);
        this.labelName = document.getElementById('js-nested-form-referrer-add').dataset.referrerName;
        this.labelDelete = document.getElementById('js-nested-form-referrer-add').dataset.referrerDelete;
      }
    }.bind(this))
  }

  _addReferrer () {
    this.nextID = this.nextID + 1
    var templateHTML = this._template(this.nextID, this.labelName, this.labelDelete)
    document.getElementById('referrer-item-list').insertAdjacentHTML('beforeend', templateHTML)
    var allRemoveButtonElements = document.getElementsByClassName('js-nested-form-referrer-remove')
    this._createRemoveEventListener(allRemoveButtonElements[allRemoveButtonElements.length - 1])
  }

  _destroyReferrer (target) {;
    var parent = target.parentElement
    parent.className = parent.className + " is-hidden"

    var destroyHiddenField = parent.getElementsByClassName('js-nested-form-referrer-destroy')[0]
    if(destroyHiddenField) {
      destroyHiddenField.value = true
    } else {
      parent.remove()
    }
  };

  // This is to create the events listening to some DOM events
  _createDOMEventListeners () {
    this._createAddEventListener()

    var removeReferrerButtons = document.getElementsByClassName('js-nested-form-referrer-remove')
    Array.from(removeReferrerButtons).forEach(function(element){
      this._createRemoveEventListener(element)
    }.bind(this));
  }

  _template (id, name, labelDelete) {
    return `
      <div class="referrer-item m-b-md">
        <label class="label">${name}</label>
        <input class="input" type="text" name="client[referrers_attributes][${id}][name]">
        <label class="label">Note</label>
        <textarea class="textarea" name="client[referrers_attributes][${id}][note]"></textarea>
        <a class='js-nested-form-referrer-remove is-size-7 has-text-danger'>${labelDelete}</a>
      </div>`
  }

  _createAddEventListener () {
    var addReferrerButton = document.getElementById('js-nested-form-referrer-add')
    if (addReferrerButton) {
      addReferrerButton.addEventListener('click', function(event) {
        event.preventDefault()
        this._addReferrer()
      }.bind(this));
    }
  }

  _createRemoveEventListener (element) {
    element.addEventListener('click', function(event) {
      event.preventDefault()
      if (event.target.parentElement.className.indexOf('referrer-item') != -1) {
        this._destroyReferrer(event.target)
      } else {
        throw new Error('Noooooo.... :(');
      }
    }.bind(this));
  }
}
export default NestedFormsReferrer
