import Chart from 'chart.js/auto';

var addChartForResult = function(ctx) {
  var minTargetValue = ctx.dataset.minTargetValue;
  var maxTargetValue = ctx.dataset.maxTargetValue;
  var options = {
    responsive: true,
    hoverMode: 'index',
    stacked: false,
    maintainAspectRatio: false,   
    legend: {
      position: 'bottom'
    },
    scales: {
      y: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15,
          suggestedMin: minTargetValue,
          suggestedMax: maxTargetValue,
          beginAtZero: true
        },
      },
      x: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        }
      }
    }
  }

  // prepare data out of the DOM
  var allData = JSON.parse(ctx.dataset.chartData)
  var resultData = []
  var labelName = []
  var labels
  allData.forEach(function(data){
    labelName.push(data[0])
    
    labels = data[1].map(function(item) {
      return item[0]
    })

    var result = data[1].map(function(item) {
      return item[1]
    })
    resultData.push(result)
  })

  var dataset = []
  allData.forEach(function(value, i){
    var rgb = randomRGB(i);
    dataset.push({
      label: labelName[i],
      borderColor: rgb,
      backgroundColor: rgb,
      borderWidth: 2,
      data: resultData[i],
      // barThickness: 100,
    })
  })

  // Configure datasets
  var barChartData = {
    labels: labels,
    datasets: dataset
  };

  // create chart
  var lineChart = new Chart(ctx, {
    labels: labels,
    type: 'bar',
    data: barChartData,
    options: options
  });
}

var addChartForResultSingle = function(ctx) {
  var minTargetValue = ctx.dataset.minTargetValue;
  var maxTargetValue = ctx.dataset.maxTargetValue;
  var options = {
    responsive: true,
    hoverMode: 'index',
    stacked: false,
    maintainAspectRatio: false,   
    legend: {
      position: 'bottom'
    },
    scales: {
      y: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15,
          suggestedMin: minTargetValue,
          suggestedMax: maxTargetValue,
          beginAtZero: true
        },
      },
      x: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        }
      }
    }
  }

  // prepare data out of the DOM
  var allData = JSON.parse(ctx.dataset.chartData)

  var labels = allData.map(function(item) {
    return item[0]
  })

  var resultData = allData.map(function(item) {
    return item[1]
  })

  var labelName = ctx.dataset.labelName

  // Configure datasets
  var barChartData = {
    labels: labels,
    datasets: [{
      label: labelName,
      borderColor: 'rgb(46, 91, 255)',
      backgroundColor: 'rgb(46, 91, 255, 0.8)',
      borderWidth: 2,
      data: resultData,
      // barThickness: 100,
    }]
  };

  // create chart
  var lineChart = new Chart(ctx, {
    labels: labels,
    type: 'bar',
    data: barChartData,
    options: options
  });
}

var addSpecificSkillForChart = function(ctx) {
  var minTargetValue = ctx.dataset.minTargetValue;
  var maxTargetValue = ctx.dataset.maxTargetValue;
  var options = {
    responsive: true,
    hoverMode: 'index',
    maintainAspectRatio: false,   
    stacked: false,    
    legend: {
      position: 'bottom'
    },
    scales: {
      y: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15,
          suggestedMin: minTargetValue,
          suggestedMax: maxTargetValue,
          beginAtZero: true
        },
      },
      x: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        }
      }
    }
  }

  // prepare data out of the DOM
  var allData = JSON.parse(ctx.dataset.chartData)
  var resultData = []
  var labelName = []
  var labels
  allData.forEach(function(data){
    labelName.push(data[0])
    
    labels = data[1].map(function(item) {
      return item[0]
    })

    var result = data[1].map(function(item) {
      return item[1]
    })
    resultData.push(result)
  })

  var dataset = []
  allData.forEach(function(value, i){
    var rgb = randomRGB(i);
    dataset.push({
      label: labelName[i],
      borderColor: rgb,
      backgroundColor: rgb,
      borderWidth: 2,
      data: resultData[i],
      // barThickness: 100,
    })
  })

  var barChartData = {
   labels: labels,
   datasets: dataset
 };

  // create chart
  var lineChart = new Chart(ctx, {
    labels: labels,
    type: 'bar',
    data: barChartData,
    options: options
  });
}

var addSpecificSkillForChartSingle = function(ctx) {
  var minTargetValue = ctx.dataset.minTargetValue;
  var maxTargetValue = ctx.dataset.maxTargetValue;
  var options = {
    responsive: true,
    hoverMode: 'index',
    maintainAspectRatio: false,   
    stacked: false,    
    legend: {
      position: 'bottom'
    },
    scales: {
      y: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15,
          suggestedMin: minTargetValue,
          suggestedMax: maxTargetValue,
          beginAtZero: true
        },
      },
      x: {
        ticks: {
          fontFamily: 'Rubik, BlinkMacSystemFont, -apple-system, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
          fontColor: 'rgb(167, 190, 229)',
          fontSize: 15
        }
      }
    }
  }

  // prepare data out of the DOM
  var allData = JSON.parse(ctx.dataset.chartData)
  
  var labels = allData.map(function(item) {
    return item[0]
  })

  var resultData = allData.map(function(item) {
    return item[1]
  })

  var labelName = ctx.dataset.labelName

  // Configure datasets
  var barChartData = {
    labels: labels,
    datasets: [{
      label: labelName,
      borderColor: 'rgb(46, 91, 255)',
      backgroundColor: 'rgb(46, 91, 255, 0.8)',
      borderWidth: 2,
      data: resultData,
      // barThickness: 100,
    }]
  };

  // create chart
  var lineChart = new Chart(ctx, {
    labels: labels,
    type: 'bar',
    data: barChartData,
    options: options
  });
}

function randomRGB(i) {
  var rgb = ['rgb(51, 51, 255)', 'rgb(51, 153, 255)', 'rgb(51, 255, 255)', 'rgb(51, 255, 153)', 'rgb(51, 255, 51)', 'rgb(153, 255, 51)', 'rgb(255, 255, 51)', 'rgb(255, 153, 51)', 'rgb(255, 51, 51)', 'rgb(160, 160, 160)', 'rgb(255, 51, 153)', 'rgb(255, 51, 255)', 'rgb(153, 51, 255)', 'rgb(0, 0, 255)', 'rgb(0, 128, 255)', 'rgb(0, 255, 255)', 'rgb(0, 255, 128)', 'rgb(0, 255, 0)', 'rgb(128, 255, 0)', 'rgb(255, 255, 0)']
  // var x = Math.floor(Math.random() * 256);
  // var y = Math.floor(Math.random() * 256);
  // var z = Math.floor(Math.random() * 256);

  // var RGBColor = "rgb(" + x + "," + y + "," + z + ")";  
  var RGBColor = rgb[i];
  return RGBColor;
}

function removeAndSelectSelectedClass(stars, length, selectedStarLength) {
  for (var i = 0; i <= length; i++) {
    $(stars[i]).removeClass('selected');
  }

  for (var i = 0; i <= selectedStarLength; i++) {
    $(stars[i]).addClass('selected');
  }
}

export function addRemoveHiddenClassOnCondition() {
  var questionsConditions = document.getElementsByClassName('questions_condition');
  var assessment_parent_question = document.querySelectorAll('.assessment_parent_question');
  
  if (questionsConditions) {
    for (var i = 0; i < questionsConditions.length; i++) {
      if (i == questionsConditions.length - 1) {
        questionsConditions[i].classList.add('hidden');
      } else {
        questionsConditions[i].classList.remove('hidden');
      }
      if(assessment_parent_question[i+1]) {
        addRemoveHiddenClassOnAssessmentOptions(questionsConditions[i].children[0], i);
      }
    }
  }
}

export function changeQuestionOptions(element, i) {
  var assessmentQuestionID = event.target.value
  var questionOptionDropdownForQuestion = document.getElementsByClassName('assessment_question_assessment_questiion_options_'+assessmentQuestionID);

  var lineItems = document.getElementsByClassName("assessment-questions-line-items");
  var questionOptionsDropdowns = lineItems[i].getElementsByClassName('js-assessment-question-assessment-question-option');
  Array.from(questionOptionsDropdowns).forEach(function(questionOptionsDropdown){
    questionOptionsDropdown.classList.add('hidden');
    questionOptionsDropdown.querySelector('select').disabled = true;
  });
  
  questionOptionDropdownForQuestion[i].querySelector('select').disabled = false;
  questionOptionDropdownForQuestion[i].classList.remove('hidden');
  addRemoveHiddenClassOnCondition();
}

export function addRemoveHiddenClassOnAssessmentOptions(element, index) {
  var questionsConditions = document.getElementsByClassName('questions_condition');
  var assessment_parent_question = document.querySelectorAll('.assessment_parent_question');

  var value = assessment_parent_question[index].value;
  Array.from(assessment_parent_question[index + 1].options).forEach(function(option) {
    if(questionsConditions[index].children[0].value == 'and_condition') {
      if (value == option.value) {
        option.classList.add('hidden');
      } else {
        option.classList.remove('hidden');
      }
    } else {
      if (value == option.value) {
        option.classList.remove('hidden');
      } else {
        option.classList.add('hidden');
      }
    };
  });
}

document.addEventListener('turbo:load', function () {

  addRemoveHiddenClassOnCondition();
  var ratingWidget = document.querySelector('.rating-widget');
  if(ratingWidget) {
    var getQuestionOptionRadios = document.querySelectorAll('.question_options');
    var selectedStarLength;
    for (i = 0; i < getQuestionOptionRadios.length; i++) {
      if (getQuestionOptionRadios[i].checked) {
        selectedStarLength = i;
      }
    }

    var stars = $('#stars').children('li.star');
    removeAndSelectSelectedClass(stars, stars.length, selectedStarLength);

    /* 1. Visualizing things on Hover*/
    $('#stars li').on('mouseover', function() {
      var onStar = parseInt($(this).data('value'), 10); // The star currently mouse on
      // Now highlight all the stars that's not after the current hovered star
      $(this).parent().children('li.star').each(function(e) {
        if (e <= onStar) {
          $(this).addClass('hover');
        } else {
          $(this).removeClass('hover');
        }
      });
    }).on('mouseout', function() {
      $(this).parent().children('li.star').each(function(e) {
        $(this).removeClass('hover');
      });
    });
    /* 2. Action to perform on click */
    $('#stars li').on('click', function() {
      var selectedStarLength = parseInt($(this).data('value'), 10); // The star currently selected
      var stars = $(this).parent().children('li.star');
      removeAndSelectSelectedClass(stars, stars.length, selectedStarLength);

      var selectedOptionId = $('#stars li.selected').last().data('id');
      $('.get_selected_radio_' + selectedOptionId)[0].checked = true;
    });
  }

  var genericDropdown = document.querySelector('form.assessment-question-form #assessment_question_assessment_section_id');
  if(genericDropdown) {
    genericDropdown.addEventListener('change', function(event){
      var genericSkillID = event.target.value
      var sspecificDropdowns = document.getElementsByClassName('js-assessment-question-subsections');
      var specificDropdownForGenericSkill = document.getElementById('assessment_question_assessment_sub_section_for_section_'+genericSkillID);
      Array.from(sspecificDropdowns).forEach(function(sspecificDropdown){
        sspecificDropdown.classList.add('hidden');
        sspecificDropdown.querySelector('select').disabled = true;
      });
      specificDropdownForGenericSkill.querySelector('select').disabled = false;
      specificDropdownForGenericSkill.classList.remove('hidden');
    });
  }
  
  const assessment_parent_question = document.querySelectorAll('.assessment_parent_question');
  assessment_parent_question.forEach((element, index) => {
    element.addEventListener('change', changeQuestionOptions.bind(this, element, index));
  });

  const assessment_parent_question_on_scale = document.querySelectorAll('.assessment_parent_question_on_scale');
  assessment_parent_question_on_scale.forEach((element, index) => {
    element.addEventListener('change', addRemoveHiddenClassOnAssessmentOptions.bind(this, element, index));
  });

  var chartsToRender = document.getElementsByClassName('questionnaireChartForResult')
  for (var i = 0, len = chartsToRender.length; i < len; i++) {
    addChartForResult(chartsToRender[i])
  }

  var chartsToRenderSingle = document.getElementsByClassName('questionnaireChartForResultSingle')
  for (var i = 0, len = chartsToRenderSingle.length; i < len; i++) {
    addChartForResultSingle(chartsToRenderSingle[i])
  }

  var specificSkillCharts = document.getElementsByClassName('specificSkillsChart')
  for (var i = 0, len = specificSkillCharts.length; i < len; i++) {
    addSpecificSkillForChart(specificSkillCharts[i])
  }

  var specificSkillChartsSingle = document.getElementsByClassName('specificSkillsChartSingle')
  for (var i = 0, len = specificSkillChartsSingle.length; i < len; i++) {
    addSpecificSkillForChartSingle(specificSkillChartsSingle[i])
  }

  let go_to_dashboard = document.getElementById('go-to-dashboard2');
  if (go_to_dashboard) {
    go_to_dashboard.addEventListener('click', function(event){
      var form = $('#update_answer_form')[0];
      var data = $('#update_answer_form').serializeArray();
      data.push({name: "corrected_by_coach", value: true})
      $.ajax({
        data: data,
        type: 'POST',
        url: form.action,
        success: function(response) {
          window.parent.location.assign(go_to_dashboard.dataset.href)
        }
      });
    });
  }

  let save_and_exit = document.getElementById('assessment_questionnaire_save_and_exit');
  if (save_and_exit) {
    save_and_exit.addEventListener('click', function(event){
      var form = $('#assessment_questionnaires_form')[0];
      var data = $('#assessment_questionnaires_form').serializeArray();
      data.push({name: "redirect", value: 'dashbord'})
      $.ajax({
        data: data,
        type: 'POST',
        url: form.action,
        success: function(response) {
          window.parent.location.assign(save_and_exit.dataset.href)
        }
      });
    });
  }

  let reload_progression = document.getElementById('reload_user_progression_page');
  if (reload_progression) {
    reload_progression.addEventListener('click', function(event){
      window.parent.location.reload();
    });
  }

  var read_more = document.getElementsByClassName("read-more");
  var instruction_read_more = document.getElementsByClassName("instruction-read-more");
  var utility_read_more = document.getElementsByClassName("utility-read-more");
  if (read_more[0]) {
    read_more[0].addEventListener('click', showMoreDetails.bind(read_more));
  }
  if (instruction_read_more[0]) {
    instruction_read_more[0].addEventListener('click', showMoreDetails.bind(instruction_read_more));
  }
  if (utility_read_more[0]) {
    utility_read_more[0].addEventListener('click', showMoreDetails.bind(utility_read_more));
  }
});

function showMoreDetails(event) {
  this[0].parentElement.innerHTML = this[0].dataset.readMore;
}
