document.addEventListener("turbo:load", function() {
  var $videoFrame = $(".video-frame");
  if ($videoFrame.length && $videoFrame.data('video-meeting-id')) {
    var videoUrl = 'https://visio.coachinglms.com/?is_caller=0&video=true#'+$videoFrame.data('video-meeting-id');
    console.log(videoUrl);
    var streamBox = '';
    streamBox += '<div style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%;">';
    streamBox += '<iframe src="'+encodeURI(videoUrl)+'" allow="microphone *; camera *;" style="width: 100%; height: 100%"></iframe>';
    streamBox += '</div>';
    $videoFrame.prepend(streamBox);
  }
});