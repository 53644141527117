document.addEventListener('turbo:load', function () {
  let is_send_second_cal_reminders = document.getElementById('user_is_send_second_cal_reminders')
  if (is_send_second_cal_reminders) {
  if (is_send_second_cal_reminders.checked) {
    second_cal_reminders.classList.remove("hidden");
  } else {
    second_cal_reminders.classList.add("hidden");
  }
    is_send_second_cal_reminders.addEventListener('click', showHideSecondReminderDiv.bind(is_send_second_cal_reminders));

  }
});

function showHideSecondReminderDiv(event) {
  let second_cal_reminders = document.getElementById('second_cal_reminders');
  if (event.currentTarget.checked) {
    second_cal_reminders.classList.remove("hidden");
  } else {
    second_cal_reminders.classList.add("hidden");
  }
}
