document.addEventListener("turbo:load", function() {
  var $todolistItems = $('.js-todolist-item');
  if ($todolistItems.length) {
    $todolistItems.each(function() {
      $(this).on('click', function(ev) {
        if ($(this).hasClass('is-frozen')) {
          // when coaches view the list, we don't want them to be able to check the boxes
          ev.preventDefault();
        }
        if ($(this).data('action')) {
          window.location = $(this).data('action');
          ev.preventDefault();
        }
      });
    });
  }
});