document.addEventListener("turbo:load", function() {

  if ($('#plan_slug').length) {
    var plan_slug = $('#plan_slug').val();
    $('.price-switch-button, #plan_slug').on('change', function() {
      plan_slug = $('#plan_slug').val();
      togglePrice(plan_slug);
    });
    togglePrice(plan_slug);
  }

  function togglePrice(id){
    if (id) {
      if ($('#' + id+ 'price-switch-button-annually').is(':checked')){
        $('.' + id+ "price-switch-annually").removeClass("is-hidden");
        $('.' + id+ "price-switch-monthly").addClass("is-hidden");
        $('.' + id+ "plan-price-month").addClass("is-hidden");
        $('.' + id+ "plan-price-annual").removeClass("is-hidden");
      } else {
        $('.' + id+ "price-switch-annually").addClass("is-hidden");
        $('.' + id+ "price-switch-monthly").removeClass("is-hidden");
        $('.' + id+ "plan-price-month").removeClass("is-hidden");
        $('.' + id+ "plan-price-annual").addClass("is-hidden");
      }
      $('.pricing-plans').addClass('is-hidden');
      $('#' + id).removeClass('is-hidden');
    }
  }

});
