class ToolFilters {
  start() {
    document.addEventListener("turbo:load", function() {
      this.currentURL = new URL(window.location)
      // not sure why but sometimes searchParams returns an "undefined" value...
      if (this.currentURL.searchParams) {
        this.categoryID = this.currentURL.searchParams.get('category_id') || "" // defaults to an empty string so we don't have things like "category_id=null" in the URL
        this.typeID = this.currentURL.searchParams.get('type_id') || "" // defaults to an empty string so we don't have things like "type_id=null" in the URL
        this.tag = this.currentURL.searchParams.get('tag') || "" // defaults to an empty string so we don't have things like "type_id=null" in the URL
      } else {
        this.categoryID = ""
        this.typeID = ""
        this.tag = ""
      }
      if(document.getElementById('tools-filters'))
      this._createDOMEventListeners()
    }.bind(this));
  }

  _createDOMEventListeners() {
    var categoryDropdown = document.getElementById('tool_tool_category_id')
    if(categoryDropdown) {
      categoryDropdown.addEventListener('change', function(event){
        this.categoryID = event.target.value
        this.applyFilters()
      }.bind(this))
    }

    var contentTypeDropdown = document.getElementById('tool_tool_content_type')
    if(contentTypeDropdown) {
      contentTypeDropdown.addEventListener('change', function(event){
        this.contentType = event.target.value
        this.applyFilters()
      }.bind(this))
    }

    var typeDropdown = document.getElementById('tool_tool_type_id')
    if(typeDropdown) {
      typeDropdown.addEventListener('change', function(event){
        this.typeID = event.target.value
        this.applyFilters()
      }.bind(this))
    }

    var toolsContentDropdown = document.getElementById('tool_tools_content_id')
    if(toolsContentDropdown) {
      toolsContentDropdown.addEventListener('change', function(event){
        this.contentID = event.target.value
        this.applyFilters()
      }.bind(this))
    }

    var toolsStatusDropdown = document.getElementById('tool_tool_status')
    if(toolsStatusDropdown) {
      toolsStatusDropdown.addEventListener('change', function(event){
        this.status = event.target.value
        this.applyFilters()
      }.bind(this))
    }

    var htmlCategoryDropdown = document.getElementById('tool_advance_html_category_id')
    if(htmlCategoryDropdown) {
      htmlCategoryDropdown.addEventListener('change', function(event){
        this.categoryID = event.target.value
        this.applyFilters()
      }.bind(this))
    }
  }

  applyFilters() {
    window.location.search = '?category_id=' + this.categoryID + '&type_id=' + this.typeID + '&tag=' + this.tag + '&content_type=' + this.contentType + '&content_id=' + this.contentID + '&status=' + this.status
  }
}

export default ToolFilters
