/* Used to print course certificate */
window.PrintCert = function(){
    var divContents = document.getElementById("printCertContent").outerHTML;
    var mywindow = window.open('', '', 'height=800,width=800');
    mywindow.document.write('<html><head><title></title>');
    $('link[rel="stylesheet"]').each(function (i, ele) {
        mywindow.document.write('<link rel="stylesheet" href="'+$(this).attr('href')+'" type="text/css" />');
    });
    mywindow.document.write('<style>');
    const style_data = '@media print {\n' +
        '    * {\n' +
        '        -webkit-print-color-adjust: exact;\n' +
        '        color-adjust: exact;\n' +
        '    }\n' +
        '}';
    mywindow.document.write(style_data);
    mywindow.document.write('</style>');
    mywindow.document.write('</head><body>');
    mywindow.document.write(divContents);
    mywindow.document.write('</body></html>');
    mywindow.document.close();
    mywindow.focus();
    setTimeout(function(){mywindow.print();},1000);

    return true;
}