import { Controller } from "@hotwired/stimulus"
import { I18n } from "i18n-js";
import fr from "../src/translations/fr.json";
import en from "../src/translations/en.json";

// Connects to data-controller="payment-failed-warning"
export default class extends Controller {
  static targets = [ "payment_delay" ]

  connect() {
    const activePlanExpired = this.payment_delayTarget.value;

    if (activePlanExpired == 'true') {
      const i18n = new I18n({
        fr,
        en,
      });
      i18n.locale = $('body').data('locale');

      // Get the date when the alert was last shown from localStorage
      const lastAlertDate = localStorage.getItem('lastAlertDate');

      // Get today's date
      const today = new Date().toISOString().split('T')[0];

      if (lastAlertDate !== today) {
        // Show the alert
        alert(i18n.t('warning_for_payment'));

        // Update the last alert date in localStorage
        localStorage.setItem('lastAlertDate', today);
      }
    }
  }

  clearStorage(event) {
    if (localStorage.getItem('lastAlertDate')) {
      localStorage.removeItem('lastAlertDate');
    }
  }
}
