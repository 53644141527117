document.addEventListener("turbo:load", function() {
  var freeTemplateSwitches = Array.prototype.slice.call(document.querySelectorAll(".isFreeTemplateContentSwitch"), 0);

  if( freeTemplateSwitches.length > 0 ) {
    freeTemplateSwitches.forEach(function (freeTemplateSwitch) {
      var priceVisibleBtn = freeTemplateSwitch.querySelector('.isPriceVisibilitySwitch-visible');
      var priceHiddenBtn = freeTemplateSwitch.querySelector('.isPriceVisibilitySwitch-hidden');

      var tool_price = document.querySelector(".templatePrice");

      priceHiddenBtn.addEventListener( "click", hideContent );
      priceVisibleBtn.addEventListener( "click", showContent );

      function hideContent () {
        tool_price.classList.remove('hidden')
      }

      function showContent () {
        tool_price.classList.add('hidden')
      }

    });
  }
});
